// container index

import {  IconButton, InputAdornment, OutlinedInput, Box, Typography, TextField,TextareaAutosize, FormHelperText , Button, List,ListItem,Card, CardContent, TablePagination,  Table , TableBody , TableCell , TableContainer , TableHead  , TableRow , Tabs ,Tab, Radio , FormControlLabel,ToggleButton,ToggleButtonGroup,Modal,Select,Menu,MenuItem ,FormControl, Chip,  Stack, Pagination, Tooltip , Checkbox,} from '@mui/material'

import 'react-toastify/dist/ReactToastify.css';
import Png from "../assets/png";
import Svg from "../assets/svg";
import Jpg from "../assets/jpg";
import PrimaryButton from '../component/button/PrimaryButton';
import LinearButton from '../component/button/LinearButton';
import SecondaryButton from '../component/button/SecondaryButton';
import Header from '../component/defaultLayout/Header';
import SideBar from '../component/defaultLayout/SideBar';
import HeaderDropDown from '../component/dropDown/HeaderDropDown';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ImageIcon from '@mui/icons-material/Image';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LoadingSpinner from "../component/LoadingSpinner";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {Grid} from '@mui/material';
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import DataNotFound from '../component/dataNotFound/DataNotFound';
import ImportExcelSheet from '../component/button/ImportExcelSheet';
import ExportSampleSheet from '../component/button/ExportSampleSheet';

let convertDate=(date=new Date())=>{
    let d=new Date(date);
    d.setHours(5,30,0,0);
    return d.toJSON();
}

let trimText=(val='')=>{
  return  val.split(" ")?.filter((e)=>e==="")?.length>=2?val.trim():val
}

let settledAmount = (v1='',v2='',check,extra=0)=>{
  if(v1 && check){
    return ((v1)+(extra)) !== 0
  }else if(v2 && check){
    return ((v2)+(extra)) !== 0
  }else if(check && extra){
    return extra !== 0;
  }else{
    return false;
  }
}

let calculationOfAmount =(groupName,openingType,type,amount)=>{
  if(groupName.match(/CREDITORS/g) && openingType==="C"){
    return type==="C"?-amount:amount
  }else if(groupName.match(/CREDITORS/g) && openingType==="D"){
    return type==="D"?-amount:amount
  }else if(groupName.match(/DEBTORS/g) && openingType==="C"){
    return type==="C"?-amount:+amount
  }else if(groupName.match(/DEBTORS/g) && openingType==="D"){
    return type==="D"?-amount:+amount
  }else if(groupName.match(/BANK/g) && openingType==="D"){
    return type==="C"?amount:-amount
  }else if(groupName.match(/BANK/g) && openingType==="C"){
    return type==="D"?+amount:-amount
  }
}

export default {
    ThumbUpIcon,
    convertDate,
    Grid,
    ThumbDownIcon,
    IconButton,
    InputAdornment,
    OutlinedInput,
    List,
    ListItem,
    Png,
    Svg, 
    Box,
    Tooltip,
    Jpg,
    Typography, 
    TextField,
    TextareaAutosize,
    FormHelperText,
    Link,
    PrimaryButton,
    Button,
    Header,
    SideBar,
    HeaderDropDown,
    Chip,
    Card,
    CardContent,
    Table, 
    TablePagination,
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead,
    TableRow,
    LinearButton,
    Tab,
    Tabs,
    Radio,
    ToggleButton,
    FormControlLabel,
    ToggleButtonGroup,
    SecondaryButton,
    Modal,
    Select,
    Menu,
    MenuItem,
    FormControl,
    useForm,
    Controller,
    toast,
    ToastContainer,
    useDispatch,
    useSelector,
    Swal,
    useNavigate,
    useParams,
    Visibility,
    VisibilityOff,
    useLocation,
    LoadingSpinner,
    styled,
    Paper,
    makeStyles,
    Stack,
    Pagination,
    Checkbox,
    moment,
    Autocomplete,
    trimText,
    settledAmount,
    calculationOfAmount,
    QrCodeScannerIcon,
    ImageIcon,
    DataNotFound,
    ImportExcelSheet,
    ExportSampleSheet
};
