import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const CompanyPrivateComponent = () => {
  const { type } = useSelector((state) => {
    return state.ERPAdminReducer;
  });

  return type === "company" ? <Outlet /> : <Navigate from='*' to='/404' />;
};

export default CompanyPrivateComponent;
