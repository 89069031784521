import React, { useState, useEffect, useRef } from "react";
import Index from "../../../../component/Index";
import ContainerIndex from "../../../Index";
import { styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { Autocomplete, ButtonGroup, Button } from "@mui/material";
import { Controller } from "react-hook-form";
import * as XLSX from "xlsx/xlsx.mjs";
import {
  getStockReportDataWebviewApiHandler,
  getStockReportFilterOptionsWebviewApiHandler,
  uploadPdfApiHandler,
} from "../../../../services/AdminIndex";
import LoadingSpinner from "../../../../component/LoadingSpinner";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { GetSizeMasterListWebviewAction } from "../../../../redux/admin/action";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Item = styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function SalesOrderReport() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = Index.useForm();

  const { companyId, fromDate } = useParams();

  const filterRef = useRef();
  const printRef = useRef();
  const dispatch = useDispatch();
  const [sundryDebitors, setSundryDebitors] = useState([]);
  const [productGroup, setProductGroup] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [colors, setColors] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [filteredSubmitedOptions, setFilteredSubmitedOptions] = useState();

  const reportType = [
    { value: "detailReport", title: "Stock Report" },
    { value: "stockSummary", title: "Stock Report Summary" },
    {
      value: "stockSummaryBal",
      title: "Stock Report Summary (Only Balance Qty)",
    },
    { value: "productGroupSummary", title: "Product Group Wise Summary" },
    {
      value: "categoryProductGroupSummary",
      title: "Category Product Group Wise Summary",
    },
  ];
  const { loading, getSizeMaster, selectedYearStart, selectedYearEnd } =
    Index.useSelector((state) => {
      return state.ERPAdminReducer;
    });

  useEffect(() => {
    dispatch(GetSizeMasterListWebviewAction(1, companyId));
  }, []);

  // const handlePrint = useReactToPrint({
  //   content: () => printRef.current,
  //   bodyClass: "padding-10",
  // });

  const exportToExcel = () => {
    // Convert table to sheet
    const ws = XLSX?.utils?.table_to_sheet(printRef.current);
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

    if (watch("reportType") == "detailReport") {
      data.forEach((row) => {
        if (row.length > 1) {
          if (row?.indexOf("Set Off") != -1 || row?.indexOf("Action") != -1) {
            row.pop(); // Remove the last element from each row
          }
        }
      });
    }
    // return false;
    data.splice(2, 0, []);
    const newWs = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX?.utils?.book_new();
    XLSX?.utils?.book_append_sheet(wb, newWs, "Sales-Order");
    XLSX.writeFile(wb, "sales-Order.xlsx");
  };

  // const generateAndSavePDF = async (printIframe) => {
  //   const element = printIframe.contentWindow.document.body;

  //   const canvas = await html2canvas(element, {
  //     scale: window.devicePixelRatio || 2,
  //   }); // Adjust scale for high-density displays
  //   const imgData = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   const imgProps = pdf.getImageProperties(imgData);
  //   const imgWidth = imgProps.width * 0.264583;
  //   const imgHeight = imgProps.height * 0.264583;

  //   const ratio = imgWidth / pdfWidth;
  //   const adjustedHeight = imgHeight / ratio;

  //   if (adjustedHeight <= pdfHeight) {
  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, adjustedHeight);
  //   } else {
  //     let position = 0;
  //     while (position < imgHeight) {
  //       pdf.addImage(
  //         imgData,
  //         "PNG",
  //         0,
  //         -position / ratio,
  //         pdfWidth,
  //         imgHeight / ratio
  //       );
  //       position += pdfHeight * ratio;
  //       if (position < imgHeight) {
  //         pdf.addPage();
  //       }
  //     }
  //   }

  //   pdf.save("download.pdf");
  // };

  const generateAndSavePDF = async (printIframe) => {
    const element = printIframe.contentWindow.document.body;

    const canvas = await html2canvas(element, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/jpeg", 0.8);

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = imgProps.width * 0.264583;
    const imgHeight = imgProps.height * 0.264583;

    const ratio = imgWidth / pdfWidth;
    const adjustedHeight = imgHeight / ratio;

    if (adjustedHeight <= pdfHeight) {
      pdf.addImage(imgData, "jpeg", 0, 0, pdfWidth, adjustedHeight, "", "FAST");
    } else {
      let position = 0;
      while (position < imgHeight) {
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -position / ratio,
          pdfWidth,
          imgHeight / ratio
        );
        position += pdfHeight * ratio;
        if (position < imgHeight) {
          pdf.addPage();
        }
      }
    }

    const pdfBlob = pdf.output("blob", {
      compress: true,
      fontSize: 12,
      quality: "high",
    });

    const formData = new FormData();
    formData.append("profile", pdfBlob, "generated.pdf");

    try {
      uploadPdfApiHandler(formData).then((response) => {
        if (response.status == 201 || response.status == 200) {
          console.log(response, "response 202");
          // window.open(
          //   "http://localhost:3011/api/uploads/profile-1720590930769.pdf"
          // );
          const link = document.createElement("a");
          link.href = response?.data;
          link.download = "report.pdf"; // Specify the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIdLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    print: async (printIframe) => {
      await generateAndSavePDF(printIframe);
    },
  });

  // Automatically trigger print when data changes and is not empty
  useEffect(() => {
    if (reportData?.length > 0) {
      handlePrint();
    }
  }, [reportData]);

  const handleScrollToTop = (ref) => {
    filterRef.current.scrollIntoView();
  };

  useEffect(() => {
    setValue("fromDate", dayjs(selectedYearStart));
    setValue("asOnDate", dayjs(selectedYearEnd));
  }, [selectedYearStart]);

  const getReportFilterOptions = () => {
    try {
      getStockReportFilterOptionsWebviewApiHandler(companyId).then(
        async (data) => {
          if (data.status === 201 || data.status === 200) {
            setSundryDebitors(data?.data?.party);
            setProducts(data?.data?.product);
            setColors(data?.data?.color);
            setProductGroup(data?.data?.productGroup);
            setProductCategory(data?.data?.productCategory);

            dispatch({ type: "LOADER_END" });
          } else {
            dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReportFilterOptions();
  }, []);

  const [isLoading, setIdLoading] = useState(false);
  const submit = (data) => {
    setIdLoading(true);
    setFilteredSubmitedOptions(data);
    if (data.asOnDate) {
      data.fromDate = dayjs(fromDate).format("YYYY-MM-DD");
      data.asOnDate = moment(data?.asOnDate?.$d).format("YYYY-MM-DD");
    }
    data.type = data.reportType;
    data.companyId = companyId;
    try {
      getStockReportDataWebviewApiHandler(data).then(async (response) => {
        dispatch({ type: "LOADER_START" });
        if (response.status === 201 || response.status === 200) {
          const filteredData = response?.data?.filter(
            (ele) => ele?.transactionData
          );
          let finalData = [];
          if (watch("reportType") == "stockSummary") {
            const data = response?.data?.filter((ele) => ele?.productData);

            let formatedData = data?.map((ele) => {
              let productData = parseData(ele?.productData);
              productData = productData?.map((item) => {
                let transactionData = item?.transactionData?.map(
                  (transaction) => {
                    return {
                      ...transaction,
                      sizeQuantity: parseData(transaction?.sizeQuantity),
                    };
                  }
                );
                return {
                  ...item,
                  transactionData: transactionData,
                };
              });
              return {
                ...ele,
                productData: productData,
              };
            });

            finalData = formatedData;
          } else if (watch("reportType") == "stockSummaryBal") {
            const data = response?.data?.filter((ele) => ele?.productData);

            let formatedData = data?.map((ele) => {
              let productData = parseData(ele?.productData);
              productData = productData?.map((item) => {
                let transactionData = item?.transactionData?.map(
                  (transaction) => {
                    // if(transaction?.balance)
                    // {
                    return {
                      ...transaction,
                      sizeQuantity: parseData(transaction?.sizeQuantity),
                    };
                    // }
                  }
                );
                return {
                  ...item,
                  transactionData: transactionData,
                };
              });
              return {
                ...ele,
                productData: productData,
              };
            });

            let newData = formatedData?.map((ele) => {
              let productData = ele?.productData?.map((item) => {
                let transactionData = [];
                item?.transactionData?.filter((transaction) => {
                  if (
                    transaction?.sizeQuantity?.length > 1 ||
                    transaction?.sizeQuantity?.[0]?.quantity
                  ) {
                    transactionData.push(transaction);
                  }
                });
                let row = {
                  ...item,
                  transactionData,
                };
                if (transactionData?.length) {
                  return row;
                }
              });
              productData = productData?.filter((product) => product);
              let data = {
                ...ele,
                productData,
              };
              if (productData?.length) {
                finalData.push(data);
              }
            });
          } else if (watch("reportType") == "productGroupSummary") {
            const data = response?.data?.filter((ele) => ele?.productData);

            let formatedData = data?.map((ele) => {
              let productData = parseData(ele?.productData);
              productData = productData?.map((item) => {
                let transactionData = item?.transactionData?.map(
                  (transaction) => {
                    return {
                      ...transaction,
                      sizeQuantity: parseData(transaction?.sizeQuantity),
                    };
                  }
                );
                return {
                  ...item,
                  transactionData: transactionData,
                };
              });
              return {
                ...ele,
                productData: productData,
              };
            });

            let newData = formatedData?.map((ele) => {
              let productData = ele?.productData?.map((item) => {
                let transactionData = [];
                item?.transactionData?.filter((transaction) => {
                  if (
                    transaction?.sizeQuantity?.length > 1 ||
                    transaction?.sizeQuantity?.[0]?.quantity
                  ) {
                    transactionData.push(transaction);
                  }
                });
                let row = {
                  ...item,
                  transactionData,
                };
                if (transactionData?.length) {
                  return row;
                }
              });
              productData = productData?.filter((product) => product);
              let data = {
                ...ele,
                productData,
              };
              if (productData?.length) {
                finalData.push(data);
              }
            });
          } else if (watch("reportType") == "categoryProductGroupSummary") {
            const data = response?.data?.filter(
              (ele) => ele?.categorisedProduct
            );

            let formatedData = data?.map((ele) => {
              let categorisedProduct = parseData(ele?.categorisedProduct);
              categorisedProduct = categorisedProduct?.map(
                (productCategory) => {
                  let productData = productCategory?.productData?.map(
                    (item) => {
                      let transactionData = item?.transactionData?.map(
                        (transaction) => {
                          return {
                            ...transaction,
                            sizeQuantity: parseData(transaction?.sizeQuantity),
                          };
                        }
                      );
                      return {
                        ...item,
                        transactionData: transactionData,
                      };
                    }
                  );
                  return {
                    ...productCategory,
                    productData: productData,
                  };
                }
              );

              return {
                ...ele,
                categorisedProduct: categorisedProduct,
              };
            });
            formatedData?.map((ele) => {
              let filteredCategorised = ele?.categorisedProduct?.map(
                (productCategory) => {
                  let productData = productCategory?.productData?.map(
                    (item) => {
                      item?.transactionData?.filter((transaction) => {
                        return (
                          transaction?.sizeQuantity?.length > 1 ||
                          transaction?.sizeQuantity?.[0]?.quantity
                        );
                      });
                      if (item?.transactionData?.length) {
                        return item;
                      }
                    }
                  );
                  productData = productData?.filter((product) => product);
                  let data = {
                    ...productCategory,
                    productData,
                  };
                  if (productData?.length) {
                    return data;
                  }
                }
              );
              filteredCategorised = filteredCategorised?.filter((ele) => ele);
              let newData = {
                ...ele,
                categorisedProduct: filteredCategorised,
              };
              finalData.push(newData);
            });
          } else {
            filteredData?.map((ele) => {
              let transactionData = parseData(ele?.transactionData);
              transactionData = transactionData?.map((ele) => {
                return { ...ele, sizeQuantity: parseData(ele?.sizeQuantity) };
              });
              let row = {
                ...ele,
                transactionData: transactionData,
              };
              if (
                transactionData?.length > 1 ||
                transactionData?.[0]?.sizeQuantity?.length > 0
              ) {
                finalData.push(row);
              }
            });
          }

          setReportData(finalData);
          dispatch({ type: "LOADER_END" });
        } else {
          dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const parseData = (data) => {
    try {
      // if plain js
      if (data) {
        let result = JSON.parse(data);
        return result;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };
  const reportContent = (reportData) => {
    if (watch("reportType") == "detailReport") {
      return (
        <Index.Box
          className="card-spacing page-table-main stock-details-table-main"
          style={{ display: "none" }}
        >
          <TableContainer
            component={Paper}
            className="table-container summary-table-container"
          >
            <div ref={printRef}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table summary-table webview-main-container"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan="100%"
                    >
                      <Index.Box className="table-summary-bg">
                        <Index.Typography
                          variant="h4"
                          component="h4"
                          className="view-titles"
                        >
                          Stock Report
                        </Index.Typography>
                      </Index.Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Voucher No.
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Party Name
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan={getSizeMaster?.length}
                    >
                      Size
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Inward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Outward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Balance
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>

                    {getSizeMaster
                      ?.sort((a, b) => a.type - b.type)
                      ?.map((row, index) => (
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell-th"
                          key={row?.id}
                        >
                          {row?.size_name}
                        </TableCell>
                      ))}
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.map((row, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                            colSpan={getSizeMaster?.length + 6}
                          >
                            <b className="bold-text">Product</b> :{" "}
                            {row?.ProductName}, <b>Color</b> :{row?.color_name},{" "}
                            <b className="bold-text">Category</b> :{" "}
                            {row?.productCategory}
                          </TableCell>
                        </TableRow>
                        {row?.transactionData?.map((transaction, index) => {
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {transaction?.voucher}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {transaction?.party}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {transaction.type != "opening"
                                    ? moment(transaction?.date)?.format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </TableCell>

                                {getSizeMaster
                                  ?.sort((a, b) => a.type - b.type)
                                  ?.map((row, index) => (
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="table-cell-td"
                                      key={row?.id}
                                    >
                                      <Index.Box className="table-data-text">
                                        {
                                          transaction?.sizeQuantity?.find(
                                            (ele) => ele?.size == row?.size_code
                                          )?.quantity
                                        }
                                      </Index.Box>
                                    </TableCell>
                                  ))}
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {transaction?.inward}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {transaction?.outward}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {transaction?.balance || 0}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                        <>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            ></TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            ></TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">Total</b>
                            </TableCell>

                            {getSizeMaster
                              ?.sort((a, b) => a.type - b.type)
                              ?.map((sizeRow, index) => (
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                  key={row?.id}
                                >
                                  <Index.Box className="table-data-text">
                                    {row?.transactionData?.reduce(
                                      (accumulator, transaction) => {
                                        const sizeQtySum =
                                          transaction.sizeQuantity.reduce(
                                            (sum, size) => {
                                              if (
                                                size?.size == sizeRow?.size_code
                                              ) {
                                                if (
                                                  transaction?.type == "opening"
                                                ) {
                                                  return sum + size?.quantity;
                                                } else if (
                                                  transaction?.type ==
                                                  "finishReceived"
                                                ) {
                                                  return sum + size?.quantity;
                                                } else if (
                                                  transaction?.type ==
                                                  "finishOutward"
                                                ) {
                                                  return sum - size?.quantity;
                                                } else if (
                                                  transaction?.type == "packing"
                                                ) {
                                                  return sum - size?.quantity;
                                                } else if (
                                                  transaction?.type == "sales"
                                                ) {
                                                  return sum - size?.quantity;
                                                } else if (
                                                  transaction?.type ==
                                                  "salesReturn"
                                                ) {
                                                  return sum + size?.quantity;
                                                } else {
                                                  return sum + size?.quantity;
                                                }
                                              } else {
                                                return sum;
                                              }
                                            },
                                            0
                                          );
                                        return accumulator + sizeQtySum;
                                      },
                                      0
                                    )}
                                  </Index.Box>
                                </TableCell>
                              ))}
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={row?.id}
                            >
                              <Index.Box className="table-data-text">
                                {row?.transactionData?.reduce(
                                  (accumulator, transaction) => {
                                    if (transaction?.type == "opening") {
                                      return (
                                        accumulator + (transaction?.inward || 0)
                                      );
                                    } else if (
                                      transaction?.type == "finishReceived"
                                    ) {
                                      return (
                                        accumulator + (transaction?.inward || 0)
                                      );
                                    } else if (
                                      transaction?.type == "salesReturn"
                                    ) {
                                      return (
                                        accumulator + (transaction?.inward || 0)
                                      );
                                    } else {
                                      return accumulator;
                                    }
                                  },
                                  0
                                )}
                              </Index.Box>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.transactionData?.reduce(
                                (accumulator, transaction) => {
                                  if (transaction?.type == "finishOutward") {
                                    return (
                                      accumulator + (transaction?.outward || 0)
                                    );
                                  } else if (transaction?.type == "packing") {
                                    return (
                                      accumulator + (transaction?.outward || 0)
                                    );
                                  } else if (transaction?.type == "sales") {
                                    return (
                                      accumulator + (transaction?.outward || 0)
                                    );
                                  } else {
                                    return accumulator;
                                  }
                                },
                                0
                              )}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">
                                {row?.transactionData?.[
                                  row?.transactionData?.length - 1
                                ]?.balance || 0}
                              </b>
                            </TableCell>
                          </TableRow>
                        </>
                      </>
                    );
                  })}
                  {reportData?.length && (
                    <>
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        ></TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        ></TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">Grand Total</b>
                        </TableCell>

                        {getSizeMaster
                          ?.sort((a, b) => a.type - b.type)
                          ?.map((sizeRow, index) => (
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={index}
                            >
                              <Index.Box className="table-data-text">
                                {reportData.reduce((sum, entry) => {
                                  entry.transactionData.forEach(
                                    (transaction) => {
                                      transaction.sizeQuantity.forEach(
                                        (size) => {
                                          if (
                                            size?.size == sizeRow?.size_code
                                          ) {
                                            if (
                                              transaction?.type == "opening"
                                            ) {
                                              sum += size.quantity;
                                            } else if (
                                              transaction?.type ==
                                              "finishReceived"
                                            ) {
                                              sum += size.quantity;
                                            } else if (
                                              transaction?.type ==
                                              "finishOutward"
                                            ) {
                                              sum -= size.quantity;
                                            } else if (
                                              transaction?.type == "packing"
                                            ) {
                                              sum -= size.quantity;
                                            } else if (
                                              transaction?.type == "sales"
                                            ) {
                                              sum -= size.quantity;
                                            } else if (
                                              transaction?.type == "salesReturn"
                                            ) {
                                              sum += size.quantity;
                                            } else {
                                              sum += size.quantity;
                                            }
                                          }
                                        }
                                      );
                                    }
                                  );

                                  return sum;
                                }, 0)}
                              </Index.Box>
                            </TableCell>
                          ))}
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            {reportData.reduce((sum, entry) => {
                              entry.transactionData.forEach((transaction) => {
                                if (transaction?.type == "opening") {
                                  sum += transaction?.inward || 0;
                                } else if (
                                  transaction?.type == "finishReceived"
                                ) {
                                  sum += transaction?.inward || 0;
                                } else if (transaction?.type == "salesReturn") {
                                  sum += transaction?.inward || 0;
                                }
                              });

                              return sum;
                            }, 0)}
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          {reportData.reduce((sum, entry) => {
                            entry.transactionData.forEach((transaction) => {
                              if (transaction?.type == "finishOutward") {
                                sum += transaction?.outward || 0;
                              } else if (transaction?.type == "packing") {
                                sum += transaction?.outward || 0;
                              } else if (transaction?.type == "sales") {
                                sum += transaction?.outward || 0;
                              }
                            });

                            return sum;
                          }, 0)}
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">
                            {reportData.reduce((sum, entry) => {
                              if (
                                entry?.transactionData?.[
                                  entry?.transactionData?.length - 1
                                ]?.balance
                              ) {
                                sum +=
                                  entry?.transactionData?.[
                                    entry?.transactionData?.length - 1
                                  ]?.balance;
                              }

                              return sum;
                            }, 0)}
                          </b>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Index.Box>
      );
    }
    if (watch("reportType") == "stockSummary") {
      return (
        <Index.Box
          className="card-spacing page-table-main summary-stock-sumary-table-main"
          style={{ display: "none" }}
        >
          <TableContainer
            component={Paper}
            className="table-container summary-table-container"
          >
            <div ref={printRef}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table summary-table webview-main-container"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan="100%"
                    >
                      <Index.Box className="table-summary-bg">
                        <Index.Typography
                          variant="h4"
                          component="h4"
                          className="view-titles"
                        >
                          Stock Report Summary
                        </Index.Typography>
                      </Index.Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Color
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan={getSizeMaster?.length}
                    >
                      Size
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Inward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Outward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Balance
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>

                    {getSizeMaster
                      ?.sort((a, b) => a.type - b.type)
                      ?.map((row, index) => (
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell-th"
                          key={row?.id}
                        >
                          {row?.size_name}
                        </TableCell>
                      ))}
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.map((row, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                            colSpan={getSizeMaster?.length + 4}
                          >
                            <b className="bold-text">Product</b> :{" "}
                            {row?.ProductName},{" "}
                            <b className="bold-text">Category</b> :{" "}
                            {row?.productCategory}
                          </TableCell>
                        </TableRow>
                        {row?.productData?.map((product, index) => {
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.color_name}
                                </TableCell>
                                {getSizeMaster
                                  ?.sort((a, b) => a.type - b.type)
                                  ?.map((sizeRow, index) => (
                                    <TableCell
                                      component="td"
                                      className="table-cell-td"
                                      scope="row"
                                      key={index}
                                    >
                                      {product?.transactionData?.reduce(
                                        (sum, entry) => {
                                          entry?.sizeQuantity.forEach(
                                            (transaction) => {
                                              if (
                                                transaction?.size ==
                                                sizeRow?.size_code
                                              ) {
                                                if (entry?.type == "opening") {
                                                  sum += transaction.quantity;
                                                } else if (
                                                  entry?.type ==
                                                  "finishReceived"
                                                ) {
                                                  sum += transaction.quantity;
                                                } else if (
                                                  entry?.type == "finishOutward"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry?.type == "packing"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry?.type == "sales"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry?.type == "salesReturn"
                                                ) {
                                                  sum += transaction.quantity;
                                                } else {
                                                  sum += transaction.quantity;
                                                }
                                              }
                                            }
                                          );

                                          return sum;
                                        },
                                        0
                                      )}
                                    </TableCell>
                                  ))}
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.reduce(
                                    (accumulator, transaction) => {
                                      if (transaction?.type == "opening") {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "finishReceived"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "salesReturn"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.reduce(
                                    (accumulator, transaction) => {
                                      if (
                                        transaction?.type == "finishOutward"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "packing"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else if (transaction?.type == "sales") {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance || 0}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                        <>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">Total</b>
                            </TableCell>

                            {getSizeMaster
                              ?.sort((a, b) => a.type - b.type)
                              ?.map((sizeRow, index) => (
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                  key={row?.id}
                                >
                                  <Index.Box
                                    scope="row"
                                    className="table-data-text"
                                  >
                                    {row?.productData?.reduce(
                                      (sum, product) => {
                                        product?.transactionData?.forEach(
                                          (transaction) => {
                                            transaction?.sizeQuantity?.forEach(
                                              (size) => {
                                                if (
                                                  size?.size ==
                                                  sizeRow?.size_code
                                                ) {
                                                  if (
                                                    transaction?.type ==
                                                    "opening"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "finishReceived"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "finishOutward"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "packing"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type == "sales"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "salesReturn"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else {
                                                    sum += size.quantity;
                                                  }
                                                }
                                              }
                                            );
                                          }
                                        );

                                        return sum;
                                      },
                                      0
                                    )}
                                  </Index.Box>
                                </TableCell>
                              ))}
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={row?.id}
                            >
                              <Index.Box className="table-data-text">
                                {row?.productData?.reduce((sum, product) => {
                                  product?.transactionData?.forEach(
                                    (transaction) => {
                                      if (transaction?.type == "opening") {
                                        sum += transaction.inward || 0;
                                      } else if (
                                        transaction?.type == "finishReceived"
                                      ) {
                                        sum += transaction.inward || 0;
                                      } else if (
                                        transaction?.type == "salesReturn"
                                      ) {
                                        sum += transaction.inward || 0;
                                      }
                                    }
                                  );

                                  return sum;
                                }, 0)}
                              </Index.Box>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.productData?.reduce((sum, product) => {
                                product?.transactionData?.forEach(
                                  (transaction) => {
                                    if (transaction?.type == "finishOutward") {
                                      sum += transaction.outward || 0;
                                    } else if (transaction?.type == "packing") {
                                      sum += transaction.outward || 0;
                                    } else if (transaction?.type == "sales") {
                                      sum += transaction.outward || 0;
                                    }
                                  }
                                );

                                return sum;
                              }, 0)}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.productData?.reduce((sum, product) => {
                                if (
                                  product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance
                                ) {
                                  sum +=
                                    product?.transactionData?.[
                                      product?.transactionData?.length - 1
                                    ]?.balance;
                                }

                                return sum;
                              }, 0)}
                            </TableCell>
                          </TableRow>
                        </>
                      </>
                    );
                  })}
                  <TableRow>
                    <TableCell
                      component="td"
                      scope="row"
                      className="table-cell-td"
                      colSpan={"100%"}
                    ></TableCell>
                  </TableRow>
                  {reportData?.length && (
                    <>
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">Grand Total</b>
                        </TableCell>

                        {getSizeMaster
                          ?.sort((a, b) => a.type - b.type)
                          ?.map((sizeRow, index) => (
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={index}
                            >
                              <Index.Box className="table-data-text">
                                {reportData.reduce((sum, product) => {
                                  product?.productData?.forEach(
                                    (transaction) => {
                                      transaction?.transactionData?.forEach(
                                        (row) => {
                                          row?.sizeQuantity.forEach((size) => {
                                            if (
                                              size?.size == sizeRow?.size_code
                                            ) {
                                              if (row?.type == "opening") {
                                                sum += size.quantity;
                                              } else if (
                                                row?.type == "finishReceived"
                                              ) {
                                                sum += size.quantity;
                                              } else if (
                                                row?.type == "finishOutward"
                                              ) {
                                                sum -= size.quantity;
                                              } else if (
                                                row?.type == "packing"
                                              ) {
                                                sum -= size.quantity;
                                              } else if (row?.type == "sales") {
                                                sum -= size.quantity;
                                              } else if (
                                                row?.type == "salesReturn"
                                              ) {
                                                sum += size.quantity;
                                              } else {
                                                sum += size.quantity;
                                              }
                                            }
                                          });
                                        }
                                      );
                                    }
                                  );

                                  return sum;
                                }, 0)}
                              </Index.Box>
                            </TableCell>
                          ))}
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            {reportData.reduce((sum, product) => {
                              product?.productData?.forEach((transaction) => {
                                transaction?.transactionData?.forEach((row) => {
                                  if (row?.type == "opening") {
                                    sum += row?.inward || 0;
                                  } else if (row?.type == "finishReceived") {
                                    sum += row?.inward || 0;
                                  } else if (row?.type == "salesReturn") {
                                    sum += row?.inward || 0;
                                  }
                                });
                              });

                              return sum;
                            }, 0)}
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          {reportData.reduce((sum, product) => {
                            product?.productData?.forEach((transaction) => {
                              transaction?.transactionData?.forEach((row) => {
                                if (row?.type == "finishOutward") {
                                  sum += row?.outward;
                                } else if (row?.type == "packing") {
                                  sum += row?.outward;
                                } else if (row?.type == "sales") {
                                  sum += row?.outward;
                                }
                              });
                            });

                            return sum;
                          }, 0)}
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">
                            {reportData.reduce((sum, entry) => {
                              entry?.productData?.forEach((product) => {
                                if (
                                  product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance
                                ) {
                                  sum +=
                                    product?.transactionData?.[
                                      product?.transactionData?.length - 1
                                    ]?.balance;
                                }
                              });
                              return sum;
                            }, 0)}
                          </b>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Index.Box>
      );
    } else if (watch("reportType") == "stockSummaryBal") {
      return (
        <Index.Box
          className="card-spacing page-table-main summary-stock-bal-table-main"
          style={{ display: "none" }}
        >
          <TableContainer
            component={Paper}
            className="table-container summary-table-container"
          >
            <div ref={printRef}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table summary-table webview-main-container"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan="100%"
                    >
                      <Index.Box className="table-summary-bg">
                        <Index.Typography
                          variant="h4"
                          component="h4"
                          className="view-titles"
                        >
                          Stock Report Summary (Only Balance)
                        </Index.Typography>
                      </Index.Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Color
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan={getSizeMaster?.length}
                    >
                      Size
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Inward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Outward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Balance
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>

                    {getSizeMaster
                      ?.sort((a, b) => a.type - b.type)
                      ?.map((row, index) => (
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell-th"
                          key={row?.id}
                        >
                          {row?.size_name}
                        </TableCell>
                      ))}
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.map((row, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                            colSpan={getSizeMaster?.length + 4}
                          >
                            <b className="bold-text">Product</b> :{" "}
                            {row?.ProductName}, <b>Category</b> :{" "}
                            {row?.productCategory}
                          </TableCell>
                        </TableRow>
                        {row?.productData?.map((product, index) => {
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.color_name}
                                </TableCell>
                                {getSizeMaster
                                  ?.sort((a, b) => a.type - b.type)
                                  ?.map((sizeRow, index) => (
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="table-cell-td"
                                      key={index}
                                    >
                                      {product?.transactionData?.reduce(
                                        (sum, entry) => {
                                          entry?.sizeQuantity.forEach(
                                            (transaction) => {
                                              if (
                                                transaction?.size ==
                                                sizeRow?.size_code
                                              ) {
                                                if (entry.type == "opening") {
                                                  sum += transaction.quantity;
                                                } else if (
                                                  entry.type == "finishReceived"
                                                ) {
                                                  sum += transaction.quantity;
                                                } else if (
                                                  entry.type == "finishOutward"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry.type == "packing"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry.type == "sales"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry.type == "salesReturn"
                                                ) {
                                                  sum += transaction.quantity;
                                                } else {
                                                  sum += transaction.quantity;
                                                }
                                              }
                                            }
                                          );

                                          return sum;
                                        },
                                        0
                                      )}
                                    </TableCell>
                                  ))}
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.reduce(
                                    (accumulator, transaction) => {
                                      if (transaction?.type == "opening") {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "finishReceived"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "salesReturn"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.reduce(
                                    (accumulator, transaction) => {
                                      if (
                                        transaction?.type == "finishOutward"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "packing"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else if (transaction?.type == "sales") {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance || 0}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                        <>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">Total</b>
                            </TableCell>

                            {getSizeMaster
                              ?.sort((a, b) => a.type - b.type)
                              ?.map((sizeRow, index) => (
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                  key={row?.id}
                                >
                                  <Index.Box className="table-data-text">
                                    {row?.productData?.reduce(
                                      (sum, product) => {
                                        product?.transactionData?.forEach(
                                          (transaction) => {
                                            transaction?.sizeQuantity?.forEach(
                                              (size) => {
                                                if (
                                                  size?.size ==
                                                  sizeRow?.size_code
                                                ) {
                                                  if (
                                                    transaction?.type ==
                                                    "opening"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "finishReceived"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "finishOutward"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "packing"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type == "sales"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "salesReturn"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else {
                                                    sum += size.quantity;
                                                  }
                                                }
                                              }
                                            );
                                          }
                                        );

                                        return sum;
                                      },
                                      0
                                    )}
                                  </Index.Box>
                                </TableCell>
                              ))}
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={row?.id}
                            >
                              <Index.Box className="table-data-text">
                                {row?.productData?.reduce((sum, product) => {
                                  product?.transactionData?.forEach(
                                    (transaction) => {
                                      if (transaction?.type == "opening") {
                                        sum += transaction.inward || 0;
                                      } else if (
                                        transaction?.type == "finishReceived"
                                      ) {
                                        sum += transaction.inward || 0;
                                      } else if (
                                        transaction?.type == "salesReturn"
                                      ) {
                                        sum += transaction.inward || 0;
                                      }
                                    }
                                  );

                                  return sum;
                                }, 0)}
                              </Index.Box>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.productData?.reduce((sum, product) => {
                                product?.transactionData?.forEach(
                                  (transaction) => {
                                    if (transaction?.type == "finishOutward") {
                                      sum += transaction.outward || 0;
                                    } else if (transaction?.type == "packing") {
                                      sum += transaction.outward || 0;
                                    } else if (transaction?.type == "sales") {
                                      sum += transaction.outward || 0;
                                    }
                                  }
                                );

                                return sum;
                              }, 0)}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.productData?.reduce((sum, product) => {
                                if (
                                  product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance
                                ) {
                                  sum +=
                                    product?.transactionData?.[
                                      product?.transactionData?.length - 1
                                    ]?.balance;
                                }

                                return sum;
                              }, 0)}
                            </TableCell>
                          </TableRow>
                        </>
                      </>
                    );
                  })}
                  <TableRow>
                    <TableCell
                      component="td"
                      scope="row"
                      className="table-cell-td"
                      colSpan={"100%"}
                    ></TableCell>
                  </TableRow>
                  {reportData?.length && (
                    <>
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">Grand Total</b>
                        </TableCell>

                        {getSizeMaster
                          ?.sort((a, b) => a.type - b.type)
                          ?.map((sizeRow, index) => (
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={index}
                            >
                              <Index.Box className="table-data-text">
                                {reportData.reduce((sum, product) => {
                                  product?.productData?.forEach(
                                    (transaction) => {
                                      transaction?.transactionData?.forEach(
                                        (row) => {
                                          row?.sizeQuantity.forEach((size) => {
                                            if (
                                              size?.size == sizeRow?.size_code
                                            ) {
                                              if (row?.type == "opening") {
                                                sum += size.quantity;
                                              } else if (
                                                row?.type == "finishReceived"
                                              ) {
                                                sum += size.quantity;
                                              } else if (
                                                row?.type == "finishOutward"
                                              ) {
                                                sum -= size.quantity;
                                              } else if (
                                                row?.type == "packing"
                                              ) {
                                                sum -= size.quantity;
                                              } else if (row?.type == "sales") {
                                                sum -= size.quantity;
                                              } else if (
                                                row?.type == "salesReturn"
                                              ) {
                                                sum += size.quantity;
                                              } else {
                                                sum += size.quantity;
                                              }
                                            }
                                          });
                                        }
                                      );
                                    }
                                  );

                                  return sum;
                                }, 0)}
                              </Index.Box>
                            </TableCell>
                          ))}
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            {reportData.reduce((sum, product) => {
                              product?.productData?.forEach((transaction) => {
                                transaction?.transactionData?.forEach((row) => {
                                  if (row?.type == "opening") {
                                    sum += row?.inward || 0;
                                  } else if (row?.type == "finishReceived") {
                                    sum += row?.inward || 0;
                                  } else if (row?.type == "salesReturn") {
                                    sum += row?.inward || 0;
                                  }
                                });
                              });

                              return sum;
                            }, 0)}
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          {reportData.reduce((sum, product) => {
                            product?.productData?.forEach((transaction) => {
                              transaction?.transactionData?.forEach((row) => {
                                if (row?.type == "finishOutward") {
                                  sum += row?.outward;
                                } else if (row?.type == "packing") {
                                  sum += row?.outward;
                                } else if (row?.type == "sales") {
                                  sum += row?.outward;
                                }
                              });
                            });

                            return sum;
                          }, 0)}
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">
                            {reportData.reduce((sum, entry) => {
                              entry?.productData?.forEach((product) => {
                                if (
                                  product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance
                                ) {
                                  sum +=
                                    product?.transactionData?.[
                                      product?.transactionData?.length - 1
                                    ]?.balance;
                                }
                              });
                              return sum;
                            }, 0)}
                          </b>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Index.Box>
      );
    } else if (watch("reportType") == "productGroupSummary") {
      return (
        <Index.Box
          className="card-spacing page-table-main summary-product-group-table-main"
          style={{ display: "none" }}
        >
          <TableContainer
            component={Paper}
            className="table-container summary-table-container"
          >
            <div ref={printRef}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table summary-table webview-main-container"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan="100%"
                    >
                      <Index.Box className="table-summary-bg">
                        <Index.Typography
                          variant="h4"
                          component="h4"
                          className="view-titles"
                        >
                          Product Group Wise Summary
                        </Index.Typography>
                      </Index.Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Product/Colour
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Category
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan={getSizeMaster?.length}
                    >
                      Size
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Inward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Outward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Balance
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>

                    {getSizeMaster
                      ?.sort((a, b) => a.type - b.type)
                      ?.map((row, index) => (
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell-th"
                          key={row?.id}
                        >
                          {row?.size_name}
                        </TableCell>
                      ))}
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.map((row, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                            colSpan={"100%"}
                          >
                            <b>Product Group</b> : {row?.productGroup}
                          </TableCell>
                        </TableRow>
                        {row?.productData?.map((product, index) => {
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {`${product?.ProductName}/${product?.color_name}`}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.ProdCategoryName}
                                </TableCell>
                                {getSizeMaster
                                  ?.sort((a, b) => a.type - b.type)
                                  ?.map((sizeRow, index) => (
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="table-cell-td"
                                      key={index}
                                    >
                                      {product?.transactionData?.reduce(
                                        (sum, entry) => {
                                          entry?.sizeQuantity.forEach(
                                            (transaction) => {
                                              if (
                                                transaction?.size ==
                                                sizeRow?.size_code
                                              ) {
                                                if (entry.type == "opening") {
                                                  sum += transaction.quantity;
                                                } else if (
                                                  entry.type == "finishReceived"
                                                ) {
                                                  sum += transaction.quantity;
                                                } else if (
                                                  entry.type == "finishOutward"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry.type == "packing"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry.type == "sales"
                                                ) {
                                                  sum -= transaction.quantity;
                                                } else if (
                                                  entry.type == "salesReturn"
                                                ) {
                                                  sum += transaction.quantity;
                                                } else {
                                                  sum += transaction.quantity;
                                                }
                                              }
                                            }
                                          );

                                          return sum;
                                        },
                                        0
                                      )}
                                    </TableCell>
                                  ))}
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.reduce(
                                    (accumulator, transaction) => {
                                      if (transaction?.type == "opening") {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "finishReceived"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "salesReturn"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.inward || 0)
                                        );
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.reduce(
                                    (accumulator, transaction) => {
                                      if (
                                        transaction?.type == "finishOutward"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else if (
                                        transaction?.type == "packing"
                                      ) {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else if (transaction?.type == "sales") {
                                        return (
                                          accumulator +
                                          (transaction?.outward || 0)
                                        );
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                >
                                  {product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance || 0}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                        <>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan={2}
                            >
                              <b className="bold-text">Total</b>
                            </TableCell>

                            {getSizeMaster
                              ?.sort((a, b) => a.type - b.type)
                              ?.map((sizeRow, index) => (
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                  key={row?.id}
                                >
                                  <Index.Box className="table-data-text">
                                    {row?.productData?.reduce(
                                      (sum, product) => {
                                        product?.transactionData?.forEach(
                                          (transaction) => {
                                            transaction?.sizeQuantity?.forEach(
                                              (size) => {
                                                if (
                                                  size?.size ==
                                                  sizeRow?.size_code
                                                ) {
                                                  if (
                                                    transaction?.type ==
                                                    "opening"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "finishReceived"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "finishOutward"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "packing"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type == "sales"
                                                  ) {
                                                    sum -= size.quantity;
                                                  } else if (
                                                    transaction?.type ==
                                                    "salesReturn"
                                                  ) {
                                                    sum += size.quantity;
                                                  } else {
                                                    sum += size.quantity;
                                                  }
                                                }
                                              }
                                            );
                                          }
                                        );

                                        return sum;
                                      },
                                      0
                                    )}
                                  </Index.Box>
                                </TableCell>
                              ))}
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={row?.id}
                            >
                              <Index.Box className="table-data-text">
                                {row?.productData?.reduce((sum, product) => {
                                  product?.transactionData?.forEach(
                                    (transaction) => {
                                      if (transaction?.type == "opening") {
                                        sum += transaction.inward || 0;
                                      } else if (
                                        transaction?.type == "finishReceived"
                                      ) {
                                        sum += transaction.inward || 0;
                                      } else if (
                                        transaction?.type == "salesReturn"
                                      ) {
                                        sum += transaction.inward || 0;
                                      }
                                    }
                                  );

                                  return sum;
                                }, 0)}
                              </Index.Box>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.productData?.reduce((sum, product) => {
                                product?.transactionData?.forEach(
                                  (transaction) => {
                                    if (transaction?.type == "finishOutward") {
                                      sum += transaction.outward || 0;
                                    } else if (transaction?.type == "packing") {
                                      sum += transaction.outward || 0;
                                    } else if (transaction?.type == "sales") {
                                      sum += transaction.outward || 0;
                                    }
                                  }
                                );

                                return sum;
                              }, 0)}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.productData?.reduce((sum, product) => {
                                if (
                                  product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance
                                ) {
                                  sum +=
                                    product?.transactionData?.[
                                      product?.transactionData?.length - 1
                                    ]?.balance;
                                }

                                return sum;
                              }, 0)}
                            </TableCell>
                          </TableRow>
                        </>
                      </>
                    );
                  })}
                  <TableRow>
                    <TableCell
                      component="td"
                      scope="row"
                      className="table-cell-td"
                      colSpan={"100%"}
                    ></TableCell>
                  </TableRow>
                  {reportData?.length && (
                    <>
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                          colSpan={2}
                        >
                          <b className="bold-text">Grand Total</b>
                        </TableCell>

                        {getSizeMaster
                          ?.sort((a, b) => a.type - b.type)
                          ?.map((sizeRow, index) => (
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={index}
                            >
                              <Index.Box className="table-data-text">
                                {reportData.reduce((sum, product) => {
                                  product?.productData?.forEach(
                                    (transaction) => {
                                      transaction?.transactionData?.forEach(
                                        (row) => {
                                          row?.sizeQuantity.forEach((size) => {
                                            if (
                                              size?.size == sizeRow?.size_code
                                            ) {
                                              if (row?.type == "opening") {
                                                sum += size.quantity;
                                              } else if (
                                                row?.type == "finishReceived"
                                              ) {
                                                sum += size.quantity;
                                              } else if (
                                                row?.type == "finishOutward"
                                              ) {
                                                sum -= size.quantity;
                                              } else if (
                                                row?.type == "packing"
                                              ) {
                                                sum -= size.quantity;
                                              } else if (row?.type == "sales") {
                                                sum -= size.quantity;
                                              } else if (
                                                row?.type == "salesReturn"
                                              ) {
                                                sum += size.quantity;
                                              } else {
                                                sum += size.quantity;
                                              }
                                            }
                                          });
                                        }
                                      );
                                    }
                                  );

                                  return sum;
                                }, 0)}
                              </Index.Box>
                            </TableCell>
                          ))}
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            {reportData.reduce((sum, product) => {
                              product?.productData?.forEach((transaction) => {
                                transaction?.transactionData?.forEach((row) => {
                                  if (row?.type == "opening") {
                                    sum += row?.inward || 0;
                                  } else if (row?.type == "finishReceived") {
                                    sum += row?.inward || 0;
                                  } else if (row?.type == "salesReturn") {
                                    sum += row?.inward || 0;
                                  }
                                });
                              });

                              return sum;
                            }, 0)}
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          {reportData.reduce((sum, product) => {
                            product?.productData?.forEach((transaction) => {
                              transaction?.transactionData?.forEach((row) => {
                                if (row?.type == "finishOutward") {
                                  sum += row?.outward;
                                } else if (row?.type == "packing") {
                                  sum += row?.outward;
                                } else if (row?.type == "sales") {
                                  sum += row?.outward;
                                }
                              });
                            });

                            return sum;
                          }, 0)}
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">
                            {reportData.reduce((sum, entry) => {
                              entry?.productData?.forEach((product) => {
                                if (
                                  product?.transactionData?.[
                                    product?.transactionData?.length - 1
                                  ]?.balance
                                ) {
                                  sum +=
                                    product?.transactionData?.[
                                      product?.transactionData?.length - 1
                                    ]?.balance;
                                }
                              });
                              return sum;
                            }, 0)}
                          </b>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Index.Box>
      );
    } else if (watch("reportType") == "categoryProductGroupSummary") {
      return (
        <Index.Box
          className="card-spacing page-table-main summary-category-product-group-table-main"
          style={{ display: "none" }}
        >
          <TableContainer
            component={Paper}
            className="table-container summary-table-container"
          >
            <div ref={printRef}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table summary-table webview-main-container"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan="100%"
                    >
                      <Index.Box className="table-summary-bg">
                        <Index.Typography
                          variant="h4"
                          component="h4"
                          className="view-titles"
                        >
                          Category Product Group Wise Summary
                        </Index.Typography>
                      </Index.Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Product/Colour
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                      colSpan={getSizeMaster?.length}
                    >
                      Size
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Inward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Outward
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Balance
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>

                    {getSizeMaster
                      ?.sort((a, b) => a.type - b.type)
                      ?.map((row, index) => (
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell-th"
                          key={row?.id}
                        >
                          {row?.size_name}
                        </TableCell>
                      ))}
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.map((row, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                            colSpan={"100%"}
                          >
                            <b className="bold-text">Category</b> :{" "}
                            {row?.categoryName}
                          </TableCell>
                        </TableRow>
                        {row?.categorisedProduct?.map((group, index) => {
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                  colSpan={"100%"}
                                >
                                  <b className="bold-text">Product Group</b> :{" "}
                                  {group?.productGroup}
                                </TableCell>
                              </TableRow>
                              {group?.productData?.map((product, index) => {
                                return (
                                  <>
                                    <TableRow key={index}>
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                      >
                                        {`${product?.ProductName}/${product?.color_name}`}
                                      </TableCell>
                                      {getSizeMaster
                                        ?.sort((a, b) => a.type - b.type)
                                        ?.map((sizeRow, index) => (
                                          <TableCell
                                            component="td"
                                            scope="row"
                                            className="table-cell-td"
                                            key={index}
                                          >
                                            {product?.transactionData?.reduce(
                                              (sum, entry) => {
                                                entry?.sizeQuantity.forEach(
                                                  (transaction) => {
                                                    if (
                                                      transaction?.size ==
                                                      sizeRow?.size_code
                                                    ) {
                                                      if (
                                                        entry.type == "opening"
                                                      ) {
                                                        sum +=
                                                          transaction.quantity;
                                                      } else if (
                                                        entry.type ==
                                                        "finishReceived"
                                                      ) {
                                                        sum +=
                                                          transaction.quantity;
                                                      } else if (
                                                        entry.type ==
                                                        "finishOutward"
                                                      ) {
                                                        sum -=
                                                          transaction.quantity;
                                                      } else if (
                                                        entry.type == "packing"
                                                      ) {
                                                        sum -=
                                                          transaction.quantity;
                                                      } else if (
                                                        entry.type == "sales"
                                                      ) {
                                                        sum -=
                                                          transaction.quantity;
                                                      } else if (
                                                        entry.type ==
                                                        "salesReturn"
                                                      ) {
                                                        sum +=
                                                          transaction.quantity;
                                                      } else {
                                                        sum +=
                                                          transaction.quantity;
                                                      }
                                                    }
                                                  }
                                                );

                                                return sum;
                                              },
                                              0
                                            )}
                                          </TableCell>
                                        ))}
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                      >
                                        {product?.transactionData?.reduce(
                                          (accumulator, transaction) => {
                                            if (
                                              transaction?.type == "opening"
                                            ) {
                                              return (
                                                accumulator +
                                                (transaction?.inward || 0)
                                              );
                                            } else if (
                                              transaction?.type ==
                                              "finishInward"
                                            ) {
                                              return (
                                                accumulator +
                                                (transaction?.inward || 0)
                                              );
                                            } else if (
                                              transaction?.type == "salesReturn"
                                            ) {
                                              return (
                                                accumulator +
                                                (transaction?.inward || 0)
                                              );
                                            } else {
                                              return accumulator;
                                            }
                                          },
                                          0
                                        )}
                                      </TableCell>
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                      >
                                        {product?.transactionData?.reduce(
                                          (accumulator, transaction) => {
                                            if (
                                              transaction?.type == "packing"
                                            ) {
                                              return (
                                                accumulator +
                                                (transaction?.outward || 0)
                                              );
                                            } else if (
                                              transaction?.type ==
                                              "finishOutward"
                                            ) {
                                              return (
                                                accumulator +
                                                (transaction?.outward || 0)
                                              );
                                            } else if (
                                              transaction?.type == "sales"
                                            ) {
                                              return (
                                                accumulator +
                                                (transaction?.outward || 0)
                                              );
                                            } else {
                                              return accumulator;
                                            }
                                          },
                                          0
                                        )}
                                      </TableCell>
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                      >
                                        {product?.transactionData?.[
                                          product?.transactionData?.length - 1
                                        ]?.balance || 0}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                              <>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <b className="bold-text">Total</b>
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((sizeRow, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={index}
                                      >
                                        <Index.Box className="table-data-text">
                                          {group?.productData?.reduce(
                                            (sum, product) => {
                                              product?.transactionData?.forEach(
                                                (transaction) => {
                                                  transaction?.sizeQuantity?.forEach(
                                                    (size) => {
                                                      if (
                                                        size?.size ==
                                                        sizeRow?.size_code
                                                      ) {
                                                        if (
                                                          transaction?.type ==
                                                          "opening"
                                                        ) {
                                                          sum += size.quantity;
                                                        } else if (
                                                          transaction?.type ==
                                                          "finishReceived"
                                                        ) {
                                                          sum += size.quantity;
                                                        } else if (
                                                          transaction?.type ==
                                                          "finishOutward"
                                                        ) {
                                                          sum -= size.quantity;
                                                        } else if (
                                                          transaction?.type ==
                                                          "packing"
                                                        ) {
                                                          sum -= size.quantity;
                                                        } else if (
                                                          transaction?.type ==
                                                          "sales"
                                                        ) {
                                                          sum -= size.quantity;
                                                        } else if (
                                                          transaction?.type ==
                                                          "salesReturn"
                                                        ) {
                                                          sum += size.quantity;
                                                        } else {
                                                          sum += size.quantity;
                                                        }
                                                      }
                                                    }
                                                  );
                                                }
                                              );

                                              return sum;
                                            },
                                            0
                                          )}
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                    key={row?.id}
                                  >
                                    <Index.Box className="table-data-text">
                                      {group?.productData?.reduce(
                                        (sum, product) => {
                                          product?.transactionData?.forEach(
                                            (transaction) => {
                                              if (
                                                transaction?.type == "opening"
                                              ) {
                                                sum += transaction.inward || 0;
                                              } else if (
                                                transaction?.type ==
                                                "finishReveived"
                                              ) {
                                                sum += transaction.inward || 0;
                                              } else if (
                                                transaction?.type ==
                                                "salesReturn"
                                              ) {
                                                sum += transaction.inward || 0;
                                              }
                                            }
                                          );

                                          return sum;
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {group?.productData?.reduce(
                                      (sum, product) => {
                                        product?.transactionData?.forEach(
                                          (transaction) => {
                                            if (
                                              transaction?.type == "packing"
                                            ) {
                                              sum += transaction.outward || 0;
                                            } else if (
                                              transaction?.type ==
                                              "finishOutward"
                                            ) {
                                              sum += transaction.outward || 0;
                                            } else if (
                                              transaction?.type == "sales"
                                            ) {
                                              sum += transaction.outward || 0;
                                            }
                                          }
                                        );

                                        return sum;
                                      },
                                      0
                                    )}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {group?.productData?.reduce(
                                      (sum, product) => {
                                        if (
                                          product?.transactionData?.[
                                            product?.transactionData?.length - 1
                                          ]?.balance
                                        ) {
                                          sum +=
                                            product?.transactionData?.[
                                              product?.transactionData?.length -
                                                1
                                            ]?.balance;
                                        }

                                        return sum;
                                      },
                                      0
                                    )}
                                  </TableCell>
                                </TableRow>
                              </>
                            </>
                          );
                        })}
                        <>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan={"100%"}
                            ></TableCell>
                          </TableRow>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">Sub Total</b>
                            </TableCell>

                            {getSizeMaster
                              ?.sort((a, b) => a.type - b.type)
                              ?.map((sizeRow, index) => (
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                  key={index}
                                >
                                  <Index.Box className="table-data-text">
                                    {row?.categorisedProduct?.reduce(
                                      (sum, group) => {
                                        group?.productData?.forEach(
                                          (product) => {
                                            product?.transactionData?.forEach(
                                              (transaction) => {
                                                transaction?.sizeQuantity?.forEach(
                                                  (size) => {
                                                    if (
                                                      size?.size ==
                                                      sizeRow?.size_code
                                                    ) {
                                                      if (
                                                        transaction?.type ==
                                                        "opening"
                                                      ) {
                                                        sum += size.quantity;
                                                      } else if (
                                                        transaction?.type ==
                                                        "finishReceived"
                                                      ) {
                                                        sum += size.quantity;
                                                      } else if (
                                                        transaction?.type ==
                                                        "finishOutward"
                                                      ) {
                                                        sum -= size.quantity;
                                                      } else if (
                                                        transaction?.type ==
                                                        "packing"
                                                      ) {
                                                        sum -= size.quantity;
                                                      } else if (
                                                        transaction?.type ==
                                                        "sales"
                                                      ) {
                                                        sum -= size.quantity;
                                                      } else if (
                                                        transaction?.type ==
                                                        "salesReturn"
                                                      ) {
                                                        sum += size.quantity;
                                                      } else {
                                                        sum += size.quantity;
                                                      }
                                                    }
                                                  }
                                                );
                                              }
                                            );
                                          }
                                        );
                                        return sum;
                                      },
                                      0
                                    )}
                                  </Index.Box>
                                </TableCell>
                              ))}
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={row?.id}
                            >
                              <Index.Box className="table-data-text">
                                {row?.categorisedProduct?.reduce(
                                  (sum, group) => {
                                    group?.productData?.forEach((product) => {
                                      product?.transactionData?.forEach(
                                        (transaction) => {
                                          if (
                                            transaction?.type ==
                                            "finishReceived"
                                          ) {
                                            sum += transaction.inward || 0;
                                          } else if (
                                            transaction?.type == "opening"
                                          ) {
                                            sum += transaction.inward || 0;
                                          } else if (
                                            transaction?.type == "salesReturn"
                                          ) {
                                            sum += transaction.inward || 0;
                                          }
                                        }
                                      );
                                    });
                                    return sum;
                                  },
                                  0
                                )}
                              </Index.Box>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.categorisedProduct?.reduce((sum, group) => {
                                group?.productData?.forEach((product) => {
                                  product?.transactionData?.forEach(
                                    (transaction) => {
                                      if (transaction?.type == "packing") {
                                        sum += transaction.outward || 0;
                                      } else if (
                                        transaction?.type == "finishOutward"
                                      ) {
                                        sum += transaction.outward || 0;
                                      } else if (transaction?.type == "sales") {
                                        sum += transaction.outward || 0;
                                      }
                                    }
                                  );
                                });
                                return sum;
                              }, 0)}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.categorisedProduct?.reduce((sum, group) => {
                                group?.productData?.forEach((product) => {
                                  if (
                                    product?.transactionData?.[
                                      product?.transactionData?.length - 1
                                    ]?.balance
                                  ) {
                                    sum +=
                                      product?.transactionData?.[
                                        product?.transactionData?.length - 1
                                      ]?.balance;
                                  }
                                });
                                return sum;
                              }, 0)}
                            </TableCell>
                          </TableRow>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan={"100%"}
                            ></TableCell>
                          </TableRow>
                        </>
                      </>
                    );
                  })}
                  {reportData?.length && (
                    <>
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">Grand Total</b>
                        </TableCell>

                        {getSizeMaster
                          ?.sort((a, b) => a.type - b.type)
                          ?.map((sizeRow, index) => (
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              key={index}
                            >
                              <Index.Box className="table-data-text">
                                {reportData.reduce((sum, category) => {
                                  category?.categorisedProduct?.forEach(
                                    (group) => {
                                      group?.productData?.forEach(
                                        (transaction) => {
                                          transaction?.transactionData?.forEach(
                                            (row) => {
                                              row?.sizeQuantity.forEach(
                                                (size) => {
                                                  if (
                                                    size?.size ==
                                                    sizeRow?.size_code
                                                  ) {
                                                    if (
                                                      row?.type == "opening"
                                                    ) {
                                                      sum += size.quantity;
                                                    } else if (
                                                      row?.type ==
                                                      "finishReceived"
                                                    ) {
                                                      sum += size.quantity;
                                                    } else if (
                                                      row?.type ==
                                                      "finishOutward"
                                                    ) {
                                                      sum -= size.quantity;
                                                    } else if (
                                                      row?.type == "packing"
                                                    ) {
                                                      sum -= size.quantity;
                                                    } else if (
                                                      row?.type == "sales"
                                                    ) {
                                                      sum -= size.quantity;
                                                    } else if (
                                                      row?.type == "salesReturn"
                                                    ) {
                                                      sum += size.quantity;
                                                    } else {
                                                      sum += size.quantity;
                                                    }
                                                  }
                                                }
                                              );
                                            }
                                          );
                                        }
                                      );
                                    }
                                  );
                                  return sum;
                                }, 0)}
                              </Index.Box>
                            </TableCell>
                          ))}
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            {reportData.reduce((sum, category) => {
                              category?.categorisedProduct?.forEach((group) => {
                                group?.productData?.forEach((transaction) => {
                                  transaction?.transactionData?.forEach(
                                    (row) => {
                                      if (row?.type == "finishReceived") {
                                        sum += row?.inward || 0;
                                      } else if (row?.type == "opening") {
                                        sum += row?.inward || 0;
                                      } else if (row?.type == "salesReturn") {
                                        sum += row?.inward || 0;
                                      }
                                    }
                                  );
                                });
                              });
                              return sum;
                            }, 0)}
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          {reportData.reduce((sum, category) => {
                            category?.categorisedProduct?.forEach((group) => {
                              group?.productData?.forEach((transaction) => {
                                transaction?.transactionData?.forEach((row) => {
                                  if (row?.type == "packing") {
                                    sum += row?.outward;
                                  } else if (row?.type == "finishOutward") {
                                    sum += row?.outward;
                                  } else if (row?.type == "sales") {
                                    sum += row?.outward;
                                  }
                                });
                              });
                            });
                            return sum;
                          }, 0)}
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <b className="bold-text">
                            {reportData.reduce((sum, category) => {
                              category?.categorisedProduct?.forEach((group) => {
                                group?.productData?.forEach((product) => {
                                  if (
                                    product?.transactionData?.[
                                      product?.transactionData?.length - 1
                                    ]?.balance
                                  ) {
                                    sum +=
                                      product?.transactionData?.[
                                        product?.transactionData?.length - 1
                                      ]?.balance;
                                  }
                                });
                              });
                              return sum;
                            }, 0)}
                          </b>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Index.Box>
      );
    }
  };

  if (loading) {
    <LoadingSpinner></LoadingSpinner>;
  } else {
    return (
      <div>
        <Index.Box className="main_content">
          {/* <Index.Box className="title_top_fix">
            <Index.Box className="flex_justify">
              <Index.Typography variant="h4" component="h4">
                Stock Reports
              </Index.Typography>
            </Index.Box>
          </Index.Box> */}
          <Index.Box className="web_main_content">
            <Index.Box className="over_text_scroll">
              <Index.Box className="admin-dashboard-list-row">
                <Index.Box className="stock-report-main">
                  <Index.Box
                    className="report-main-box report-main-box-set"
                    ref={filterRef}
                  >
                    <form
                      className="form-content"
                      onSubmit={handleSubmit(submit)}
                    >
                      <Index.Box className="input-box stock-report-input-box webview-input-box">
                        <Index.Box className="form-group date-of-purchase">
                          <Index.FormHelperText className="label_control">
                            As on Date
                          </Index.FormHelperText>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <Controller
                                control={control}
                                {...register("asOnDate")}
                                render={({ field: { onChange, value } }) => (
                                  <MobileDatePicker
                                    disableFuture={true}
                                    className="custom_form_control"
                                    // minDate={dayjs(selectedYearStart)}
                                    // maxDate={dayjs(selectedYearEnd)}
                                    format="DD/MM/YYYY"
                                    value={value}
                                    onChange={(newValue) => {
                                      setValue("asOnDate", newValue);
                                    }}
                                    slotProps={{
                                      textField: {
                                        size: "medium",
                                        error: Boolean(errors.asOnDate),
                                        // error : true
                                      },
                                    }}
                                    error={Boolean(errors.asOnDate)}
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box stock-report-input-box webview-input-box">
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="label_control">
                            Party
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box auto-drop-box">
                            <Controller
                              control={control}
                              {...register("party")}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  className="custom_form_control"
                                  options={sundryDebitors || []}
                                  autoHighlight
                                  onChange={(e, value) => {
                                    onChange(value?.id ? value?.id : "");
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.accountName
                                  }
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": {
                                          mr: 2,
                                          flexShrink: 0,
                                        },
                                      }}
                                      {...props}
                                    >
                                      {option?.accountName}
                                    </Index.Box>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      error={Boolean(errors?.party)}
                                      className="inner_custom_form_control"
                                      {...params}
                                      size="medium  "
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box stock-report-input-box webview-input-box">
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="label_control">
                            Product
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box auto-drop-box">
                            <Controller
                              control={control}
                              {...register("product", {})}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  className="custom_form_control"
                                  options={products || []}
                                  autoHighlight
                                  onChange={(e, value) => {
                                    onChange(
                                      value?.ProductCode
                                        ? value?.ProductCode
                                        : ""
                                    );
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.ProductName
                                  }
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": {
                                          mr: 2,
                                          flexShrink: 0,
                                        },
                                      }}
                                      {...props}
                                    >
                                      {option?.ProductName}
                                    </Index.Box>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      error={Boolean(errors?.product)}
                                      className="inner_custom_form_control"
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box stock-report-input-box webview-input-box">
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="label_control">
                            Color
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box auto-drop-box">
                            <Controller
                              control={control}
                              {...register("color", {})}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  className="custom_form_control"
                                  options={colors || []}
                                  autoHighlight
                                  onChange={(e, value) => {
                                    onChange(
                                      value?.color_code ? value?.color_code : ""
                                    );
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.color_name
                                  }
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": {
                                          mr: 2,
                                          flexShrink: 0,
                                        },
                                      }}
                                      {...props}
                                    >
                                      {option?.color_name}
                                    </Index.Box>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      error={Boolean(errors?.color)}
                                      className="inner_custom_form_control"
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box stock-report-input-box webview-input-box">
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="label_control">
                            Product Group
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box auto-drop-box">
                            <Controller
                              control={control}
                              {...register("productGroup", {
                                // required:"Princiapl party is required.",
                              })}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  className="custom_form_control"
                                  value={
                                    value
                                      ? productGroup.find((o) => o.id === value)
                                      : value || null
                                  }
                                  options={productGroup || []}
                                  autoHighlight
                                  onChange={(e, value) => {
                                    onChange(value?.id ? value?.id : "");
                                  }}
                                  getOptionLabel={(option) => option?.groupName}
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": {
                                          mr: 2,
                                          flexShrink: 0,
                                        },
                                      }}
                                      {...props}
                                    >
                                      {option?.groupName}
                                    </Index.Box>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      error={Boolean(errors?.productGroup)}
                                      className="inner_custom_form_control"
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box stock-report-input-box webview-input-box">
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="label_control">
                            Product Category
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box auto-drop-box">
                            <Controller
                              control={control}
                              {...register("productCategory")}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  className="custom_form_control"
                                  value={
                                    value
                                      ? productCategory.find(
                                          (o) => o.id === value
                                        )
                                      : value || null
                                  }
                                  options={productCategory || []}
                                  autoHighlight
                                  onChange={(e, value) => {
                                    onChange(value?.id ? value?.id : "");
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.categoryName
                                  }
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": {
                                          mr: 2,
                                          flexShrink: 0,
                                        },
                                      }}
                                      {...props}
                                    >
                                      {option?.categoryName}
                                    </Index.Box>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      error={Boolean(errors?.productCategory)}
                                      className="inner_custom_form_control"
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box stock-report-input-box webview-input-box">
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="label_control">
                            Report Type <span className="requried-star">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box auto-drop-box">
                            <Controller
                              control={control}
                              {...register("reportType", {
                                required: "Report type is required.",
                              })}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  className="custom_form_control"
                                  options={reportType || []}
                                  autoHighlight
                                  onChange={(e, value) => {
                                    onChange(value?.value ? value?.value : "");
                                    setReportData([]);
                                  }}
                                  getOptionLabel={(option) => option?.title}
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": {
                                          mr: 2,
                                          flexShrink: 0,
                                        },
                                      }}
                                      {...props}
                                    >
                                      {option?.title}
                                    </Index.Box>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      error={Boolean(errors?.reportType)}
                                      className="inner_custom_form_control"
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-flex-end ">
                        <Index.Box className="btn_linear webview-button-container">
                          <Index.Button
                            variant=""
                            className="linear-btn-main webview-submit-btn"
                            type="submit"
                          >
                            {isLoading ? (
                              <Index.Box className="webview-spinner-main">
                                <Index.CircularProgress className="webview-spinner"></Index.CircularProgress>
                              </Index.Box>
                            ) : (
                              "Submit"
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </form>
                    {/* {reportData?.length ? (
                      <Index.Box className="btn-flex-end">
                        <Index.Box className="secondary-btn-main">
                          <ContainerIndex.LinearButton
                            btnLabel="PDF"
                            className="linear-btn-main"
                            onClick={() => handlePrint()}
                          ></ContainerIndex.LinearButton>
                        </Index.Box>{" "}
                        <ContainerIndex.LinearButton
                          btnLabel="Excel"
                          className="linear-btn-main"
                          onClick={() => exportToExcel()}
                        ></ContainerIndex.LinearButton>
                      </Index.Box>
                    ) : (
                      <></>
                    )} */}
                    {reportContent(reportData)}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    );
  }
}
