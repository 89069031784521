import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import ContainerIndex from "../../../Index";
import { styled } from "@mui/material/styles";
import { TextareaAutosize } from "@mui/base";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDebitNoteSalesPrerequisiteDataApiHandler,
  generateSalesVoucherEntryApiHandler,
  getSalesVoucherEntryListApiHandler,
} from "../../../../services/AdminIndex";
import LoadingSpinner from "../../../../component/LoadingSpinner";

const Item = styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function GenerateSalesVoucher() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editId = id;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = Index.useForm();

  const dispatch = useDispatch();
  const [allVoucherNo, setAllVoucherNo] = useState([]);

  const [editData, setEditData] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const [taxCategoryOptions, setTaxCategoryOptions] = useState([]);

  const [sundryDeboters, setSundryDeboters] = useState();
  const [currencies, setCurrencies] = useState();
  const [brokers, setBrokers] = useState();
  const [hsn, setHsn] = useState();
  const [salesAccount, setSalesAccount] = useState();

  const [narrationDropdown, setNarrationDropdown] = useState(false);
  const [narrationList, setNarrationList] = useState([]);

  // API call for Get sales account, sundry deboters, brokers
  const getSalesVoucherEntryPrerequisiteData = () => {
    dispatch({ type: "LOADER_START" });
    try {
      getDebitNoteSalesPrerequisiteDataApiHandler(token, editId, 0).then(
        (data) => {
          dispatch({ type: "LOADER_START" });
          if (data.status === 201 || data.status === 200) {
            setSundryDeboters(data?.data?.sundryDebtors);
            setCurrencies(data?.data?.currencies);
            setBrokers(data?.data?.broker);
            setSalesAccount(data?.data?.salesAccount);
            setTaxCategoryOptions(data?.data?.taxCategory);
            setNarrationList(data?.data?.narration);
            setHsn(data?.data?.hsn);
            let tempArray = [];
            data?.data?.voucherNos?.map((ele) => {
              tempArray.push(ele?.voucherNo);
            });
            setAllVoucherNo(tempArray);
            dispatch({ type: "LOADER_END" });
          } else {
            dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSalesVoucherEntryPrerequisiteData();
  }, []);

  useEffect(() => {
    setBrokerOfParty(watch("party"));
  }, [sundryDeboters]);

  // Set broker, credit days, discount, taxCategory, transport and sub broker on party selection  by party
  const setBrokerOfParty = (partyId) => {
    const filteredAccountmaster = sundryDeboters?.filter(
      (ele) => ele?.id == partyId
    );
    if (!editId) {
      setValue("broker", filteredAccountmaster?.[0]?.brokerId || "");
      setValue("creditDays", filteredAccountmaster?.[0]?.creditDays || 0);
    }
    setValue(
      "salesType",
      filteredAccountmaster?.[0]?.salesType != 0
        ? filteredAccountmaster?.[0]?.salesType
        : ""
    );
  };

  useEffect(() => {
    if (!editId) {
      const voucherNo = generateInvoiceNumber();
      setValue("voucherNo", voucherNo);
    }
  }, [allVoucherNo]);
  // Generate invoice number
  function generateInvoiceNumber(str = "") {
    let invoiceNos = allVoucherNo;
    const matchingNumbers = invoiceNos?.find((item) =>
      item.startsWith(str.toUpperCase())
    );
    if (!matchingNumbers) {
      if (!invoiceNos?.length && str == "") {
        return "000000000001";
      } else {
        let originalString = str;
        let remainingLength = 12 - originalString.length;

        if (remainingLength >= 1) {
          originalString += "0".repeat(remainingLength - 1) + "1";
        }
        return originalString.toUpperCase();
      }
    } else {
      const incrementedNumbers = matchingNumbers.replace(
        /(\d+)$/,
        function (match) {
          // Increment the matched number
          return String(Number(match) + 1).padStart(match.length, "0");
        }
      );
      return incrementedNumbers.toUpperCase();
    }
  }

  useEffect(() => {
    if (!editId) {
      const currencyId = currencies?.[0]?.id;
      setValue("currency", currencyId);
    }
  }, [currencies]);

  const [taxRow, setTaxRow] = useState([
    {
      id: Math.random(),
      taxId: "",
      taxCode: "",
      taxName: "",
      netAmount: "",
      taxRate: "",
      amount: "",
      balance: "",
      calculated: "",
      calculatedOn: "",
      roundOffType: "",
      salesLiablityAccount: "",
      salesLiablityId: "",
    },
  ]);
  const { loading, token, selectedYearStart, selectedYearEnd } =
    Index.useSelector((state) => {
      return state.ERPAdminReducer;
    });

  useEffect(() => {
    if (!editId) {
      setValue("voucherDate", dayjs(selectedYearStart));
    }
  }, []);

  const getEditData = () => {
    dispatch({ type: "LOADER_START" });
    getSalesVoucherEntryListApiHandler(token, editId, 0).then((data) => {
      dispatch({ type: "LOADER_START" });
      if (data.status === 201 || data.status === 200) {
        setEditData(data?.data?.[0]);
        dispatch({ type: "LOADER_END" });
      } else {
        dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
      }
    });
  };

  // Call edit API
  useEffect(() => {
    if (editId) {
      getEditData();
    }
  }, []);

  useEffect(() => {
    if (editId) {
      setBrokerOfParty(editData?.party);

      setValue("salesAccountId", editData?.salesAccountId);
      setValue("party", editData?.party);

      setValue("voucherNo", editData?.voucherNo);
      setValue("voucherDate", dayjs(editData?.voucherDate));
      setValue("broker", editData?.brokerId);
      setValue("currency", editData?.currencyId);
      setValue("creditDays", editData?.crDays);
      setValue("note", editData?.note);
      setValue("netAmount", editData?.netAmount);
      setNarrationList((prev) => [...prev, { narrat: editData?.narration }]);
      setValue("narration", editData?.narration);
      setValue("hsnId", editData?.hsnId);

      setValue("totalTaxAmount", editData?.taxAmount);
      setValue("totalAmount", editData?.totalAmount);

      const taxData = parseData(editData?.voucherEntryTaxData);

      setTaxRow(taxData);

      taxData?.map((row, index) => {
        const rowObject = {
          id: row?.id,
          taxId: row?.taxId,
          taxCode: row?.tax_code,
          taxName: row?.tax_name,
          netAmount: row?.netAmount,
          taxRate: row?.tax_rate,
          amount: row?.taxAmount,
          balance: row?.balance,
          calculated: row?.calculated,
          calculatedOn: row?.calculated_on,
          roundOffType: row?.round_off_type,
          salesLiablityAccount: row?.salesLiablityAccount,
          salesLiablityId: row?.salesLiablityId,
        };

        setTaxRow((data) => {
          let arr = [...data];
          arr[index] = rowObject;
          return arr;
        });
      });
    }
    setBrokerOfParty(editData?.party);
  }, [editData]);
  const validateData = (arr) => {
    let error = {};
    //check if any row is empty
    arr?.map((row, mainIndex) => {
      if (!row?.taxId) {
        error["taxId" + mainIndex] = "Tax Name is require";
      }
    });
    return error;
  };
  const checkIncludeError = (key) => {
    return Object.keys(validateData(taxRow)).includes(key) && submitted;
  };

  const submit = (data) => {
    setSubmitted(true);
    if (!Object.keys(validateData(taxRow))?.length) {
      data.type = "debitNoteSales";
      data.voucherDate = ContainerIndex.convertDate(data?.voucherDate || "");

      if (editId) {
        data.id = editId;
      }

      data.taxData = taxRow;

      //account data
      const partyAccountData = {
        entryType: "debitNoteSales",
        accountId: data?.party,
        oppositeId: data?.salesAccountId,
        type: "DR",
        amount: Number(data?.totalAmount),
      };
      if (editId) {
        partyAccountData.salesReference = editId;
      }

      const salesAccountData = {
        entryType: "debitNoteSales",
        accountId: data?.salesAccountId,
        oppositeId: data?.party,
        type: "CR",
        amount: Number(data?.netAmount),
      };
      if (editId) {
        salesAccountData.salesReference = editId;
      }
      let accountEntryData = [];
      accountEntryData.push(salesAccountData);
      accountEntryData.push(partyAccountData);
      taxRow?.map((tax) => {
        if (tax?.amount) {
          let row = {
            entryType: "debitNoteSales",
            accountId: Number(tax?.salesLiablityId),
            oppositeId: data?.party,
            type: tax?.calculated != "DEDUCT" ? "CR" : "DR",
            amount: Number(tax?.amount),
          };
          if (editId) {
            row.salesReference = editId;
          }

          accountEntryData.push(row);
        }
      });

      data.accountEntryData = accountEntryData;
      try {
        generateSalesVoucherEntryApiHandler(token, data).then((response) => {
          if (response.status === 201 || response.status === 200) {
            if (editId) {
              toast.success("Sales entry updated successfully");
            } else {
              toast.success("Sales entry created successfully");
            }
            dispatch({ type: "LOADER_END" });
            navigate("/dashboard/debit-note-sales-list");
          } else {
            dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const parseData = (data) => {
    try {
      // if plain js
      if (data) {
        let result = JSON.parse(data);
        return result;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const addTaxRow = () => {
    setTaxRow((data) => [
      ...data,
      {
        id: Math.random(),
        taxId: "",
        taxCode: "",
        taxName: "",
        netAmount: "",
        taxRate: "",
        amount: "",
        balance: "",
        calculated: "",
        calculatedOn: "",
        roundOffType: "",
        salesLiablityAccount: "",
        salesLiablityId: "",
      },
    ]);
  };
  const removeTaxRow = (id) => {
    setTaxRow((data) => {
      let arr = [...data];
      return arr.filter((e) => e?.id !== id);
    });

    let netAmount = watch("netAmount");
    let totalTaxAmount = 0;
    let balance = watch("netAmount");

    let previousTaxAmount = 0;
    const modifiedTaxRow = taxRow?.filter((e) => e?.id !== id);
    modifiedTaxRow?.map((row, index) => {
      if (row?.taxId) {
        const tax = taxCategoryOptions?.find((ele) => ele?.Id == row?.taxId);
        if (
          (watch("salesType") == "Local" && tax?.tax_type != "I.GST") ||
          (watch("salesType") != "Local" &&
            tax?.tax_type != "S.GST" &&
            tax?.tax_type != "C.GST")
        ) {
          let taxAmount = 0;
          if (tax?.tax_rate) {
            if (tax?.calculated_on == "ON GROSS AMOUNT") {
              taxAmount = ((netAmount * tax?.tax_rate) / 100).toFixed(2);
            } else if (tax?.calculated_on == "BALANCE AMOUNT") {
              taxAmount = ((balance * tax?.tax_rate) / 100).toFixed(2);
            } else if (tax?.calculated_on == "REPEAT PRV.TAX AMT") {
              taxAmount = previousTaxAmount;
            } else {
              taxAmount = ((netAmount * tax?.tax_rate) / 100).toFixed(2);
            }
          } else {
            taxAmount = Number(row?.amount);
          }

          // Set this tax amount in previous tax amount for next row if has repeat previous tax amount previous
          previousTaxAmount = taxAmount;

          // get amount on base of rounded type define in tax master
          taxAmount = getRoundedTaxAmount(tax?.round_off_type, taxAmount);
          balance = getBalanceAmount(
            tax?.calculated,
            Number(balance),
            Number(taxAmount || 0)
          );

          if (tax?.calculated != "DEDUCT") {
            totalTaxAmount += Number(taxAmount);
          }

          const obj = {
            id: Math.random(),
            taxId: row?.taxId,
            taxCode: tax?.tax_code,
            taxName: tax?.tax_name,
            netAmount: Number(netAmount).toFixed(2),
            taxRate: tax?.tax_rate,
            amount: taxAmount,
            balance: balance,
            calculated: tax?.calculated,
            calculatedOn: tax?.calculated_on,
            roundOffType: tax?.round_off_type,
            salesLiablityAccount: tax?.salesLiablityAccount,
            salesLiablityId: tax?.salesLiablityId,
          };
          setTaxRow((data) => {
            let arr = [...data];
            arr[index] = obj;
            return arr;
          });
        }
      }
    });

    setValue("totalTaxAmount", Number(totalTaxAmount).toFixed(2));
    let totalAmount = Number(totalTaxAmount) + Number(netAmount);
    setValue("totalAmount", Number(totalAmount).toFixed(2));
  };

  const getRoundedTaxAmount = (round_off_type, amount) => {
    switch (round_off_type) {
      case "NEARER TO RUPEE":
        amount = Math.round(amount);
        break;
      case "NEARER TO RUPEE (UPPER SIDE)":
        amount = Math.ceil(amount);
        break;
      case "NEARER TO RUPEE (LOWER SIDE)":
        amount = Math.floor(amount);
        break;
      default:
        amount = amount;
    }
    return Number(amount).toFixed(2);
  };

  const getBalanceAmount = (type, balance, taxAmount) => {
    switch (type) {
      case "ADD":
        balance = (Number(balance) + Number(taxAmount)).toFixed(2);
        break;
      case "DEDUCT":
        balance = (Number(balance) - Number(taxAmount)).toFixed(2);
        break;
      case "IGNORE":
        balance = balance;
        break;
      default:
        balance = balance;
    }
    return Number(balance).toFixed(2);
  };
  useEffect(() => {
    let netAmount = watch("netAmount");
    let totalTaxAmount = 0;
    let balance = watch("netAmount");

    let previousTaxAmount = 0;
    taxRow?.map((row, index) => {
      if (row?.taxId) {
        const tax = taxCategoryOptions?.find((ele) => ele?.Id == row?.taxId);
        if (
          (watch("salesType") == "Local" && tax?.tax_type != "I.GST") ||
          (watch("salesType") != "Local" &&
            tax?.tax_type != "S.GST" &&
            tax?.tax_type != "C.GST")
        ) {
          let taxAmount = 0;
          if (tax?.tax_rate) {
            if (tax?.calculated_on == "ON GROSS AMOUNT") {
              taxAmount = ((netAmount * tax?.tax_rate) / 100).toFixed(2);
            } else if (tax?.calculated_on == "BALANCE AMOUNT") {
              taxAmount = ((balance * tax?.tax_rate) / 100).toFixed(2);
            } else if (tax?.calculated_on == "REPEAT PRV.TAX AMT") {
              taxAmount = previousTaxAmount;
            } else {
              taxAmount = ((netAmount * tax?.tax_rate) / 100).toFixed(2);
            }
          } else {
            taxAmount = Number(row?.amount);
          }

          // Set this tax amount in previous tax amount for next row if has repeat previous tax amount previous
          previousTaxAmount = taxAmount;

          // get amount on base of rounded type define in tax master
          taxAmount = getRoundedTaxAmount(tax?.round_off_type, taxAmount);
          balance = getBalanceAmount(
            tax?.calculated,
            Number(balance),
            Number(taxAmount || 0)
          );

          if (tax?.calculated != "DEDUCT") {
            totalTaxAmount += Number(taxAmount);
          }
          const obj = {
            id: Math.random(),
            taxId: row?.taxId,
            taxCode: tax?.tax_code,
            taxName: tax?.tax_name,
            netAmount: Number(netAmount).toFixed(2),
            taxRate: tax?.tax_rate,
            amount: taxAmount,
            balance: balance,
            calculated: tax?.calculated,
            calculatedOn: tax?.calculated_on,
            roundOffType: tax?.round_off_type,
            salesLiablityAccount: tax?.salesLiablityAccount,
            salesLiablityId: tax?.salesLiablityId,
          };
          setTaxRow((data) => {
            let arr = [...data];
            arr[index] = obj;
            return arr;
          });
        }
      }
    });
    setValue("totalTaxAmount", Number(totalTaxAmount).toFixed(2));
    let totalAmount = Number(totalTaxAmount) + Number(netAmount);
    setValue("totalAmount", Number(totalAmount).toFixed(2));
  }, [watch("netAmount")]);
  const handleChangeTaxData = (index, key, value) => {
    setTaxRow((data) => {
      let arr = [...data];
      arr[index][key] = value;
      return arr;
    });

    let netAmount = watch("netAmount");
    let totalTaxAmount = 0;
    let balance = watch("netAmount");

    let previousTaxAmount = 0;
    taxRow?.map((row, index) => {
      if (row?.taxId) {
        const tax = taxCategoryOptions?.find((ele) => ele?.Id == row?.taxId);
        if (
          (watch("salesType") == "Local" && tax?.tax_type != "I.GST") ||
          (watch("salesType") != "Local" &&
            tax?.tax_type != "S.GST" &&
            tax?.tax_type != "C.GST")
        ) {
          let taxAmount = 0;
          if (tax?.tax_rate) {
            if (tax?.calculated_on == "ON GROSS AMOUNT") {
              taxAmount = ((netAmount * tax?.tax_rate) / 100).toFixed(2);
            } else if (tax?.calculated_on == "BALANCE AMOUNT") {
              taxAmount = ((balance * tax?.tax_rate) / 100).toFixed(2);
            } else if (tax?.calculated_on == "REPEAT PRV.TAX AMT") {
              taxAmount = previousTaxAmount;
            } else {
              taxAmount = ((netAmount * tax?.tax_rate) / 100).toFixed(2);
            }
          } else {
            taxAmount = Number(row?.amount);
          }

          // Set this tax amount in previous tax amount for next row if has repeat previous tax amount previous
          previousTaxAmount = taxAmount;

          // get amount on base of rounded type define in tax master
          taxAmount = getRoundedTaxAmount(tax?.round_off_type, taxAmount);
          balance = getBalanceAmount(
            tax?.calculated,
            Number(balance),
            Number(taxAmount || 0)
          );

          if (tax?.calculated != "DEDUCT") {
            totalTaxAmount += Number(taxAmount);
          }
          const obj = {
            id: Math.random(),
            taxId: row?.taxId,
            taxCode: tax?.tax_code,
            taxName: tax?.tax_name,
            netAmount: Number(netAmount).toFixed(2),
            taxRate: tax?.tax_rate,
            amount: taxAmount,
            balance: balance,
            calculated: tax?.calculated,
            calculatedOn: tax?.calculated_on,
            roundOffType: tax?.round_off_type,
            salesLiablityAccount: tax?.salesLiablityAccount,
            salesLiablityId: tax?.salesLiablityId,
          };
          setTaxRow((data) => {
            let arr = [...data];
            arr[index] = obj;
            return arr;
          });
        }
      }
    });
    setValue("totalTaxAmount", Number(totalTaxAmount).toFixed(2));
    let totalAmount = Number(totalTaxAmount) + Number(netAmount);
    setValue("totalAmount", Number(totalAmount).toFixed(2));
  };
  if (loading) {
    <LoadingSpinner />;
  } else {
    return (
      <div>
        <Index.Box className="main_content">
          <Index.Box className="title_top_fix">
            <Index.Box className="flex_justify">
              <Index.Typography variant="h4" component="h4">
                Debit Note (Sales)
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="web_main_content">
            <Index.Box className="over_text_scroll">
              <Index.Box className="admin-dashboard-list-row">
                <form className="form-content" onSubmit={handleSubmit(submit)}>
                  <Index.Box className="report-main-box report-main-box-set">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  Debit Note{" "}
                                  <span className="requried-star">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box auto-drop-box">
                                  <Controller
                                    control={control}
                                    {...register("salesAccountId", {
                                      required: "Sales account is required.",
                                    })}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <Autocomplete
                                          className="custom_form_control"
                                          value={
                                            value
                                              ? salesAccount?.find(
                                                  (o) => o.id === value
                                                )
                                              : value || null
                                          }
                                          options={salesAccount || []}
                                          autoHighlight
                                          onChange={(e, value) => {
                                            onChange(value?.id || "");
                                          }}
                                          getOptionLabel={(option) =>
                                            option?.accountName
                                          }
                                          renderOption={(props, option) => (
                                            <Index.Box
                                              component="li"
                                              sx={{
                                                "& > img": {
                                                  mr: 2,
                                                  flexShrink: 0,
                                                },
                                              }}
                                              {...props}
                                            >
                                              {option?.accountName}
                                            </Index.Box>
                                          )}
                                          renderInput={(params) => (
                                            <Index.TextField
                                              fullWidth
                                              error={Boolean(
                                                errors?.salesAccountId
                                              )}
                                              className="inner_custom_form_control"
                                              {...params}
                                              size="small"
                                              variant="outlined"
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  Note No.{" "}
                                  <span className="requried-star">*</span>
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="custom_form_control"
                                  disabled={editId ? true : false}
                                  value={watch("voucherNo")}
                                  {...register("voucherNo", {
                                    required: "Invoice no is required.",
                                  })}
                                  inputProps={{ maxLength: 10 }}
                                  onBlur={(e) =>
                                    setValue(
                                      "voucherNo",
                                      generateInvoiceNumber(e.target.value)
                                    )
                                  }
                                  error={Boolean(errors.voucherNo)}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group date-of-purchase">
                                <Index.FormHelperText className="label_control">
                                  Note Date{" "}
                                  <span className="requried-star">*</span>
                                </Index.FormHelperText>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <Controller
                                      control={control}
                                      {...register("voucherDate", {
                                        required: "Invoice date is required.",
                                      })}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <MobileDatePicker
                                          // disableFuture={true}
                                          className="custom_form_control"
                                          minDate={dayjs(selectedYearStart)}
                                          maxDate={dayjs(selectedYearEnd)}
                                          format="DD/MM/YYYY"
                                          value={value}
                                          onChange={(newValue) => {
                                            setValue("voucherDate", newValue);
                                          }}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              error: Boolean(
                                                errors.voucherDate
                                              ),
                                              // error : true
                                            },
                                          }}
                                          error={Boolean(errors.voucherDate)}
                                        />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  Party <span className="requried-star">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box auto-drop-box">
                                  <Controller
                                    control={control}
                                    {...register("party", {
                                      required: "Party is required.",
                                    })}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Autocomplete
                                        className="custom_form_control"
                                        value={
                                          value
                                            ? sundryDeboters?.find(
                                                (o) => o.id === value
                                              )
                                            : value || null
                                        }
                                        options={sundryDeboters || []}
                                        autoHighlight
                                        disabled={editId ? true : false}
                                        onChange={(e, value) => {
                                          onChange(value?.id ? value?.id : "");
                                          setBrokerOfParty(value?.id);
                                        }}
                                        getOptionLabel={(option) =>
                                          option?.accountName
                                        }
                                        renderOption={(props, option) => (
                                          <Index.Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            {option?.accountName}
                                          </Index.Box>
                                        )}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            fullWidth
                                            error={Boolean(errors?.party)}
                                            className="inner_custom_form_control"
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  Currency{" "}
                                  <span className="requried-star">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box auto-drop-box">
                                  <Controller
                                    control={control}
                                    {...register("currency", {
                                      required: "Currency is required.",
                                    })}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Autocomplete
                                        className="custom_form_control"
                                        value={
                                          value
                                            ? currencies?.find(
                                                (o) => o.id === value
                                              )
                                            : value || null
                                        }
                                        options={currencies || []}
                                        autoHighlight
                                        onChange={(e, value) => {
                                          onChange(value?.id ? value?.id : "");
                                        }}
                                        getOptionLabel={(option) =>
                                          option?.name
                                        }
                                        renderOption={(props, option) => (
                                          <Index.Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            {option?.name}
                                          </Index.Box>
                                        )}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            fullWidth
                                            error={Boolean(errors?.currency)}
                                            className="inner_custom_form_control"
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  Cr.Days.
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  type="number"
                                  id="fullWidth"
                                  className="custom_form_control"
                                  value={watch("creditDays")}
                                  {...register("creditDays")}
                                  onChange={(e) => {
                                    if (e.target.value < 0) {
                                      setValue("creditDays", 0);
                                    } else {
                                      setValue("creditDays", e.target.value);
                                    }
                                  }}
                                  error={Boolean(errors.creditDays)}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  Broker{" "}
                                  <span className="requried-star">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box auto-drop-box">
                                  <Controller
                                    control={control}
                                    {...register("broker", {
                                      required: "Broker is required.",
                                    })}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Autocomplete
                                        className="custom_form_control"
                                        value={
                                          value
                                            ? brokers?.find(
                                                (o) => o.id === value
                                              )
                                            : value || null
                                        }
                                        options={brokers || []}
                                        autoHighlight
                                        onChange={(e, value) => {
                                          onChange(value?.id ? value?.id : "");
                                        }}
                                        getOptionLabel={(option) =>
                                          option?.accountName
                                        }
                                        renderOption={(props, option) => (
                                          <Index.Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            {option?.accountName}
                                          </Index.Box>
                                        )}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            fullWidth
                                            error={Boolean(errors?.broker)}
                                            className="inner_custom_form_control"
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  Remarks
                                </Index.FormHelperText>
                                <Controller
                                  control={control}
                                  {...register("note", {
                                    // required: "Remarks is required.",
                                  })}
                                  render={({ field: { onChange, value } }) => (
                                    <TextareaAutosize
                                      className={`custom_form_control_textarea ${
                                        errors?.note
                                          ? "remark-textarea-error"
                                          : ""
                                      }`}
                                      value={value}
                                      aria-label="empty textarea"
                                      placeholder=""
                                      minRows="2"
                                      error={Boolean(errors?.note)}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  HSN <span className="requried-star">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box auto-drop-box">
                                  <Controller
                                    control={control}
                                    {...register("hsnId", {
                                      required: "HSN is required.",
                                    })}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Autocomplete
                                        className="custom_form_control"
                                        value={
                                          value
                                            ? hsn?.find((o) => o.id === value)
                                            : value || null
                                        }
                                        options={hsn || []}
                                        autoHighlight
                                        onChange={(e, value) => {
                                          onChange(value?.id ? value?.id : "");
                                        }}
                                        getOptionLabel={(option) =>
                                          option?.HSNCode
                                        }
                                        renderOption={(props, option) => (
                                          <Index.Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            {option?.HSNCode}
                                          </Index.Box>
                                        )}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            fullWidth
                                            error={Boolean(errors?.hsnId)}
                                            className="inner_custom_form_control"
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  Narration
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box auto-drop-box">
                                  <Controller
                                    control={control}
                                    {...register("narration")}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Autocomplete
                                        open={narrationDropdown}
                                        onOpen={() => {
                                          setNarrationDropdown(true);
                                        }}
                                        onClose={() =>
                                          setNarrationDropdown(false)
                                        }
                                        className="custom_form_control"
                                        options={narrationList || []}
                                        name="narration"
                                        disableClearable
                                        closeText={""}
                                        openText={""}
                                        clearText={""}
                                        value={
                                          watch("narration")
                                            ? narrationList?.find(
                                                (o) =>
                                                  o.narrat ===
                                                  watch("narration")
                                              )
                                            : watch("narration") || null
                                        }
                                        onChange={(e, selectedOptions) => {
                                          if (!e.key) {
                                            setValue(
                                              "narration",
                                              selectedOptions?.narrat
                                            );
                                          }
                                        }}
                                        renderOption={(props, item) => (
                                          // <li {...props} key={item?.narrat}>
                                          //   {item?.narrat}
                                          // </li>
                                          <ContainerIndex.Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            {item?.narrat}
                                          </ContainerIndex.Box>
                                        )}
                                        getOptionLabel={(option) =>
                                          option?.narrat
                                        }
                                        renderInput={(params) => (
                                          <ContainerIndex.TextField
                                            fullWidth
                                            className="inner_custom_form_control"
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                setNarrationDropdown(false);
                                                e.preventDefault();
                                                const existData =
                                                  narrationList.find(
                                                    (data) =>
                                                      data?.narrat?.toLowerCase() ===
                                                      e.target.value?.toLowerCase()
                                                  );
                                                if (existData) {
                                                  setValue(
                                                    "narration",
                                                    existData
                                                  );
                                                } else {
                                                  setNarrationList((prev) => [
                                                    ...prev,
                                                    {
                                                      narrat: e.target.value,
                                                    },
                                                  ]);
                                                  setValue(
                                                    "narration",
                                                    e.target.value
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 4",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control">
                                  <span>Net Amount</span>
                                  <span className="requried-star">*</span>
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  type="number"
                                  id="fullWidth"
                                  className="custom_form_control"
                                  value={watch("netAmount")}
                                  {...register("netAmount", {
                                    required: "Net amount is required.",
                                  })}
                                  onChange={(e) => {
                                    if (e.target.value < 0) {
                                      setValue("netAmount", 0);
                                    } else {
                                      setValue("netAmount", e.target.value);
                                    }
                                  }}
                                  error={Boolean(errors.netAmount)}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label_control red">
                                  {watch("salesType")
                                    ? `Sales Type: ${watch("salesType")}`
                                    : ""}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="common-card m-30">
                    <Index.Box className="tabs-containt-main">
                      <Index.Box className="page-table-main others-salesentry-scrollbale-table-main">
                        <TableContainer
                          component={Paper}
                          className="table-container scrollable-table-container"
                          sx={{ maxHeight: 255 }}
                        >
                          <Table
                            className="table"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    No.
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Tax
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Tax Name
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Net Amount
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Rate
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Amount
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Balance
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Calc
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Action
                                  </Index.Box>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {taxRow?.map((row, index) => (
                                <TableRow key={row?.id}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {index + 1}
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {row?.taxCode}
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="dropdown-box auto-drop-box">
                                        <Autocomplete
                                          className="custom_form_control"
                                          value={
                                            taxCategoryOptions?.find(
                                              (ele) => ele?.Id == row?.taxId
                                            ) || null
                                          }
                                          disabled={
                                            watch("party") ? false : true
                                          }
                                          getOptionDisabled={(option) => {
                                            const foundIndex =
                                              taxRow?.findIndex((ele, i) => {
                                                return (
                                                  ele?.taxId === option.Id &&
                                                  index !== i
                                                );
                                              });
                                            return (
                                              foundIndex !== -1 ||
                                              (watch("salesType") == "Local" &&
                                                option?.tax_type == "I.GST") ||
                                              (watch("salesType") != "Local" &&
                                                option?.tax_type == "S.GST" &&
                                                option?.tax_type == "C.GST")
                                            );
                                          }}
                                          options={taxCategoryOptions || []}
                                          autoHighlight
                                          onChange={(e, newValue) => {
                                            handleChangeTaxData(
                                              index,
                                              "taxId",
                                              newValue?.Id
                                            );
                                          }}
                                          getOptionLabel={(option) =>
                                            `${option?.tax_name}`
                                          }
                                          renderOption={(props, option) => (
                                            <Index.Box
                                              component="li"
                                              sx={{
                                                "& > img": {
                                                  mr: 2,
                                                  flexShrink: 0,
                                                },
                                              }}
                                              {...props}
                                            >
                                              {`${option?.tax_name}`}
                                            </Index.Box>
                                          )}
                                          renderInput={(params) => (
                                            <Index.TextField
                                              fullWidth
                                              className="inner_custom_form_control"
                                              error={checkIncludeError(
                                                `taxId${index}`
                                              )}
                                              {...params}
                                              size="small"
                                              variant="outlined"
                                            />
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {row?.netAmount}
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {row?.taxRate}
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {row?.amount}
                                    </Index.Box>
                                  </TableCell>

                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {row?.balance}
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {row?.calculated == "ADD"
                                        ? "(+)"
                                        : row?.calculated == "DEDUCT"
                                        ? "(-)"
                                        : "()"}
                                    </Index.Box>
                                  </TableCell>

                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td action-plus"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="add-more-content-btn userdata-btn-flex">
                                        <>
                                          {taxRow?.length > 1 ? (
                                            <Index.Box
                                              onClick={() => {
                                                removeTaxRow(row?.id);
                                              }}
                                              className="table-btns table-trash-btn"
                                              disableRipple
                                            >
                                              <img
                                                src={Index.Png.Minus}
                                                className="table-icons"
                                                alt="Minus"
                                              ></img>
                                            </Index.Box>
                                          ) : (
                                            <></>
                                          )}
                                          {index + 1 == taxRow?.length ? (
                                            <Index.Box
                                              className="table-btns table-edit-btn"
                                              disableRipple
                                              onClick={() => {
                                                addTaxRow();
                                              }}
                                            >
                                              <img
                                                src={Index.Png.Plus}
                                                className="table-icons"
                                                alt="Plus"
                                              ></img>
                                            </Index.Box>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="gross-row">
                    <Index.FormHelperText className="gross-lable"></Index.FormHelperText>
                    <Index.FormHelperText className="gross-lable"></Index.FormHelperText>
                    <Index.FormHelperText className="gross-lable"></Index.FormHelperText>
                    <Index.FormHelperText className="gross-lable"></Index.FormHelperText>
                    <Index.FormHelperText className="gross-lable">
                      <b>Tax Amount : {watch("totalTaxAmount") || 0}</b>
                    </Index.FormHelperText>
                    <Index.FormHelperText className="gross-lable">
                      <b>Total Amount : {watch("totalAmount") || 0}</b>
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="btn-flex-end">
                    <Index.Box className="secondary-btn-main">
                      <Index.Button
                        variant=""
                        className="secondary_button"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="btn_linear">
                      <Index.Button
                        variant=""
                        className="linear-btn-main"
                        type="submit"
                      >
                        Save
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </form>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    );
  }
}
