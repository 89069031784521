import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GetAdminProfileListAction } from "../../redux/admin/action";
import {
  getYearsListApiHandler,
  setUserSelectedYearsApiHandler,
} from "../../services/AdminIndex";
import Index from "../Index";

import ContainerIndex from "../../container/Index";
import AdminMenu from "./AdminMenu";
import CompanyMenu from "./CompanyMenu";

function Header({ addClassSidebar }) {
  // for open handleChangedropdown
  const dispatch = Index.useDispatch();
  const { type, token, selectedYear, companyName } = Index.useSelector(
    (state) => {
      return state.ERPAdminReducer;
    }
  );
  const [userSelectedYear, setUserSelectedYear] = React.useState(selectedYear);

  const handleChangedropdown = (event) => {
    ContainerIndex.Swal.fire({
      title: "",
      text: "All of the current settings will be reloaded if the financial year is changed. Do you wish to proceed?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = event.target.value;
        setUserSelectedYear(value);
        if (value) {
          setUserSelectedYearsApiHandler(token, value).then((response) => {
            if (response.status === 201 || response.status === 200) {
              toast.success("Year changed successfully.");
              dispatch(GetAdminProfileListAction(token));
            } else {
              dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
            }
          });
        }
      }
    });
  };

  const [yearList, setYearList] = useState([]);

  const getYearsData = () => {
    dispatch({ type: "LOADER_START" });
    getYearsListApiHandler(token, 0, 1).then((response) => {
      if (response.status === 201 || response.status === 200) {
        setYearList(response?.data);
        dispatch({ type: "LOADER_END" });
      } else {
        dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
      }
    });
  };

  useEffect(() => {
    getYearsData();
  }, []);

  return (
    <>
      <Index.Box className="main_header_deatils">
        <Index.Box className="header_row">
          {/* Logo */}
          <Index.Box className="header_logo_col1">
            <Index.Box className="black_dash_logo">
              <Index.Link className="redirect_sidebar_page" to={"home"}>
                <img src={Index.Png.Blacklogo} className="black_logo"></img>
              </Index.Link>
            </Index.Box>
          </Index.Box>

          <Index.Box className="header_content_col2">
            <Index.Box className="flex_space_header">
              <Index.Box className="header_listing">
                <Index.List className="ul_list_details">
                  {/* Show Menus on type check */}
                  {type === "admin" && <AdminMenu />}
                  {type === "company" && <CompanyMenu />}

                  {/* <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Account}
                            className="account_list_img"
                            alt="account"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              A/c Mst.
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem>

                    <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Product}
                            className="account_list_img"
                            alt="account"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Product
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem> */}

                  {/* <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Trading}
                            className="account_list_img"
                            alt="Trading"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Trading
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem>

                    <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Banking}
                            className="account_list_img"
                            alt="Banking"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Banking
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem> */}

                  {/* <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Jv}
                            className="account_list_img"
                            alt="J.V."
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              J.V.
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem>

                    <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Prettych}
                            className="account_list_img"
                            alt="PrettyCH"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              PrettyCH
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem> */}

                  {/* <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Voucher}
                            className="account_list_img"
                            alt="Voucher"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Voucher
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem>

                    <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Finaccount}
                            className="account_list_img"
                            alt="Fin A/c"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Fin A/c
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem> */}

                  {/* <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Graph}
                            className="account_list_img"
                            alt="Graph"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Graph
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem>

                    <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Posting}
                            className="account_list_img"
                            alt="Posting"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Posting
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem> */}

                  {/* <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Ledger}
                            className="account_list_img"
                            alt="Ledger"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Ledger
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem>

                    <Index.ListItem className="li_list_accounts">
                      <Index.Link
                        className="header_redirect_main"
                        href="javascript:void(0)"
                      >
                        <Index.Box className="account_list_main">
                          <img
                            src={Index.Svg.Stock}
                            className="account_list_img"
                            alt="Stock"
                          ></img>
                          <Index.Box className="list_details_title">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="list_title_header"
                            >
                              Stock
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Link>
                    </Index.ListItem> */}
                </Index.List>
              </Index.Box>
              <Index.Box className="header-right-flex">
                {type != "admin" && (
                  <Index.Box className="company-name-box">
                    <Index.Typography className="company-name-title">
                      Company Name
                    </Index.Typography>
                    <Index.Typography className="company-name-text">
                      {companyName}
                    </Index.Typography>
                  </Index.Box>
                )}
                {/* <Index.Button className="header-year-btn">2024 - 2025</Index.Button> */}
                {/* Dropdown start */}
                {type != "admin" && (
                  <Index.Box className="admin-input-box admin-header-dropdown-main">
                    <Index.Box className="form-group">
                      <Index.Box className="admin-dropdown-box">
                        <Index.FormControl className="admin-form-control">
                          <img
                            src={Index.Svg.calendaricon}
                            className="calendar-icon"
                          ></img>
                          <Index.Select
                            className="admin-dropdown-select "
                            value={userSelectedYear}
                            onChange={handleChangedropdown}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <Index.MenuItem
                              value=""
                              className="menuitem drop-upper-text"
                            >
                              <span>SELECT YEAR</span>
                            </Index.MenuItem>
                            {yearList?.map((item) => {
                              return (
                                <Index.MenuItem
                                  key={item?.id}
                                  value={item?.id}
                                  className="menuitem"
                                >
                                  {item?.shortName}
                                </Index.MenuItem>
                              );
                            })}
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                )}
                {/* Dropdown end */}
                <Index.Box className="profile_header_main">
                  <Index.HeaderDropDown />
                </Index.Box>
                <Index.Box
                  className="mobile-berger-main"
                  onClick={addClassSidebar}
                >
                  <img
                    src={Index.Svg.mobilemenu}
                    className="mobile-menu-icon"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Header;
