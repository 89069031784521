import type from "./type";
import { toast } from "react-toastify";

const initialState = {
  isAdminLogin: false,
  active: false,
  loading: false,
  domain: "",
  type: null,
  token: null,
  adminDetail: {},
  adminProfile: {},
  getCompanyList: [],
  getSingleCompany: {},
  countryList: [],
  stateList: [],
  cityList: [],
  officeCityList: [],
  factoryCityList: [],
  getAreaMaster: [],
  getProcessMaster: [],
  getGodwonMaster: [],
  getSizeMaster: [],
  getColourMaster: [],
  getUnitMaster: [],
  getStateMaster: [],
  getNarrationMaster: [],
  getSetMaster: [],
  getCompTypeList: [],
  getIndustryTypeList: [],
  allCitys: [],
  getStateList: [],
  getAllColourList: [],
  getProductCategoryMaster: [],
  getHSNMaster: [],
  getProductMaster: [],
  getSingleProductMaster: {},
  zoneList: [],
  getOpeningStockMaster: [],
  finishStock: [],
  productGroupMaster: [],
  getSingleTaxCategoryMaster: [],
  getTaxCategoryMaster: [],
  getActiveTaxMaster: [],
  getTaxMasters: [],
  getTaxTypeList: [],
  getCalculationOnList: [],
  getItemSizePrice: [],
  getAccountGroupMasters: [],
  getActiveHeadGroupMasters: [],
  getSingleItemSizePrice: {},
  gstDuplicate: {},
  emailDuplicate: {},
  departmentList: [],
  costGroupList: [],
  fixedAssetsList: [],
  accountMasterList: [],
  multipleData: [],
  openingBalanceList: [],
  outstandingVoucherList: [],
  unclearedChequeList: [],
  getSalesPurchaseList: [],
  salesPrerequisiteData: [],
  setMasterWithSize: [],
  saleUsersData: [],
  salesOrderList: [],
  permissions: [],
  fixedAssetCompanyList: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.ADMIN_ERROR_HANDLER:
      toast.error(action.payload.message);
      return {
        ...state,
        loading: false,
      };

    case type.ADMIN_SERVER_FAILD:
      toast.error(action.payload);
      return {
        ...state,
        loading: false,
      };

    case "LOADER_START":
      return {
        ...state,
        loading: true,
      };

    case "LOADER_END":
      return {
        ...state,
        loading: false,
      };

    case type.ADMIN_LOGIN_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_LOGIN_SUCCESS:
      toast.success("Logged-in");
      return {
        ...state,
        loading: false,
        isAdminLogin: true,
        active: action.payload.data.status,
        type: action.payload.data.type,
        token: action.payload.data.token,
        adminDetail: action.payload,
        id: action.payload.data.ID,
        domain: action.payload.data.domain,
        selectedYear: action.payload.data.selectedYear,
        priceType: action.payload.data.priceType,
        selectedYearStart: action.payload.data.selectedYearStart,
        selectedYearEnd: action.payload.data.selectedYearEnd,
        companyName: action.payload.data.companyName,
        permissions: action?.payload?.data?.permissions
          ? JSON.parse(action?.payload?.data?.permissions)
          : [],
      };

    case type.ADMIN_CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_CHANGE_PASSWORD_SUCCESS:
      toast.success("Password updated");
      return {
        ...state,
        loading: false,
        //adminProfile: action.payload,
      };

    case type.ADMIN_FORGOTPASSWORD_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_FORGOTPASSWORD_SUCCESS:
      toast.success("We have sent you new password on your register email");
      return {
        ...state,
        loading: false,
      };

    case type.ADMIN_LOGOUT_REQUEST:
      return { ...state, loading: true, adminDetail: {} };

    case type.ADMIN_LOGOUT_SUCCESS:
      toast.success("Logout");
      return initialState;

    case type.ADMIN_COMPANY_LIST_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stateList: [],
        getCompanyList: action.payload.data,
      };

    case type.ADMIN_COMPANY_ADD_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_COMPANY_ADD_SUCCESS:
      toast.success("Client created");
      return {
        ...state,
        loading: false,
        stateList: [],
      };

    case type.ADMIN_COMPANY_EDIT_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_COMPANY_EDIT_SUCCESS:
      toast.success("Client updated");
      return {
        ...state,
        loading: false,
        active: 1,
        //getCompanyList: action.payload,
      };

    case type.COMPANY_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.COMPANY_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_SINGLE_COMPANY_REQUEST:
      return { ...state, loading: true };

    case type.GET_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        getSingleCompany: action.payload.data,
      };

    case type.COUNTRY_LIST_REQUEST:
      return { ...state, loading: true };

    case type.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: action.payload.data,
      };

    case type.STATE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stateList: action.payload.data,
      };

    case type.OFFICE_CITY_LIST_REQUEST:
      return { ...state, loading: true };

    case type.OFFICE_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        officeCityList: action.payload.data,
      };

    case type.FACTORY_CITY_LIST_REQUEST:
      return { ...state, loading: true };

    case type.FACTORY_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        factoryCityList: action.payload.data,
      };

    case type.ADD_AREA_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_AREA_MASTER_SUCCESS:
      toast.success("Area created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_AREA_MASTER_SUCCESS:
      toast.success("Area updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_AREA_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_AREA_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getAreaMaster: action.payload.data,
      };

    case type.AREA_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.AREA_MASTER_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.AREA_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.AREA_MASTER_DELETE_SUCCESS:
      toast.success("Area deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_PROCESS_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_PROCESS_MASTER_SUCCESS:
      toast.success("Process created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_PROCESS_MASTER_SUCCESS:
      toast.success("Process updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_PROCESS_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_PROCESS_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getProcessMaster: action.payload.data,
      };

    case type.PROCESS_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.PROCESS_MASTER_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.DELETE_PROCESS_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.DELETE_PROCESS_MASTER_SUCCESS:
      toast.success("Process deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_GODWON_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_GODWON_MASTER_SUCCESS:
      toast.success("Godown created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_GODWON_MASTER_SUCCESS:
      toast.success("Godown updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_GODWON_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_GODWON_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getGodwonMaster: action.payload.data,
      };

    case type.GODWON_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.GODWON_MASTER_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.DELETE_GODWON_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.DELETE_GODWON_MASTER_SUCCESS:
      toast.success("Godown deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_SIZE_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_SIZE_MASTER_SUCCESS:
      toast.success("Size created");
      return {
        ...state,
        loading: false,
        //getSizeMaster: action.payload.data,
      };

    case type.UPDATE_SIZE_MASTER_SUCCESS:
      toast.success("Size updated");
      return {
        ...state,
        loading: false,
        //getSizeMaster: action.payload.data,
      };

    case type.GET_SIZE_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_SIZE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getSizeMaster: action.payload.data,
      };

    case type.ADMIN_SIZE_MASTER_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_SIZE_MASTER_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.DELETE_SIZE_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.DELETE_SIZE_MASTER_SUCCESS:
      toast.success("Size deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_COLOUR_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_COLOUR_MASTER_SUCCESS:
      toast.success("Colour created");
      return {
        ...state,
        loading: false,
        //getColourMaster: action.payload.data,
      };

    case type.UPDATE_COLOUR_MASTER_SUCCESS:
      toast.success("Colour updated");
      return {
        ...state,
        loading: false,
        //getColourMaster: action.payload.data,
      };

    case type.GET_COLOUR_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_COLOUR_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getColourMaster: action.payload.data,
      };

    case type.COLOUR_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.COLOUR_MASTER_APPROVE_SUCCESS:
      toast.success("Colour updated");
      return {
        ...state,
        loading: false,
      };

    case type.DELETE_COLOUR_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.DELETE_COLOUR_MASTER_SUCCESS:
      toast.success("Colour deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_UNIT_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_UNIT_MASTER_SUCCESS:
      toast.success("Unit created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_UNIT_MASTER_SUCCESS:
      toast.success("Unit updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_UNIT_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_UNIT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getUnitMaster: action.payload.data,
      };

    case type.ADMIN_UNIT_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_UNIT_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.UNIT_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.UNIT_MASTER_DELETE_SUCCESS:
      toast.success("Unit deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_STATE_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_STATE_MASTER_SUCCESS:
      toast.success("State created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_STATE_MASTER_SUCCESS:
      toast.success("State updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_STATE_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_STATE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getStateMaster: action.payload.data,
      };

    case type.ADD_NARRATION_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_NARRATION_MASTER_SUCCESS:
      toast.success("Narration created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_NARRATION_MASTER_SUCCESS:
      toast.success("Narration updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_NARRATION_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_NARRATION_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getNarrationMaster: action.payload.data,
      };

    case type.NARRATION_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.NARRATION_MASTER_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.NARRATION_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.NARRATION_MASTER_DELETE_SUCCESS:
      toast.success("Narration deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_SET_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_SET_MASTER_SUCCESS:
      toast.success("Set created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_SET_MASTER_SUCCESS:
      toast.success("Set updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_SET_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_SET_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getSetMaster: action.payload.data,
      };

    case type.SET_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.SET_MASTER_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.SET_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.SET_MASTER_DELETE_SUCCESS:
      toast.success("Set deleted");
      return {
        ...state,
        loading: false,
      };

    case type.GET_ALL_COLOUR_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_ALL_COLOUR_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllColourList: action.payload.data,
      };

    case type.GET_COMPANY_TYPE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_COMPANY_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getCompTypeList: action.payload.data,
      };

    case type.GET_INDUSTRY_TYPE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_INDUSTRY_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndustryTypeList: action.payload.data,
      };

    case type.EDIT_ADMIN_PROFILE_REQUEST:
      return { ...state, loading: true };

    case type.EDIT_ADMIN_PROFILE_SUCCESS:
      toast.success("Profile updated");
      return {
        ...state,
        loading: false,
        adminProfile: action.payload.data,
      };

    case type.GET_ADMIN_PROFILE_REQUEST:
      return { ...state, loading: true };

    case type.GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        adminProfile: action.payload.data,
        selectedYear: action.payload.data.selectedYear,
        selectedYearStart: action.payload.data.selectedYearStart,
        selectedYearEnd: action.payload.data.selectedYearEnd,
        permissions: action?.payload?.data?.permissions
          ? JSON.parse(action?.payload?.data?.permissions)
          : [],
      };

    case type.ALL_CITY_LIST_REQUEST:
      return { ...state, loading: true };

    case type.ALL_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allCitys: action.payload.data,
      };

    case type.ADD_COUNTRY_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_COUNTRY_MASTER_SUCCESS:
      toast.success("Country created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_COUNTRY_MASTER_SUCCESS:
      toast.success("Country updated");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_CITY_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_CITY_MASTER_SUCCESS:
      toast.success("City created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_CITY_MASTER_SUCCESS:
      toast.success("City updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_CITY_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_CITY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        cityList: action.payload.data,
      };

    case type.GET_ADMINA_COUNTRY_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_ADMINA_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: action.payload.data,
      };

    case type.ADMIN_COUNTRY_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_COUNTRY_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.COUNTRY_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.COUNTRY_MASTER_DELETE_SUCCESS:
      toast.success("Country deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADMIN_STATE_MASTER_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_STATE_MASTER_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.ADMIN_STATE_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_STATE_MASTER_DELETE_SUCCESS:
      toast.success("State deleted");
      return {
        ...state,
        loading: false,
      };

    case type.CITY_STATE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.CITY_STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getStateList: action.payload.data,
      };

    case type.ADMIN_CITY_MASTER_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.ADMIN_CITY_MASTER_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.CITY_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.CITY_MASTER_DELETE_SUCCESS:
      toast.success("City deleted");
      return {
        ...state,
        loading: false,
      };

    case type.PRODUCT_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.PRODUCT_GROUP_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        productGroupMaster: action.payload.data,
      };

    case type.ADD_PRODUCT_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_PRODUCT_GROUP_MASTER_SUCCESS:
      toast.success("Group name created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_PRODUCT_GROUP_MASTER_SUCCESS:
      toast.success("Group name updated.");
      return {
        ...state,
        loading: false,
      };

    case type.PRODUCT_GROUP_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.PRODUCT_GROUP_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.DELETE_PRODUCT_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.DELETE_PRODUCT_GROUP_MASTER_SUCCESS:
      toast.success("Group name deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_PRODUCT_CATEGORY_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_PRODUCT_CATEGORY_MASTER_SUCCESS:
      toast.success("Product category created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_PRODUCT_CATEGORY_MASTER_SUCCESS:
      toast.success("Product category updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_PRODUCT_CATEGORY_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_PRODUCT_CATEGORY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getProductCategoryMaster: action.payload.data,
      };

    case type.PRODUCT_CATEGORY_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.PRODUCT_CATEGORY_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.PRODUCT_CATEGORY_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.PRODUCT_CATEGORY_MASTER_DELETE_SUCCESS:
      toast.success("Product category deleted");
      return {
        ...state,
        loading: false,
      };

    case type.GET_HSN_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_HSN_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getHSNMaster: action.payload.data,
      };

    case type.ADD_HSN_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_HSN_MASTER_SUCCESS:
      toast.success("HSN created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_HSN_MASTER_SUCCESS:
      toast.success("HSN updated");
      return {
        ...state,
        loading: false,
      };

    case type.HSN_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.HSN_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.HSN_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.HSN_MASTER_DELETE_SUCCESS:
      toast.success("HSN deleted");
      return {
        ...state,
        loading: false,
      };

    case type.GET_PRODUCT_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_PRODUCT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getProductMaster: action.payload.data,
      };

    case type.GET_SINGLE_PRODUCT_MASTER_REQUEST:
      return { ...state, loading: true, getSingleProductMaster: {} };

    case type.GET_SINGLE_PRODUCT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getSingleProductMaster: action.payload.data,
      };

    case type.ADD_PRODUCT_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_PRODUCT_MASTER_SUCCESS:
      toast.success("Product created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_PRODUCT_MASTER_SUCCESS:
      toast.success("Product updated");
      return {
        ...state,
        loading: false,
      };

    case type.PRODUCT_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.PRODUCT_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.PRODUCT_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.PRODUCT_MASTER_DELETE_SUCCESS:
      toast.success("Product deleted");
      return {
        ...state,
        loading: false,
      };
    case type.GET_ZONE_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_ZONE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        zoneList: action.payload.data,
      };

    case type.ZONE_MASTER_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.ZONE_MASTER_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.ZONE_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.ZONE_MASTER_DELETE_SUCCESS:
      toast.success("Zone deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_ZONE_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_ZONE_MASTER_SUCCESS:
      toast.success("Zone created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_ZONE_MASTER_SUCCESS:
      toast.success("Zone updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_OPENING_STOCK_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_OPENING_STOCK_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getOpeningStockMaster: action.payload.data,
      };

    case type.ADD_OPENING_STOCK_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_OPENING_STOCK_MASTER_SUCCESS:
      toast.success("Opening stock created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_OPENING_STOCK_MASTER_SUCCESS:
      toast.success("Opening stock updated");
      return {
        ...state,
        loading: false,
      };

    case type.OPENING_STOCK_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.OPENING_STOCK_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.OPENING_STOCK_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.OPENING_STOCK_MASTER_DELETE_SUCCESS:
      toast.success("Opening stock deleted");
      return {
        ...state,
        loading: false,
      };
    case type.ADD_FINISH_STOCK_REQUEST:
      return { ...state, loading: true };

    case type.ADD_FINISH_STOCK_SUCCESS:
      toast.success("Finish stock created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_FINISH_STOCK_SUCCESS:
      toast.success("Finish stock updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_FINISH_STOCK_REQUEST:
      return { ...state, loading: true };

    case type.GET_FINISH_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        finishStock: action.payload.data,
      };

    case type.FINISH_STOCK_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.FINISH_STOCK_DELETE_SUCCESS:
      toast.success("Stock deleted");
      return {
        ...state,
        loading: false,
      };

    case type.GET_TAX_CATEGORY_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_SINGLE_TAX_CATEGORY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getSingleTaxCategoryMaster: action.payload.data,
      };

    case type.GET_TAX_CATEGORY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getTaxCategoryMaster: action.payload.data,
      };

    case type.TAX_CATEGORY_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.TAX_CATEGORY_MASTER_DELETE_SUCCESS:
      toast.success("Tax category deleted");
      return {
        ...state,
        loading: false,
      };

    case type.TAX_CATEGORY_MASTER_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.TAX_CATEGORY_MASTER_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_TAX_CATEGORY_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_TAX_CATEGORY_MASTER_SUCCESS:
      toast.success("Tax category created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_TAX_CATEGORY_MASTER_SUCCESS:
      toast.success("Tax category updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_ACTIVE_TAX_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_ACTIVE_TAX_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getActiveTaxMaster: action.payload.data,
      };

    case type.GET_TAX_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_TAX_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getTaxMasters: action.payload.data,
      };

    case type.ADD_TAX_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_TAX_MASTER_SUCCESS:
      toast.success("Tax master created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_TAX_MASTER_SUCCESS:
      toast.success("Tax master updated");
      return {
        ...state,
        loading: false,
      };

    case type.TAX_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.TAX_MASTER_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.DELETE_TAX_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.DELETE_TAX_MASTER_SUCCESS:
      toast.success("Tax master deleted");
      return {
        ...state,
        loading: false,
      };

    case type.GET_TAX_TYPE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_TAX_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getTaxTypeList: action.payload.data,
      };

    case type.GET_CALCULATION_TYPE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_CALCULATION_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getCalculationOnList: action.payload.data,
      };

    case type.ADD_ITEM_SIZE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.ADD_ITEM_SIZE_LIST_SUCCESS:
      toast.success("Price item added.");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_ITEM_SIZE_LIST_SUCCESS:
      toast.success("Price item updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_ITEM_SIZE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_ITEM_SIZE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getItemSizePrice: action.payload.data,
      };

    case type.GET_SINGLE_ITEM_SIZE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getSingleItemSizePrice: action.payload.data,
      };

    case type.GET_ACCOUNT_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_ACCOUNT_GROUP_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getAccountGroupMasters: action.payload.data,
      };

    case type.GET_ACTIVE_HEAD_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_ACTIVE_HEAD_GROUP_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getActiveHeadGroupMasters: action.payload.data,
      };

    case type.ADD_ACCOUNT_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_ACCOUNT_GROUP_MASTER_SUCCESS:
      toast.success("Group master added.");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_ACCOUNT_GROUP_MASTER_SUCCESS:
      toast.success("Group master updated");
      return {
        ...state,
        loading: false,
      };

    case type.ACCOUNT_GROUP_MASTER_STATUS_REQUEST:
      return { ...state, loading: true };

    case type.ACCOUNT_GROUP_MASTER_STATUS_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.ACCOUNT_GROUP_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.ACCOUNT_GROUP_MASTER_DELETE_SUCCESS:
      toast.success("Group master deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ITEM_SIZE_LIST_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.ITEM_SIZE_LIST_APPROVE_SUCCESS:
      toast.success("Price item updated");
      return {
        ...state,
        loading: false,
      };

    case type.ITEM_SIZE_LIST_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.ITEM_SIZE_LIST_DELETE_SUCCESS:
      toast.success("Price item updated");
      return {
        ...state,
        loading: false,
      };

    case type.CHECK_DUPLICATE_COMPANY_DATA_REQUEST:
      return { ...state, loading: true };

    case type.CHECK_DUPLICATE_GST_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        gstDuplicate: action.payload.data,
      };

    case type.CHECK_DUPLICATE_EMAIL_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        emailDuplicate: action.payload.data,
      };

    case type.GET_DEPARTMENT_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_DEPARTMENT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentList: action.payload.data,
      };

    case type.DEPARTMENT_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.DEPARTMENT_MASTER_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.DELETE_DEPARTMENT_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.DELETE_DEPARTMENT_MASTER_SUCCESS:
      toast.success("Department deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_DEPARTMENT_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_DEPARTMENT_MASTER_SUCCESS:
      toast.success("Department created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_DEPARTMENT_MASTER_SUCCESS:
      toast.success("Department updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_COST_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_COST_GROUP_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        costGroupList: action.payload.data,
      };

    case type.ACTIVE_COST_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ACTIVE_COST_GROUP_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        activeGroupList: action.payload.data,
      };

    case type.COST_GROUP_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.COST_GROUP_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.COST_GROUP_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.COST_GROUP_MASTER_DELETE_SUCCESS:
      toast.success("Cost group deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_COST_GROUP_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_COST_GROUP_MASTER_SUCCESS:
      toast.success("Cost group created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_COST_GROUP_MASTER_SUCCESS:
      toast.success("Cost group updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_FIXED_ASSET_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.GET_FIXED_ASSET_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedAssetsList: action.payload.data,
      };

    case type.FIXED_ASSET_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.FIXED_ASSET_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.FIXED_ASSET_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.FIXED_ASSET_MASTER_DELETE_SUCCESS:
      toast.success("Fixed asset deleted");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_FIXED_ASSET_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_FIXED_ASSET_MASTER_SUCCESS:
      toast.success("Fixed asset created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_FIXED_ASSET_MASTER_SUCCESS:
      toast.success("Fixed asset updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_ACCOUNT_MASTER_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_ACCOUNT_MASTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        accountMasterList: action.payload.data,
      };

    case type.ADD_ACCOUNT_MASTER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_ACCOUNT_MASTER_SUCCESS:
      toast.success("Account ledger created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_ACCOUNT_MASTER_SUCCESS:
      toast.success("Account ledger updated");
      return {
        ...state,
        loading: false,
      };

    case type.ACCOUNT_MASTER_APPROVE_REQUEST:
      return { ...state, loading: true };

    case type.ACCOUNT_MASTER_APPROVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.ACCOUNT_MASTER_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.ACCOUNT_MASTER_DELETE_SUCCESS:
      toast.success("Account ledger deleted");
      return {
        ...state,
        loading: false,
      };

    case type.GET_MULTIPLE_DATA_REQUEST:
      return { ...state, loading: true };

    case type.GET_MULTIPLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        multipleData: action.payload.data,
      };

    case type.GET_BIND_DATA_REQUEST:
      return { ...state, loading: true };

    case type.GET_BIND_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        bindDataData: action.payload.data,
      };
    case type.GET_OPENING_BALANCE_REQUEST:
      return { ...state, loading: true };

    case type.GET_OPENING_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        openingBalanceList: action.payload.data,
      };

    case type.OPENING_BALANCE_ACTIVE_INACTIVE_REQUEST:
      return { ...state, loading: true };

    case type.OPENING_BALANCE_ACTIVE_INACTIVE_SUCCESS:
      toast.success("Status updated");
      return {
        ...state,
        loading: false,
      };

    case type.ADD_OPENING_BALANCE_REQUEST:
      return { ...state, loading: true };

    case type.ADD_OPENING_BALANCE_SUCCESS:
      toast.success("Opening balance created");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_OPENING_BALANCE_SUCCESS:
      toast.success("Opening balance updated");
      return {
        ...state,
        loading: false,
      };

    case type.OPENING_BALANCE_DELETE_REQUEST:
      return { ...state, loading: true };

    case type.OPENING_BALANCE_DELETE_SUCCESS:
      toast.success("Opening balance deleted");
      return {
        ...state,
        loading: false,
      };

    case type.GET_OUTSTANDING_VOUCHER_REQUEST:
      return { ...state, outstandingVoucherList: [], loading: true };

    case type.GET_OUTSTANDING_VOUCHER_SUCCESS:
      return {
        ...state,
        loading: false,
        outstandingVoucherList: action.payload.data,
      };

    case type.ADD_OUTSTANDING_VOUCHER_REQUEST:
      return { ...state, loading: true };

    case type.ADD_OUTSTANDING_VOUCHER_SUCCESS:
      toast.success("Record inserted");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_OUTSTANDING_VOUCHER_SUCCESS:
      toast.success("Record updated");
      return {
        ...state,
        loading: false,
      };

    case type.GET_UNCLEARED_CHEQUE_REQUEST:
      return { ...state, loading: true, unclearedChequeList: [] };

    case type.GET_UNCLEARED_CHEQUE_SUCCESS:
      return {
        ...state,
        loading: false,
        unclearedChequeList: action.payload.data,
      };

    case type.ADD_UNCLEARED_CHEQUE_REQUEST:
      return { ...state, loading: true };

    case type.ADD_UNCLEARED_CHEQUE_SUCCESS:
      toast.success("Record inserted");
      return {
        ...state,
        loading: false,
      };

    case type.UPDATE_UNCLEARED_CHEQUE_SUCCESS:
      toast.success("Record updated");
      return {
        ...state,
        loading: false,
      };

    case type.RESEND_VERIFICATION_EMAIL_REQUEST:
      return { ...state, loading: true };

    case type.RESEND_VERIFICATION_EMAIL_SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        loading: false,
      };

    case type.GET_SALSE_PURCHASE_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_SALSE_PURCHASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getSalesPurchaseList: action.payload.data,
      };

    case type.GET_SALES_PREREQUISITE_DATA_REQUEST:
      return { ...state, loading: true };

    case type.GET_SALES_PREREQUISITE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        salesPrerequisiteData: action.payload.data,
      };

    case type.GET_SET_MASTER_WITH_SIZE_REQUEST:
      return { ...state, loading: true };

    case type.GET_SET_MASTER_WITH_SIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        setMasterWithSize: action.payload.data,
      };

    case type.GET_SALES_USERS_DROPDOWN_DATA_REQUEST:
      return { ...state, loading: true };

    case type.GET_SALES_USERS_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        saleUsersData: action.payload.data,
      };

    case type.CREATE_SALES_ORDER_REQUEST:
      return { ...state, loading: true };

    case type.CREATE_SALES_ORDER_SUCCESS:
      toast.success("Sales order created successfully");
      return { ...state, loading: false };

    case type.GET_SALES_LIST_REQUEST:
      return { ...state, loading: true };

    case type.GET_SALES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        salesOrderList: action.payload.data,
      };

    case type.GET_FIXED_ASSET_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedAssetCompanyList: action.payload.data,
      };

    case type.GET_SALES_EDIT_REQUEST:
      return { ...state, loading: true };

    case type.GET_SALES_EDIT_SUCCESS:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default Reducer;
