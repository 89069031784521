import React from "react";
import Index from "../Index";

const AdminMenu = () => {
  const adminMenus = [
    // {
    //   title: "A/c Mst.",
    //   menuLogo: Index.Svg.Account,
    //   url: "acmast",
    // },
    // {
    //   title: "Product",
    //   menuLogo: Index.Svg.Product,
    //   url: "Product",
    // },
    // {
    //   title: "Trading",
    //   menuLogo: Index.Svg.Trading,
    //   url: "Trading",
    // },
    // {
    //   title: "Banking",
    //   menuLogo: Index.Svg.Banking,
    //   url: "Banking",
    // },
    // {
    //   title: "J.V.",
    //   menuLogo: Index.Svg.Jv,
    //   url: "Jv",
    // },
    // {
    //   title: "PrettyCH",
    //   menuLogo: Index.Svg.Prettych,
    //   url: "PrettyCH",
    // },
    // {
    //   title: "Voucher",
    //   menuLogo: Index.Svg.Voucher,
    //   url: "Voucher",
    // },
    // {
    //   title: "Fin A/c",
    //   menuLogo: Index.Svg.Finaccount,
    //   url: "Finaccount",
    // },
    // {
    //   title: "Graph",
    //   menuLogo: Index.Svg.Graph,
    //   url: "Graph",
    // },
    // {
    //   title: "Posting",
    //   menuLogo: Index.Svg.Posting,
    //   url: "Posting",
    // },
    // {
    //   title: "Ledger",
    //   menuLogo: Index.Svg.Ledger,
    //   url: "Ledger",
    // },
    // {
    //   title: "Stock",
    //   menuLogo: Index.Svg.Stock,
    //   url: "Stock",
    // },
  ];

  return (
    <>
      {adminMenus.map((item, index) => {
        return (
          <Index.ListItem key={index} className="li_list_accounts">
            <Index.Link className="header_redirect_main" href={item?.url}>
              <Index.Box className="account_list_main">
                <img
                  src={item?.menuLogo}
                  className="account_list_img"
                  alt="account"
                ></img>
                <Index.Box className="list_details_title">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="list_title_header"
                  >
                    {item?.title}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Link>
          </Index.ListItem>
        );
      })}
    </>
  );
};

export default AdminMenu;
