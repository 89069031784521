import Account from './images/svg/account.svg'
import Product from './images/svg/product.svg'
import Trading from './images/svg/trading.svg'
import Banking from './images/svg/banking.svg'
import Jv from './images/svg/jv.svg'
import Prettych from './images/svg/prettych.svg'
import Voucher from './images/svg/voucher.svg'
import Finaccount from './images/svg/fin-account.svg'
import Graph from './images/svg/graph.svg'
import Posting from './images/svg/posting.svg'
import Ledger from './images/svg/ledger.svg'
import Stock from './images/svg/stock.svg'
// sidebar
import Dashgray from './images/svg/dashboard-gray.svg'
import Dashwhite from './images/svg/dashboard-white.svg'
import mastergray from './images/svg/mastermaintenance-gray.svg'
import masterwhite from './images/svg/mastermaintenance-white.svg'
import Dailyentrygray from './images/svg/daily-entry-gray.svg'
import Dailyentrywhite from './images/svg/daily-entry-white.svg'
import Reportsgray from './images/svg/reports-gray.svg'
import Reportswhite from './images/svg/reports-white.svg'
import ordergray from './images/svg/order-gray.svg'
import orderwhite from './images/svg/order-white.svg'
import utilitygray from './images/svg/utility-gray.svg'
import utilitywhite from './images/svg/utility-white.svg'
import RightDrop from './images/svg/right-drop.svg'
// home card
import Cardoneblack from './images/svg/card-svg-1-black.svg'
import Cardonewhite from './images/svg/card-svg-1-white.svg'
import Cardtwoblack from './images/svg/card-svg-2-black.svg'
import Cardtwowhite from './images/svg/card-svg-2-white.svg'
import Cardthreeblack from './images/svg/card-svg-3-black.svg'
import Cardthreewhite from './images/svg/card-svg-3-white.svg'
import Cardfourblack from './images/svg/card-svg-4-black.svg'
import Cardfourwhite from './images/svg/card-svg-4-white.svg'
import whiteedit from './images/svg/white-edit.svg';
import whiteeye from './images/svg/white-eye.svg';
import whitetrash from './images/svg/white-trash.svg';
import downflag from './images/svg/down-flag.svg';
import calendaricon from './images/svg/calendar.svg';
import profileicon from './images/svg/profile.svg';
import resetpassword from './images/svg/reset-password.svg';
import signouticon from './images/svg/out.svg';
import dashboardicon from './images/svg/dashboard.svg';
import mastermaintenance from './images/svg/master-maintenance.svg';
import accountmaster from './images/svg/account-master.svg';
import productmaster from './images/svg/product-master.svg';
import taxmaster from './images/svg/tax-master.svg';
import accountgroupmaster from './images/svg/account-group-master.svg';
import inneraccountmaster from './images/svg/inner-account-master.svg';
import openingbalance from './images/svg/opening-balance.svg';
import productgroupmaster from './images/svg/product-group-master.svg';
import innerproductmaster from './images/svg/inner-product-master.svg';
import productcategorymaster from './images/svg/product-category-master.svg';
import barcodemaster from './images/svg/barcode-master.svg';
import hsnmaster from './images/svg/hsn-master.svg';
import openingstock from './images/svg/opening-stock.svg';
import finishstock from './images/svg/finis-stock.svg';
import otherstock from './images/svg/other-stock.svg';
import pricelist from './images/svg/price-list.svg';
import pricedown from './images/svg/price-down.svg';
import priceup from './images/svg/price-up.svg';
import taxcategorymaster from './images/svg/tax-category-master.svg';
import innertaxmaster from './images/svg/inner-tax-master.svg';
import othermaster from './images/svg/other-master.svg';
import godownmaster from './images/svg/godown-master.svg';
import areamaster from './images/svg/area-master.svg';
import narrationmaster from './images/svg/narration-master.svg';
import departmentmaster from './images/svg/department-master.svg';
import sizemaster from './images/svg/size-master.svg';
import colormaster from './images/svg/color-master.svg';
import setmaster from './images/svg/set-master.svg';
import processmaster from './images/svg/process-master.svg';
import costgroupmaster from './images/svg/cost-group-master.svg';
import fixedasset from './images/svg/fixed-asset.svg';
import dailyentry from './images/svg/daily-entery.svg';
import purchaseions from './images/svg/purchase.svg';
import grnentry from './images/svg/grn-entry.svg';
import purchaselist from './images/svg/purchase-list.svg';
import purchasentrysizewise from './images/svg/purchase-entery-size-wise.svg';
import purchasereturn from './images/svg/purchase-return.svg';
import salesicons from './images/svg/sales-icon.svg';
import challanentry from './images/svg/challan-entry.svg';
import salesentry from './images/svg/sales-entry.svg';
import salesretrun from './images/svg/sales-return.svg';
import othersalesentry from './images/svg/other-sales-entry.svg';
import cashbankentry from './images/svg/cash-bank-entry.svg';
import purchasereport from './images/svg/purchase-report.svg';
import grnreports from './images/svg/grn-reports.svg';
import purchaseregister from './images/svg/purchas-register.svg';
import purchaseretruns from './images/svg/purchase-retuns.svg';
import salesreports from './images/svg/sales-reports.svg';
import challanreport from './images/svg/chalaan-report.svg';
import salesregiter from './images/svg/sales-regiter.svg';
import salesregiterreturn from './images/svg/sales-register-return.svg';
import materialstock from './images/svg/matrial-stock.svg';
import stockreport from './images/svg/stock-report.svg';
import orderprocessing from './images/svg/order-processing.svg';
import purchaseorder from './images/svg/purchase-order.svg';
import purchaseorderreport from './images/svg/purchase-order-report.svg';
import innerpurchaseorder from './images/svg/inner-purchase-order.svg';
import salesorder from './images/svg/sales-order.svg';
import salesorderport from './images/svg/sales-order-report.svg';
import manageuser from './images/svg/manage-user.svg';
import rolemaster from './images/svg/role-master.svg';
import usercreation from './images/svg/user-creation.svg';
import countrymaster from './images/svg/country-master.svg';
import statemaster from './images/svg/state-master.svg';
import citymaster from './images/svg/city-master.svg';
import unitmaster from './images/svg/unit-master.svg';
import zonemaster from './images/svg/zone-master.svg';
import yearmaster from './images/svg/year-master.svg';
import clientmaster from './images/svg/clinet-master.svg';
import finishentery from './images/svg/finish-entry.svg';
import utilityicon from './images/svg/utility.svg';
import mastericon from './images/svg/master-icon.svg';
import mobilemenu from './images/svg/menu.svg';






const Svg = {
    Account,
    Product,
    Trading,
    Banking,
    Jv,
    Prettych,
    Voucher,
    Finaccount,
    Graph,
    Posting,
    Ledger,
    Stock,
    Dashgray,
    Dashwhite,
    mastergray,
    masterwhite,
    Dailyentrygray,
    Dailyentrywhite,
    Reportsgray,
    Reportswhite,
    ordergray,
    orderwhite,
    utilitygray,
    utilitywhite,
    RightDrop,
    Cardoneblack,
    Cardonewhite,
    Cardtwoblack,
    Cardtwowhite,
    Cardthreeblack,
    Cardthreewhite,
    Cardfourblack,
    Cardfourwhite,
    whitetrash,
    whiteedit,
    whiteeye,
    downflag,
    calendaricon,
    signouticon,
    resetpassword,
    profileicon,
    dashboardicon,
    mastermaintenance,
    accountmaster,
    productmaster,
    taxmaster,
    accountgroupmaster,
    inneraccountmaster,
    openingbalance,
    productgroupmaster,
    innerproductmaster,
    productcategorymaster,
    barcodemaster,
    hsnmaster,
    openingstock,
    finishstock,
    otherstock,
    pricelist,
    pricedown,
    priceup,
    taxcategorymaster,
    innertaxmaster,
    othermaster,
    godownmaster,
    areamaster,
    narrationmaster,
    departmentmaster,
    sizemaster,
    colormaster,
    setmaster,
    processmaster,
    costgroupmaster,
    fixedasset,
    dailyentry,
    purchaseions,
    grnentry,
    purchaselist,
    purchasentrysizewise,
    purchasereturn,
    salesicons,
    challanentry,
    salesentry,
    salesretrun,
    othersalesentry,
    cashbankentry,
    purchasereport,
    grnreports,
    purchaseregister,
    purchaseretruns,
    salesreports,
    challanreport,
    salesregiter,
    salesregiterreturn,
    materialstock,
    stockreport,
    orderprocessing,
    purchaseorder,
    purchaseorderreport,
    innerpurchaseorder,
    salesorder,
    salesorderport,
    manageuser,
    rolemaster,
    usercreation,
    countrymaster,
    statemaster,
    unitmaster,
    citymaster,
    zonemaster,
    yearmaster,
    clientmaster,
    finishentery,
    utilityicon,
    mastericon,
    mobilemenu,
}

export default Svg;