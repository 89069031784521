import React from 'react'
import Index from "../../../Index";
export default function AccessPage() {
  return (
    <div>
     {/* <DynamicTitle title="404 Page not found" /> */}
      <Index.Box className="login_main forgot_main">
        <Index.Box className="login_pd_container login_pd_container-access">
          <Index.Box className="cus_row align_center_text">
            <Index.Box className="left_bg_login">
              <Index.Box className="bg_logins bg_logins-access">
                <Index.Box className="login_details_main">
                  <img src={Index.Png.Logo} className="logo_login" />
                  <Index.Box className="login_title_content">
                    <Index.Typography
                      variant="h5"
                      component="h5"
                      className="main_title_login"
                    >
                     401 Unauthorized
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="main_content_login"
                    >
                     We are sorry but you don't have access for this page
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

         
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  )
}
