import React from "react";
import Index from "../Index";
import * as XLSX from "xlsx-color";

export default function ExportSampleSheet(props) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
//   const fileType = "text/csv;charset=utf-8";
//   const fileExtension = ".csv";
  const exportToCSV = (headerKeys, fileName) => {
    const applyCellStyle = (cell, style, ws) => {
      ws[cell].s = {
        font: {
          color: { rgb: style?.fontColor || "FFFFFF" },
          bold: style.bold,
        },
        alignment: { horizontal: "center", vertical: "center" },
      };
    };

    const wsData = [];

    //Row Data
    wsData.push(headerKeys);

    const RowA = headerKeys.map((_, colIndex) => {
      return XLSX.utils.encode_cell({ r: 0, c: colIndex });
    });

    const stylesRowA = Array.from(
      { length: headerKeys?.length },
      (_, index) => {
        return {
          bold: false,
          italic: false,
          underline: false,
          fontColor: props?.requiredIndex?.includes(index) ? "FF0000" : "000000",
        };
      }
    );

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    RowA.forEach((cell, index) => {
      applyCellStyle(cell, stylesRowA[index], ws);
    });

    const colWidth = headerKeys.map((cell, index) => ({ wch: 15 }));

    ws["!cols"] = colWidth;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + fileExtension;
    link.click();
  };
  return (
    <Index.Box className="btn_linear">
      <Index.Button
        className={props.className}
        onClick={(e) => exportToCSV(props?.headerKeys, props?.fileName)}
        disableRipple
        disabled={props.disabled}
      >
        {props.btnLabel}
      </Index.Button>
    </Index.Box>
  );
}
