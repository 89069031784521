import Logo from "./images/png/logo.png"
import Blacklogo from "./images/png/black-logo.png"
import User from "./images/png/user.png"
import Dashboard from "./images/png/dashboard.png"
import Ordergraph from "./images/png/order-graph.png"
import Revenuegraph from "./images/png/revenue-graph.png"
import Report from "./images/png/report.png"
import Cancel from "./images/png/cancel.png"
import Profile from "./images/png/profile.png"
import Search from "./images/png/search.png"
import Minus from "./images/png/minus-sign.png"
import Plus from "./images/png/plus.png"
import ImagePreview from "./images/png/image-preview.png"
import test from "./images/png/test.png"
import bannerimg from "./images/png/bannerimg.png"
import successfully from "./images/png/successfully.png"


const Png = {
    Logo,
    Blacklogo,
    User,
    Dashboard,
    Ordergraph,
    Revenuegraph,
    Report,
    Cancel,
    Profile,
    Search,
    Minus,
    Plus,
    ImagePreview,
    test,
    bannerimg,
    successfully
}

export default Png;