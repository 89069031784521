import React from "react";
import Index from "../Index";

const CompanyMenu = () => {
  const companyMenus = [
    {
      title: "Comp One",
      menuLogo: Index.Svg.Account,
      url: "/dashboard/home",
    },
    {
      title: "Comp Two",
      menuLogo: Index.Svg.Product,
      url: "/dashboard/home",
    },
    {
      title: "Comp Three",
      menuLogo: Index.Svg.Trading,
      url: "/dashboard/home",
    },
    {
      title: "J.V.",
      menuLogo: Index.Svg.Jv,
      url: "/dashboard/jv-list",
    },
    {
      title: "PrettyCH",
      menuLogo: Index.Svg.Prettych,
      url: "/dashboard/home",
    },
    {
      title: "Voucher",
      menuLogo: Index.Svg.Voucher,
      url: "/dashboard/home",
    },
    {
      title: "Fin A/c",
      menuLogo: Index.Svg.Finaccount,
      url: "/dashboard/home",
    },
    {
      title: "Banking",
      menuLogo: Index.Svg.Banking,
      url: "/dashboard/home",
    },
    {
      title: "Graph",
      menuLogo: Index.Svg.Graph,
      url: "/dashboard/home",
    },
    {
      title: "Posting",
      menuLogo: Index.Svg.Posting,
      url: "/dashboard/home",
    },
  ];

  return (
    <>
      {companyMenus.map((item, index) => {
        return (
          <Index.ListItem key={index} className="li_list_accounts">
            <Index.Link className="header_redirect_main" to={item?.url}>
              <Index.Box className="account_list_main">
                <img
                  src={item?.menuLogo}
                  className="account_list_img"
                  alt="account"
                ></img>
                <Index.Box className="list_details_title">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="list_title_header"
                  >
                    {item?.title}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Link>
          </Index.ListItem>
        );
      })}
    </>
  );
};

export default CompanyMenu;
