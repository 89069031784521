import { Autocomplete } from "@mui/material";
import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { getBarcodeChildListAPIHandler } from "../../../../services/AdminIndex";


const useStyles = Index.makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const BarcodeDropdown = ({
  productRow,
  token,
  setOpen,
  barcodeIndex,
  handleChangeProductData,
}) => {
  const [barcodeList, setBarcodeList] = useState([]);
  const [selectedBarcode, setSelectedBarcode] = useState('');
  const handleClose = () => {
    setOpen(false);
  };

  const getBarcodeList = () => {
    getBarcodeChildListAPIHandler(token).then((response) => {
      if (response.status === 201 || response.status === 200) {
        setBarcodeList(response?.data);
      }
    });
  };

  useEffect(() => {
    getBarcodeList();
  }, []);

  const handleChangeBarcode = async (barcodeData) => {
    const checkExist = productRow?.filter(
      (row, index) =>
        row.productId == barcodeData?.productCode &&
        row.colorId == barcodeData?.colorId &&
        index != barcodeIndex
    );

    if (checkExist?.length) {
      alert("Product already exist");
    } else {
      setSelectedBarcode(barcodeData);
      await handleChangeProductData(
        barcodeIndex,
        "productId",
        barcodeData?.productCode
      );
      await handleChangeProductData(
        barcodeIndex,
        "colorId",
        barcodeData?.colorId
      );
      await handleChangeProductData(barcodeIndex, "setId", barcodeData?.setId);
      setOpen(false);
    }
  };

  return (
    <Index.Box className="">
      <Index.Box className="modal-header">
        <Index.Typography
          varient="h4"
          component="h4"
          className="modal-title"
        >
          Select Barcode
        </Index.Typography>
        <Index.Button className="modal-close-btn" onClick={handleClose}>
          <img
            src={Index.Png.Cancel}
            className="cancel-img"
            alt="cancel"
          />
        </Index.Button>
      </Index.Box>


      <Index.Box className="modal-body">
        <Index.Box className="modal-content-main modal-scroll">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              // gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Index.Box className="form-group">
                  {/* <Index.FormHelperText className="label_control">
                      Barcode
                    </Index.FormHelperText> */}
                  <Index.Box className="dropdown-box dropdown-box-barcode auto-drop-box">
                    <Index.FormControl className="form-control">
                      <Autocomplete
                        className="custom_form_control"
                        value={selectedBarcode || null}
                        options={barcodeList}
                        autoHighlight
                        onChange={(e, value) => {
                          handleChangeBarcode(value);
                        }}
                        getOptionLabel={(option) =>
                          `${option?.generatedBarcode}-${option?.productCode}-${option?.color_name}-${option?.setName}`
                        }
                        renderOption={(props, option) => (
                          <Index.Box
                            component="li"
                            sx={{
                              "& > img": {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            {`${option?.generatedBarcode}-${option?.productCode}-${option?.color_name}-${option?.setName}`}
                          </Index.Box>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="inner_custome_form_control"
                            {...params}
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="modal-footer-main">
          <Index.Box className="modal-btn-flex">
            <Index.SecondaryButton
              btnLabel="Cancel"
              className="secondary_button cancel_btn"
              onClick={handleClose}
            ></Index.SecondaryButton>
            <Index.LinearButton
              // disabled={loading}
              btnLabel="Submit"
              className="linear-btn-main savechange_btn"
              type="submit"
            ></Index.LinearButton>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default BarcodeDropdown;
