import React, { useState } from "react";
import Index from "../../../Index";
import { Autocomplete } from "@mui/material";
import moment from "moment";

const stylemodal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const Item = Index.styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

let num = ["MRP", "rate"];
const useStyles = Index.makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const BillModal = ({
  handleClose,
  partybills,
  bills,
  setBills,
  setSelectedBillsAmount,
  advNo,
  generateAdvanceNo,
  editId,
  setRemovedBills,
  removedBills,
}) => {
  const classes = useStyles();
  const [openChildModal, setOpenChildModal] = useState(false);
  const [submitted, setSubbmitted] = useState(false);
  const [selectedBill, setSelectedBills] = useState(bills);

  const [selectedData, setSelectedData] = useState({
    id: Math.random(),
    billType: "",
    billId: "",
    status: "against",
    billDate: "",
    billNo: "",
    amount: 0,
    billAmount: "",
  });
  const statusTypes = [
    { label: "Agt Bill", value: "against" },
    { label: "Advance", value: "advance" },
  ];
  const {
    handleSubmit,
    formState: { errors },
  } = Index.useForm();

  const handleOpenChildModal = () => {
    setOpenChildModal(true);
  };

  const handleCloseChildModal = () => {
    setOpenChildModal(false);
  };

  const validData = (data) => {
    let error = {};
    if (!data.status) {
      error.status = "Status is require";
    }
    if (!data.billNo) {
      error.billNo = "Bill no is require";
    }
    if (!data.amount) {
      error.amount = "Amount is require";
    }
    if (!Number(data.amount) > 0) {
      error.amount = "Amount should be greater than 0";
    }
    return error;
  };

  const checkIncudeError = (key) => {
    return Object.keys(validData(selectedData)).includes(key) && submitted;
  };

  const submit = (data) => {
    setSubbmitted(true);
    if (!Object.keys(validData(selectedData)).length) {
      console.log(selectedData, 113);
      setSelectedBills([...selectedBill, selectedData]);
      const obj = {
        billType: "",
        billId: "",
        status: "against",
        billDate: "",
        billNo: "",
        amount: "0",
        billAmount: "",
      };
      setSubbmitted(false);
      setSelectedData(obj);
    }
  };
  const handleSelectBillRow = (bill) => {
    console.log(bill, 132);
    let obj = {
      id: Math.random(),
      billType: bill?.type,
      billId: bill?.id,
      status: "against",
      billNo: bill?.invoiceNo,
      billDate: bill?.invoiceDate,
      amount: bill?.salesReturnRemainAmount,
      billAmount: bill?.totalAmount,
    };
    setSelectedData(obj);
    handleCloseChildModal(false);
  };
  const handleChangeSelectedData = (key, value, effectiveAccount) => {
    setSelectedData((data) => {
      let obj = { ...data };
      obj[key] = value;
      if (key == "discount" && effectiveAccount && value) {
        obj["discountAcc"] = effectiveAccount;
      }

      if (key == "tds" && effectiveAccount && value) {
        obj["tdsAcc"] = effectiveAccount;
      }

      if (key == "interest" && effectiveAccount && value) {
        obj["interestAcc"] = effectiveAccount;
      }

      if (key == "otherExpense" && effectiveAccount && value) {
        obj["otherExpenseAcc"] = effectiveAccount;
      }
      return obj;
    });

    if (key == "status" && value == "advance") {
      let newBillNo = "";
      //find if one advance row exist

      // Filter the array to include only items with status "advance"
      const filteredData = selectedBill.filter(
        (item) => item.status === "advance"
      );

      // Sort the filtered data by billNo in descending order
      const sortedData = filteredData.sort((a, b) =>
        b.billNo.localeCompare(a.billNo)
      );

      // Get the first item (the one with the highest billNo)
      const highestAdvanceNo = sortedData[0]?.billNo;

      if (highestAdvanceNo) {
        newBillNo = generateAdvanceNo(highestAdvanceNo);
      } else {
        newBillNo = advNo;
      }
      setSelectedData((data) => {
        let obj = { ...data };

        obj["id"] = Math.random();
        obj["billType"] = "creditNote";
        obj["billId"] = 0;
        obj["billDate"] = moment().format("YYYY-MM-DD");
        obj["billNo"] = newBillNo;
        obj["billAmount"] = 0;
        return obj;
      });
    }
    setSelectedData((data) => {
      let obj = { ...data };
      let amount = 0;
      if (obj?.amount > obj?.billAmount && obj?.status != "advance") {
        amount = obj?.billAmount;
      } else {
        amount = obj?.amount;
      }
      let netAmount = amount;
      netAmount -= Number(obj?.discount || 0);
      netAmount -= Number(obj?.tds || 0);
      netAmount += Number(obj?.interest || 0);
      netAmount += Number(obj?.otherExpense || 0);

      obj["amount"] = amount;
      obj["netAmount"] = netAmount;
      return obj;
    });
  };

  const removeSelectedBill = (id) => {
    setSelectedBills(selectedBill?.filter((ele) => ele?.id != id));
    if (id >= 1) {
      setRemovedBills([...removedBills, id]);
    }
  };
  const handleClickFinalSubmit = () => {
    if (selectedBill?.length) {
      const total = selectedBill?.reduce((accumulator, object) => {
        return Number(accumulator) + Number(object.amount);
      }, 0);
      setSelectedBillsAmount(total);
      setBills(selectedBill);
      handleClose();
    }
  };

  return (
    <>
      <Index.Box className="modal-header">
        <Index.Button className="modal-close-btn" onClick={handleClose}>
          <img src={Index.Png.Cancel} className="cancel-img" alt="close"></img>
        </Index.Button>
      </Index.Box>

      <Index.Box className="modal-content-main modal-scroll">
        <Index.Box className="inner-modal-card">
          <Index.Box className="page-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container inner-modal-table-container border-container"
            >
              <Index.Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell component="th" className="table-th">
                      Sr.
                    </Index.TableCell>
                    <Index.TableCell component="th" className="table-th">
                      Bill No.
                    </Index.TableCell>
                    {/* <Index.TableCell component="th" className="table-th">
                      Bill Date
                    </Index.TableCell> */}
                    <Index.TableCell component="th" className="table-th">
                      Amount
                    </Index.TableCell>
                    <Index.TableCell component="th" className="table-th">
                      Bill Amount.
                    </Index.TableCell>
                    <Index.TableCell component="th" className="table-th">
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {selectedBill?.length ? (
                    selectedBill?.map((row, i, arr) => (
                      <Index.TableRow key={row?.name} className="table-row">
                        <Index.TableCell component="td" className="table-td">
                          {i + 1}
                        </Index.TableCell>
                        <Index.TableCell component="td" className="table-td">
                          {row?.billNo}
                        </Index.TableCell>
                        {/* <Index.TableCell component="td" className="table-td">
                          {row?.billDate}
                        </Index.TableCell> */}
                        <Index.TableCell component="td" className="table-td">
                          {row?.amount}
                        </Index.TableCell>
                        <Index.TableCell component="td" className="table-td">
                          {row?.billAmount}
                        </Index.TableCell>
                        <Index.TableCell component="td" className="table-td">
                          <Index.Box className="add-more-content-btn userdata-btn-flex">
                            <Index.Box
                              onClick={() => {
                                removeSelectedBill(row?.id);
                              }}
                              className="table-btns table-trash-btn"
                              disableRipple
                            >
                              <img
                                src={Index.Png.Minus}
                                className="table-icons"
                              ></img>
                            </Index.Box>
                          </Index.Box>
                        </Index.TableCell>
                      </Index.TableRow>
                    ))
                  ) : (
                    <>
                      <Index.TableRow>
                        <Index.TableCell
                          className="no-record-found-text"
                          colSpan={"100%"}
                          align="center"
                        >
                          No record found
                        </Index.TableCell>
                      </Index.TableRow>
                    </>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
        <form className="form-content" onSubmit={handleSubmit(submit)}>
          <Index.Box
            sx={{ width: 1 }}
            className="grid-main size-down-card-main common-card"
          >
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 4",
                  sm: "span 3",
                  md: "span 2",
                  lg: "span 2",
                }}
                className="grid-column"
              >
                <Item className="grid-item">
                  <Index.Box className="input-box">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label_control"></Index.FormHelperText>
                      <Index.Box className="btn_linear inner-product-btn-main">
                        <Index.Button
                          className="linear-btn-main"
                          variant="contained"
                          onClick={() => handleOpenChildModal()}
                        >
                          Select Bill
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Item>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 4",
                  md: "span 4",
                  lg: "span 2",
                }}
                className="grid-column"
              >
                <Item className="grid-item">
                  <Index.Box className="input-box">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label_control">
                        Status <span className="requried-star">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="dropdown-box">
                        <Index.FormControl className="custom_form_control">
                          <Autocomplete
                            className="custom_form_control"
                            value={
                              statusTypes?.find(
                                (ele) => ele?.value == selectedData?.status
                              ) || null
                            }
                            options={statusTypes || []}
                            autoHighlight
                            onChange={(e, newValue) => {
                              handleChangeSelectedData(
                                "status",
                                newValue?.value
                              );
                            }}
                            getOptionLabel={(option) => `${option?.label}`}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": {
                                    mr: 2,
                                    flexShrink: 0,
                                  },
                                }}
                                {...props}
                              >
                                {`${option?.label}`}
                              </Index.Box>
                            )}
                            renderInput={(params) => (
                              <Index.TextField
                                fullWidth
                                error={checkIncudeError("status")}
                                className="inner_custom_form_control"
                                {...params}
                                size="small"
                                variant="outlined"
                              />
                            )}
                          />
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Item>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 3",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Item className="grid-item">
                  <Index.Box className="input-box">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label_control">
                        Bill No <span className="requried-star">*</span>
                      </Index.FormHelperText>
                      <Index.TextField
                        fullWidth
                        value={selectedData?.billNo}
                        error={checkIncudeError("billNo")}
                        name="billNo"
                        className="custom_form_control"
                        disabled
                      />
                    </Index.Box>
                  </Index.Box>
                </Item>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 3",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Item className="grid-item">
                  <Index.Box className="input-box">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label_control">
                        Amount <span className="requried-star">*</span>
                      </Index.FormHelperText>
                      <Index.TextField
                        fullWidth
                        value={selectedData?.amount}
                        name="amount"
                        error={checkIncudeError("amount")}
                        onChange={(e) => {
                          handleChangeSelectedData("amount", e.target.value);
                        }}
                        className={`custom_form_control ${classes.input}`}
                        onWheel={(event) => event.target.blur()}
                        type="number"
                      />
                    </Index.Box>
                  </Index.Box>
                </Item>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 4",
                  md: "span 4",
                  lg: "span 2",
                }}
                className="grid-column"
              >
                <Item className="grid-item">
                  <Index.Box className="input-box">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label_control">
                        Bill Amount <span className="requried-star">*</span>
                      </Index.FormHelperText>
                      <Index.TextField
                        fullWidth
                        value={selectedData?.billAmount}
                        name="billAmount"
                        disabled
                        error={checkIncudeError("billAmount")}
                        className={`custom_form_control ${classes.input}`}
                        onWheel={(event) => event.target.blur()}
                        type="number"
                      />
                    </Index.Box>
                  </Index.Box>
                </Item>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 4",
                  md: "span 4",
                  lg: "span 2",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box">
                  <Index.Box className="form-group">
                    <Index.FormHelperText className="label_control"></Index.FormHelperText>
                    <Index.Box className="modal-btn-flex">
                      <Index.LinearButton
                        // disabled={loading}
                        btnLabel="Add"
                        className="linear-btn-main savechange_btn"
                        type="submit"
                      ></Index.LinearButton>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
      </Index.Box>
      <Index.Box className="modal-footer-main">
        <Index.Box className="modal-btn-flex">
          <p className="jv-bill-total">{`Bill Total :${selectedBill?.reduce(
            (sum, object) =>
              Number(Number(sum) + Number(object?.amount)).toFixed(2),
            0
          )}`}</p>
          <Index.SecondaryButton
            btnLabel="Cancel"
            className="secondary_button cancel_btn"
            onClick={() => handleClose()}
          ></Index.SecondaryButton>
          <Index.LinearButton
            // disabled={loading}
            btnLabel="Save"
            className="linear-btn-main savechange_btn"
            type="button"
            disabled={selectedBill?.length ? false : true}
            onClick={() => handleClickFinalSubmit()}
          ></Index.LinearButton>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openChildModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={stylemodal}
          className="modal-content-inner bill-nested-content-modal-inner"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              varient="h4"
              component="h4"
              className="modal-title"
            >
              List
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseChildModal}
            >
              <img
                src={Index.Png.Cancel}
                className="cancel-img"
                alt="close"
              ></img>
            </Index.Button>
          </Index.Box>
          <Index.Box className="modal-content-main modal-scroll">
            <Index.Box className="inner-modal-card">
              <Index.Box className="page-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container inner-modal-table-container border-container"
                >
                  <Index.Table aria-label="simple table" className="table">
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell component="th" className="table-th">
                          Sr.
                        </Index.TableCell>
                        <Index.TableCell component="th" className="table-th">
                          Bill No.
                        </Index.TableCell>
                        <Index.TableCell component="th" className="table-th">
                          Date
                        </Index.TableCell>
                        <Index.TableCell component="th" className="table-th">
                          Amt.
                        </Index.TableCell>
                        <Index.TableCell component="th" className="table-th">
                          Bill Amt.
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {partybills?.length ? (
                        partybills?.map((row, i) => (
                          <Index.TableRow
                            key={row?.name}
                            className={`table-row ${
                              selectedBill?.find(
                                (ele) =>
                                  ele?.billId == row?.id &&
                                  ele?.billType == row?.type
                              )
                                ? "disabled-sales-row"
                                : "cursor-pointer"
                            }`}
                            onClick={() => handleSelectBillRow(row)}
                          >
                            <Index.TableCell
                              component="td"
                              className="table-td"
                            >
                              {i + 1}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              className="table-td"
                            >
                              {row?.invoiceNo}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              className="table-td"
                            >
                              {row?.invoiceDate}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              className="table-td"
                            >
                              {Number(row?.salesReturnRemainAmount).toFixed(2)}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              className="table-td"
                            >
                              {Number(row?.totalAmount).toFixed(2)}
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <>
                          <Index.TableRow>
                            <Index.TableCell
                              className="no-record-found-text"
                              colSpan={"100%"}
                              align="center"
                            >
                              No record found
                            </Index.TableCell>
                          </Index.TableRow>
                        </>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default BillModal;
