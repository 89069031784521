import React from 'react'
import Index from '../Index'

function SecondaryButton(props) {
  return (
    <>
    <Index.Box className='secondary-btn-main'>
        <Index.Button className={props.className} onClick={props.onClick} disableRipple>{props.btnLabel}</Index.Button>
    </Index.Box>

    </>
  )
}

export default SecondaryButton