import React from "react";
import Index from "../Index";

function LinearButton(props) {
  return (
    <>
      <Index.Box className="btn_linear">
        <Index.Button
          className={props.className}
          onClick={props.onClick}
          disableRipple
          type={props.type}
          disabled={props.disabled}
        >
          {props.btnLabel}
        </Index.Button>
      </Index.Box>
    </>
  );
}

export default LinearButton;
