import "./App.css";
import Routes from "./routes/Routes";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import LoadingOverlay from "react-loading-overlay";
import Index from "./component/Index";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";

function App() {

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: "400",
        lineHeight: "normal",
      },
    },
    breakpoints: {
      values: {
        xxs: 0,
        xs: 375,
        sm: 550,
        md: 768,
        lg: 1024,
        xl: 1250,
      },
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const { loading } = Index.useSelector((state) => {
    return state.ERPAdminReducer;
  });
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <ThemeProvider theme={theme}>
      <LoadingOverlay active={isLoading} spinner text="Loading your content...">
        <Routes />
      </LoadingOverlay>
    </ThemeProvider>
  );
}

export default App;
