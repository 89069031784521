export default {

    ADMIN_SERVER_FAILD: "ADMIN_SERVER_FAILD",
    ADMIN_ERROR_HANDLER: "ADMIN_ERROR_HANDLER",

    ADMIN_LOGIN_REQUEST: "ADMIN_LOGIN_REQUEST",
    ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  
    ADMIN_LOGOUT_REQUEST: "ADMIN_LOGOUT_REQUEST",
    ADMIN_LOGOUT_SUCCESS: "ADMIN_LOGOUT_SUCCESS",

    ADMIN_FORGOTPASSWORD_REQUEST: "ADMIN_FORGOTPASSWORD_REQUEST",
    ADMIN_FORGOTPASSWORD_SUCCESS: "ADMIN_FORGOTPASSWORD_SUCCESS",

    ADMIN_CHANGE_PASSWORD_REQUEST: "ADMIN_CHANGE_PASSWORD_REQUEST",
    ADMIN_CHANGE_PASSWORD_SUCCESS: "ADMIN_CHANGE_PASSWORD_SUCCESS",

    ADMIN_COMPANY_LIST_REQUEST: "ADMIN_COMPANY_LIST_REQUEST",
    ADMIN_COMPANY_LIST_SUCCESS: "ADMIN_COMPANY_LIST_SUCCESS",

    ADMIN_COMPANY_ADD_REQUEST: "ADMIN_COMPANY_ADD_REQUEST",
    ADMIN_COMPANY_ADD_SUCCESS: "ADMIN_COMPANY_ADD_SUCCESS",

    GET_SINGLE_COMPANY_REQUEST: "GET_SINGLE_COMPANY_REQUEST",
    GET_SINGLE_COMPANY_SUCCESS: "GET_SINGLE_COMPANY_SUCCESS",

    ADMIN_COMPANY_EDIT_REQUEST: "ADMIN_COMPANY_EDIT_REQUEST",
    ADMIN_COMPANY_EDIT_SUCCESS: "ADMIN_COMPANY_EDIT_SUCCESS",

    COUNTRY_LIST_REQUEST: "COUNTRY_LIST_REQUEST",
    COUNTRY_LIST_SUCCESS: "COUNTRY_LIST_SUCCESS",

    STATE_LIST_REQUEST: "STATE_LIST_REQUEST",
    STATE_LIST_SUCCESS: "STATE_LIST_SUCCESS",

    OFFICE_CITY_LIST_REQUEST: "OFFICE_CITY_LIST_REQUEST",
    OFFICE_CITY_LIST_SUCCESS: "OFFICE_CITY_LIST_SUCCESS",

    FACTORY_CITY_LIST_REQUEST: "FACTORY_CITY_LIST_REQUEST",
    FACTORY_CITY_LIST_SUCCESS: "FACTORY_CITY_LIST_SUCCESS",

    COMPANY_STATUS_REQUEST: "COMPANY_STATUS_REQUEST",
    COMPANY_STATUS_SUCCESS: "COMPANY_STATUS_SUCCESS",

    ADD_AREA_MASTER_REQUEST: "ADD_AREA_MASTER_REQUEST",
    ADD_AREA_MASTER_SUCCESS: "ADD_AREA_MASTER_SUCCESS",
    UPDATE_AREA_MASTER_SUCCESS: "UPDATE_AREA_MASTER_SUCCESS",

    GET_AREA_MASTER_REQUEST: "GET_AREA_MASTER_REQUEST",
    GET_AREA_MASTER_SUCCESS: "GET_AREA_MASTER_SUCCESS",

    AREA_MASTER_APPROVE_REQUEST: "AREA_MASTER_APPROVE_REQUEST",
    AREA_MASTER_APPROVE_SUCCESS: "AREA_MASTER_APPROVE_SUCCESS",

    AREA_MASTER_DELETE_REQUEST: "AREA_MASTER_DELETE_REQUEST",
    AREA_MASTER_DELETE_SUCCESS: "AREA_MASTER_DELETE_SUCCESS",

    ADD_PROCESS_MASTER_REQUEST: "ADD_PROCESS_MASTER_REQUEST",
    ADD_PROCESS_MASTER_SUCCESS: "ADD_PROCESS_MASTER_SUCCESS",
    UPDATE_PROCESS_MASTER_SUCCESS: "UPDATE_PROCESS_MASTER_SUCCESS",

    GET_PROCESS_MASTER_REQUEST: "GET_PROCESS_MASTER_REQUEST",
    GET_PROCESS_MASTER_SUCCESS: "GET_PROCESS_MASTER_SUCCESS",

    PROCESS_MASTER_APPROVE_REQUEST: "PROCESS_MASTER_APPROVE_REQUEST",
    PROCESS_MASTER_APPROVE_SUCCESS: "PROCESS_MASTER_APPROVE_SUCCESS",

    DELETE_PROCESS_MASTER_REQUEST: "DELETE_PROCESS_MASTER_REQUEST",
    DELETE_PROCESS_MASTER_SUCCESS: "DELETE_PROCESS_MASTER_SUCCESS",

    ADD_GODWON_MASTER_REQUEST: "ADD_GODWON_MASTER_REQUEST",
    ADD_GODWON_MASTER_SUCCESS: "ADD_GODWON_MASTER_SUCCESS",
    UPDATE_GODWON_MASTER_SUCCESS: "UPDATE_GODWON_MASTER_SUCCESS",

    GET_GODWON_MASTER_REQUEST: "GET_GODWON_MASTER_REQUEST",
    GET_GODWON_MASTER_SUCCESS: "GET_GODWON_MASTER_SUCCESS",

    GODWON_MASTER_APPROVE_REQUEST: "GODWON_MASTER_APPROVE_REQUEST",
    GODWON_MASTER_APPROVE_SUCCESS: "GODWON_MASTER_APPROVE_SUCCESS",

    DELETE_GODWON_MASTER_REQUEST: "DELETE_GODWON_MASTER_REQUEST",
    DELETE_GODWON_MASTER_SUCCESS: "DELETE_GODWON_MASTER_SUCCESS",

    ADD_SIZE_MASTER_REQUEST: "ADD_SIZE_MASTER_REQUEST",
    ADD_SIZE_MASTER_SUCCESS: "ADD_SIZE_MASTER_SUCCESS",
    UPDATE_SIZE_MASTER_SUCCESS: "UPDATE_SIZE_MASTER_SUCCESS",

    GET_SIZE_MASTER_REQUEST: "GET_SIZE_MASTER_REQUEST",
    GET_SIZE_MASTER_SUCCESS: "GET_SIZE_MASTER_SUCCESS",

    ADMIN_SIZE_MASTER_STATUS_REQUEST: "ADMIN_SIZE_MASTER_STATUS_REQUEST",
    ADMIN_SIZE_MASTER_STATUS_SUCCESS: "ADMIN_SIZE_MASTER_STATUS_SUCCESS",

    DELETE_SIZE_MASTER_REQUEST: "DELETE_SIZE_MASTER_REQUEST",
    DELETE_SIZE_MASTER_SUCCESS: "DELETE_SIZE_MASTER_SUCCESS",

    ADD_COLOUR_MASTER_REQUEST: "ADD_COLOUR_MASTER_REQUEST",
    ADD_COLOUR_MASTER_SUCCESS: "ADD_COLOUR_MASTER_SUCCESS",
    UPDATE_COLOUR_MASTER_SUCCESS: "UPDATE_COLOUR_MASTER_SUCCESS",

    GET_COLOUR_MASTER_REQUEST: "GET_COLOUR_MASTER_REQUEST",
    GET_COLOUR_MASTER_SUCCESS: "GET_COLOUR_MASTER_SUCCESS",

    COLOUR_MASTER_APPROVE_REQUEST: "COLOUR_MASTER_APPROVE_REQUEST",
    COLOUR_MASTER_APPROVE_SUCCESS: "COLOUR_MASTER_APPROVE_SUCCESS",

    DELETE_COLOUR_MASTER_REQUEST: "DELETE_COLOUR_MASTER_REQUEST",
    DELETE_COLOUR_MASTER_SUCCESS: "DELETE_COLOUR_MASTER_SUCCESS",

    ADD_UNIT_MASTER_REQUEST: "ADD_UNIT_MASTER_REQUEST",
    ADD_UNIT_MASTER_SUCCESS: "ADD_UNIT_MASTER_SUCCESS",
    UPDATE_UNIT_MASTER_SUCCESS: "UPDATE_UNIT_MASTER_SUCCESS",

    GET_UNIT_MASTER_REQUEST: "GET_UNIT_MASTER_REQUEST",
    GET_UNIT_MASTER_SUCCESS: "GET_UNIT_MASTER_SUCCESS",

    ADMIN_UNIT_STATUS_REQUEST: "ADMIN_UNIT_STATUS_REQUEST",
    ADMIN_UNIT_STATUS_SUCCESS: "ADMIN_UNIT_STATUS_SUCCESS",

    UNIT_MASTER_DELETE_REQUEST: "UNIT_MASTER_DELETE_REQUEST",
    UNIT_MASTER_DELETE_SUCCESS: "UNIT_MASTER_DELETE_SUCCESS",

    ADD_STATE_MASTER_REQUEST: "ADD_STATE_MASTER_REQUEST",
    ADD_STATE_MASTER_SUCCESS: "ADD_STATE_MASTER_SUCCESS",
    UPDATE_STATE_MASTER_SUCCESS: "UPDATE_STATE_MASTER_SUCCESS",

    GET_STATE_MASTER_REQUEST: "GET_STATE_MASTER_REQUEST",
    GET_STATE_MASTER_SUCCESS: "GET_STATE_MASTER_SUCCESS",

    ADD_NARRATION_MASTER_REQUEST: "ADD_NARRATION_MASTER_REQUEST",
    ADD_NARRATION_MASTER_SUCCESS: "ADD_NARRATION_MASTER_SUCCESS",
    UPDATE_NARRATION_MASTER_SUCCESS: "UPDATE_NARRATION_MASTER_SUCCESS",

    GET_NARRATION_MASTER_REQUEST: "GET_NARRATION_MASTER_REQUEST",
    GET_NARRATION_MASTER_SUCCESS: "GET_NARRATION_MASTER_SUCCESS",

    NARRATION_MASTER_APPROVE_REQUEST: "NARRATION_MASTER_APPROVE_REQUEST",
    NARRATION_MASTER_APPROVE_SUCCESS: "NARRATION_MASTER_APPROVE_SUCCESS",

    NARRATION_MASTER_DELETE_REQUEST: "NARRATION_MASTER_DELETE_REQUEST",
    NARRATION_MASTER_DELETE_SUCCESS: "NARRATION_MASTER_DELETE_SUCCESS",

    ADD_SET_MASTER_REQUEST: "ADD_SET_MASTER_REQUEST",
    ADD_SET_MASTER_SUCCESS: "ADD_SET_MASTER_SUCCESS",
    UPDATE_SET_MASTER_SUCCESS: "UPDATE_SET_MASTER_SUCCESS",

    GET_SET_MASTER_REQUEST: "GET_SET_MASTER_REQUEST",
    GET_SET_MASTER_SUCCESS: "GET_SET_MASTER_SUCCESS",

    SET_MASTER_APPROVE_REQUEST: "SET_MASTER_APPROVE_REQUEST",
    SET_MASTER_APPROVE_SUCCESS: "SET_MASTER_APPROVE_SUCCESS",

    SET_MASTER_DELETE_REQUEST: "SET_MASTER_DELETE_REQUEST",
    SET_MASTER_DELETE_SUCCESS: "SET_MASTER_DELETE_SUCCESS",

    GET_ALL_COLOUR_MASTER_REQUEST: "GET_ALL_COLOUR_MASTER_REQUEST",
    GET_ALL_COLOUR_MASTER_SUCCESS: "GET_ALL_COLOUR_MASTER_SUCCESS",

    GET_COMPANY_TYPE_LIST_REQUEST: "GET_COMPANY_TYPE_LIST_REQUEST",
    GET_COMPANY_TYPE_LIST_SUCCESS: "GET_COMPANY_TYPE_LIST_SUCCESS",

    GET_INDUSTRY_TYPE_LIST_REQUEST: "GET_INDUSTRY_TYPE_LIST_REQUEST",
    GET_INDUSTRY_TYPE_LIST_SUCCESS: "GET_INDUSTRY_TYPE_LIST_SUCCESS",

    EDIT_ADMIN_PROFILE_REQUEST: "EDIT_ADMIN_PROFILE_REQUEST",
    EDIT_ADMIN_PROFILE_SUCCESS: "EDIT_ADMIN_PROFILE_SUCCESS",

    GET_ADMIN_PROFILE_REQUEST: "GET_ADMIN_PROFILE_REQUEST",
    GET_ADMIN_PROFILE_SUCCESS: "GET_ADMIN_PROFILE_SUCCESS",

    ALL_CITY_LIST_REQUEST: "ALL_CITY_LIST_REQUEST",
    ALL_CITY_LIST_SUCCESS: "ALL_CITY_LIST_SUCCESS",

    ADD_COUNTRY_MASTER_REQUEST: "ADD_COUNTRY_MASTER_REQUEST",
    ADD_COUNTRY_MASTER_SUCCESS: "ADD_COUNTRY_MASTER_SUCCESS",
    UPDATE_COUNTRY_MASTER_SUCCESS: "UPDATE_COUNTRY_MASTER_SUCCESS",

    ADD_CITY_MASTER_REQUEST: "ADD_CITY_MASTER_REQUEST",
    ADD_CITY_MASTER_SUCCESS: "ADD_CITY_MASTER_SUCCESS",
    UPDATE_CITY_MASTER_SUCCESS: "UPDATE_CITY_MASTER_SUCCESS",

    GET_CITY_MASTER_REQUEST: "GET_CITY_MASTER_REQUEST",
    GET_CITY_MASTER_SUCCESS: "GET_CITY_MASTER_SUCCESS",

    GET_ADMINA_COUNTRY_LIST_REQUEST: "GET_ADMINA_COUNTRY_LIST_REQUEST",
    GET_ADMINA_COUNTRY_LIST_SUCCESS: "GET_ADMINA_COUNTRY_LIST_SUCCESS",

    ADMIN_COUNTRY_STATUS_REQUEST: "ADMIN_COUNTRY_STATUS_REQUEST",
    ADMIN_COUNTRY_STATUS_SUCCESS: "ADMIN_COUNTRY_STATUS_SUCCESS",

    COUNTRY_MASTER_DELETE_REQUEST: "COUNTRY_MASTER_DELETE_REQUEST",
    COUNTRY_MASTER_DELETE_SUCCESS: "COUNTRY_MASTER_DELETE_SUCCESS",

    ADMIN_STATE_MASTER_STATUS_REQUEST: "ADMIN_STATE_MASTER_STATUS_REQUEST",
    ADMIN_STATE_MASTER_STATUS_SUCCESS: "ADMIN_STATE_MASTER_STATUS_SUCCESS",

    ADMIN_STATE_MASTER_DELETE_REQUEST: "ADMIN_STATE_MASTER_DELETE_REQUEST",
    ADMIN_STATE_MASTER_DELETE_SUCCESS: "ADMIN_STATE_MASTER_DELETE_SUCCESS",

    ADMIN_CITY_MASTER_STATUS_REQUEST: "ADMIN_CITY_MASTER_STATUS_REQUEST",
    ADMIN_CITY_MASTER_STATUS_SUCCESS: "ADMIN_CITY_MASTER_STATUS_SUCCESS",

    CITY_STATE_LIST_REQUEST: "CITY_STATE_LIST_REQUEST",
    CITY_STATE_LIST_SUCCESS: "CITY_STATE_LIST_SUCCESS",

    CITY_MASTER_DELETE_REQUEST: "CITY_MASTER_DELETE_REQUEST",
    CITY_MASTER_DELETE_SUCCESS: "CITY_MASTER_DELETE_SUCCESS",

    PRODUCT_GROUP_MASTER_REQUEST : "PRODUCT_GROUP_MASTER_REQUEST",
    PRODUCT_GROUP_MASTER_SUCCESS : "PRODUCT_GROUP_MASTER_SUCCESS",

    ADD_PRODUCT_GROUP_MASTER_REQUEST:"ADD_PRODUCT_GROUP_MASTER_REQUEST",
    ADD_PRODUCT_GROUP_MASTER_SUCCESS : "ADD_PRODUCT_GROUP_MASTER_SUCCESS",
    UPDATE_PRODUCT_GROUP_MASTER_SUCCESS : "UPDATE_PRODUCT_GROUP_MASTER_SUCCESS",

    PRODUCT_GROUP_STATUS_REQUEST: "PRODUCT_GROUP_STATUS_REQUEST",
    PRODUCT_GROUP_STATUS_SUCCESS: "PRODUCT_GROUP_STATUS_SUCCESS",
    
    DELETE_PRODUCT_GROUP_MASTER_REQUEST:"DELETE_PRODUCT_GROUP_MASTER_REQUEST",
    DELETE_PRODUCT_GROUP_MASTER_SUCCESS : "DELETE_PRODUCT_GROUP_MASTER_SUCCESS",

    ADD_PRODUCT_CATEGORY_MASTER_REQUEST: "ADD_PRODUCT_CATEGORY_MASTER_REQUEST",
    ADD_PRODUCT_CATEGORY_MASTER_SUCCESS: "ADD_PRODUCT_CATEGORY_MASTER_SUCCESS",
    UPDATE_PRODUCT_CATEGORY_MASTER_SUCCESS: "UPDATE_PRODUCT_CATEGORY_MASTER_SUCCESS",

    GET_PRODUCT_CATEGORY_MASTER_REQUEST: "GET_PRODUCT_CATEGORY_MASTER_REQUEST",
    GET_PRODUCT_CATEGORY_MASTER_SUCCESS: "GET_PRODUCT_CATEGORY_MASTER_SUCCESS",

    PRODUCT_CATEGORY_STATUS_REQUEST: "PRODUCT_CATEGORY_STATUS_REQUEST",
    PRODUCT_CATEGORY_STATUS_SUCCESS: "PRODUCT_CATEGORY_STATUS_SUCCESS",

    PRODUCT_CATEGORY_MASTER_DELETE_REQUEST: "PRODUCT_CATEGORY_MASTER_DELETE_REQUEST",
    PRODUCT_CATEGORY_MASTER_DELETE_SUCCESS: "PRODUCT_CATEGORY_MASTER_DELETE_SUCCESS",

    GET_HSN_MASTER_REQUEST: "GET_HSN_MASTER_REQUEST",
    GET_HSN_MASTER_SUCCESS: "GET_HSN_MASTER_SUCCESS",

    ADD_HSN_MASTER_REQUEST: "ADD_HSN_MASTER_REQUEST",
    ADD_HSN_MASTER_SUCCESS: "ADD_HSN_MASTER_SUCCESS",
    UPDATE_HSN_MASTER_SUCCESS: "UPDATE_HSN_MASTER_SUCCESS",

    HSN_STATUS_REQUEST: "HSN_STATUS_REQUEST",
    HSN_STATUS_SUCCESS: "HSN_STATUS_SUCCESS",

    HSN_MASTER_DELETE_REQUEST: "HSN_MASTER_DELETE_REQUEST",
    HSN_MASTER_DELETE_SUCCESS: "HSN_MASTER_DELETE_SUCCESS", 

    GET_PRODUCT_MASTER_REQUEST: "GET_PRODUCT_MASTER_REQUEST",
    GET_PRODUCT_MASTER_SUCCESS: "GET_PRODUCT_MASTER_SUCCESS",

    GET_SINGLE_PRODUCT_MASTER_REQUEST: "GET_SINGLE_PRODUCT_MASTER_REQUEST",
    GET_SINGLE_PRODUCT_MASTER_SUCCESS: "GET_SINGLE_PRODUCT_MASTER_SUCCESS",

    ADD_PRODUCT_MASTER_REQUEST: "ADD_PRODUCT_MASTER_REQUEST",
    ADD_PRODUCT_MASTER_SUCCESS: "ADD_PRODUCT_MASTER_SUCCESS",
    UPDATE_PRODUCT_MASTER_SUCCESS: "UPDATE_PRODUCT_MASTER_SUCCESS",

    PRODUCT_STATUS_REQUEST: "PRODUCT_STATUS_REQUEST",
    PRODUCT_STATUS_SUCCESS: "PRODUCT_STATUS_SUCCESS",

    PRODUCT_MASTER_DELETE_REQUEST: "PRODUCT_MASTER_DELETE_REQUEST",
    PRODUCT_MASTER_DELETE_SUCCESS: "PRODUCT_MASTER_DELETE_SUCCESS", 
    GET_ZONE_MASTER_REQUEST: "GET_ZONE_MASTER_REQUEST",
    GET_ZONE_MASTER_SUCCESS: "GET_ZONE_MASTER_SUCCESS",

    ZONE_MASTER_STATUS_REQUEST: "ZONE_MASTER_STATUS_REQUEST",
    ZONE_MASTER_STATUS_SUCCESS: "ZONE_MASTER_STATUS_SUCCESS",

    ZONE_MASTER_DELETE_REQUEST: "ZONE_MASTER_DELETE_REQUEST",
    ZONE_MASTER_DELETE_SUCCESS: "ZONE_MASTER_DELETE_SUCCESS",

    ADD_ZONE_MASTER_REQUEST: "ADD_ZONE_MASTER_REQUEST",
    ADD_ZONE_MASTER_SUCCESS: "ADD_ZONE_MASTER_SUCCESS",
    UPDATE_ZONE_MASTER_SUCCESS: "UPDATE_ZONE_MASTER_SUCCESS",

    GET_OPENING_STOCK_MASTER_REQUEST: "GET_OPENING_STOCK_MASTER_REQUEST",
    GET_OPENING_STOCK_MASTER_SUCCESS: "GET_OPENING_STOCK_MASTER_SUCCESS",

    ADD_OPENING_STOCK_MASTER_REQUEST: "ADD_OPENING_STOCK_MASTER_REQUEST",
    ADD_OPENING_STOCK_MASTER_SUCCESS: "ADD_OPENING_STOCK_MASTER_SUCCESS",
    UPDATE_OPENING_STOCK_MASTER_SUCCESS: "UPDATE_OPENING_STOCK_MASTER_SUCCESS",

    OPENING_STOCK_STATUS_REQUEST: "OPENING_STOCK_STATUS_REQUEST",
    OPENING_STOCK_STATUS_SUCCESS: "OPENING_STOCK_STATUS_SUCCESS",

    OPENING_STOCK_MASTER_DELETE_REQUEST: "OPENING_STOCK_MASTER_DELETE_REQUEST",
    OPENING_STOCK_MASTER_DELETE_SUCCESS: "OPENING_STOCK_MASTER_DELETE_SUCCESS",
    ADD_FINISH_STOCK_REQUEST: "ADD_FINISH_STOCK_REQUEST",
    ADD_FINISH_STOCK_SUCCESS: "ADD_FINISH_STOCK_SUCCESS",
    UPDATE_FINISH_STOCK_SUCCESS: "UPDATE_FINISH_STOCK_SUCCESS",

    GET_FINISH_STOCK_REQUEST: "GET_FINISH_STOCK_REQUEST",
    GET_FINISH_STOCK_SUCCESS: "GET_FINISH_STOCK_SUCCESS",

    FINISH_STOCK_DELETE_REQUEST: "FINISH_STOCK_DELETE_REQUEST",
    FINISH_STOCK_DELETE_SUCCESS: "FINISH_STOCK_DELETE_SUCCESS",

    GET_TAX_CATEGORY_MASTER_REQUEST: "GET_TAX_CATEGORY_MASTER_REQUEST",
    GET_TAX_CATEGORY_MASTER_SUCCESS: "GET_TAX_CATEGORY_MASTER_SUCCESS",
    GET_SINGLE_TAX_CATEGORY_MASTER_SUCCESS: "GET_SINGLE_TAX_CATEGORY_MASTER_SUCCESS",

    TAX_CATEGORY_MASTER_DELETE_REQUEST: "TAX_CATEGORY_MASTER_DELETE_REQUEST",
    TAX_CATEGORY_MASTER_DELETE_SUCCESS: "TAX_CATEGORY_MASTER_DELETE_SUCCESS",

    TAX_CATEGORY_MASTER_STATUS_REQUEST: "TAX_CATEGORY_MASTER_STATUS_REQUEST",
    TAX_CATEGORY_MASTER_STATUS_SUCCESS: "TAX_CATEGORY_MASTER_STATUS_SUCCESS",

    ADD_TAX_CATEGORY_MASTER_REQUEST: "ADD_TAX_CATEGORY_MASTER_REQUEST",
    ADD_TAX_CATEGORY_MASTER_SUCCESS: "ADD_TAX_CATEGORY_MASTER_SUCCESS",
    UPDATE_TAX_CATEGORY_MASTER_SUCCESS: "UPDATE_TAX_CATEGORY_MASTER_SUCCESS",

    GET_ACTIVE_TAX_MASTER_REQUEST: "GET_ACTIVE_TAX_MASTER_REQUEST",
    GET_ACTIVE_TAX_MASTER_SUCCESS: "GET_ACTIVE_TAX_MASTER_SUCCESS",

    GET_TAX_MASTER_REQUEST: "GET_TAX_MASTER_REQUEST",
    GET_TAX_MASTER_SUCCESS: "GET_TAX_MASTER_SUCCESS",

    ADD_TAX_MASTER_REQUEST: "ADD_TAX_MASTER_REQUEST",
    ADD_TAX_MASTER_SUCCESS: "ADD_TAX_MASTER_SUCCESS",
    UPDATE_TAX_MASTER_SUCCESS: "UPDATE_TAX_MASTER_SUCCESS",

    TAX_MASTER_APPROVE_REQUEST: "TAX_MASTER_APPROVE_REQUEST",
    TAX_MASTER_APPROVE_SUCCESS: "TAX_MASTER_APPROVE_SUCCESS",

    DELETE_TAX_MASTER_REQUEST: "DELETE_TAX_MASTER_REQUEST",
    DELETE_TAX_MASTER_SUCCESS: "DELETE_TAX_MASTER_SUCCESS",

    GET_TAX_TYPE_LIST_REQUEST: "GET_TAX_TYPE_LIST_REQUEST",
    GET_TAX_TYPE_LIST_SUCCESS: "GET_TAX_TYPE_LIST_SUCCESS",

    GET_CALCULATION_TYPE_LIST_REQUEST: "GET_CALCULATION_TYPE_LIST_REQUEST",
    GET_CALCULATION_TYPE_LIST_SUCCESS: "GET_CALCULATION_TYPE_LIST_SUCCESS",
    
    ADD_ITEM_SIZE_LIST_REQUEST: "ADD_ITEM_SIZE_LIST_REQUEST",
    ADD_ITEM_SIZE_LIST_SUCCESS: "ADD_ITEM_SIZE_LIST_SUCCESS",
    UPDATE_ITEM_SIZE_LIST_SUCCESS: "UPDATE_ITEM_SIZE_LIST_SUCCESS",

    GET_ITEM_SIZE_LIST_REQUEST: "GET_ITEM_SIZE_LIST_REQUEST",
    GET_ITEM_SIZE_LIST_SUCCESS: "GET_ITEM_SIZE_LIST_SUCCESS",
    GET_SINGLE_ITEM_SIZE_LIST_SUCCESS:"GET_SINGLE_ITEM_SIZE_LIST_SUCCESS",

    GET_ACCOUNT_GROUP_MASTER_REQUEST: "GET_ACCOUNT_GROUP_MASTER_REQUEST",
    GET_ACCOUNT_GROUP_MASTER_SUCCESS: "GET_ACCOUNT_GROUP_MASTER_SUCCESS",

    GET_ACTIVE_HEAD_GROUP_MASTER_REQUEST: "GET_ACTIVE_HEAD_GROUP_MASTER_REQUEST",
    GET_ACTIVE_HEAD_GROUP_MASTER_SUCCESS: "GET_ACTIVE_HEAD_GROUP_MASTER_SUCCESS",

    ADD_ACCOUNT_GROUP_MASTER_REQUEST: "ADD_ACCOUNT_GROUP_MASTER_REQUEST",
    ADD_ACCOUNT_GROUP_MASTER_SUCCESS: "ADD_ACCOUNT_GROUP_MASTER_SUCCESS",
    UPDATE_ACCOUNT_GROUP_MASTER_SUCCESS: "UPDATE_ACCOUNT_GROUP_MASTER_SUCCESS",

    ACCOUNT_GROUP_MASTER_STATUS_REQUEST: "ACCOUNT_GROUP_MASTER_STATUS_REQUEST",
    ACCOUNT_GROUP_MASTER_STATUS_SUCCESS: "ACCOUNT_GROUP_MASTER_STATUS_SUCCESS",

    ACCOUNT_GROUP_MASTER_DELETE_REQUEST: "ACCOUNT_GROUP_MASTER_DELETE_REQUEST",
    ACCOUNT_GROUP_MASTER_DELETE_SUCCESS: "ACCOUNT_GROUP_MASTER_DELETE_SUCCESS",
    ITEM_SIZE_LIST_APPROVE_REQUEST: "ITEM_SIZE_LIST_APPROVE_REQUEST",
    ITEM_SIZE_LIST_APPROVE_SUCCESS: "ITEM_SIZE_LIST_APPROVE_SUCCESS",

    ITEM_SIZE_LIST_DELETE_REQUEST: "ITEM_SIZE_LIST_DELETE_REQUEST",
    ITEM_SIZE_LIST_DELETE_SUCCESS: "ITEM_SIZE_LIST_DELETE_SUCCESS",

    CHECK_DUPLICATE_COMPANY_DATA_REQUEST: "CHECK_DUPLICATE_COMPANY_DATA_REQUEST",
    CHECK_DUPLICATE_GST_COMPANY_DATA_SUCCESS: "CHECK_DUPLICATE_GST_COMPANY_DATA_SUCCESS",
    CHECK_DUPLICATE_EMAIL_COMPANY_DATA_SUCCESS: "CHECK_DUPLICATE_EMAIL_COMPANY_DATA_SUCCESS",

    GET_DEPARTMENT_MASTER_REQUEST: "GET_DEPARTMENT_MASTER_REQUEST",
    GET_DEPARTMENT_MASTER_SUCCESS: "GET_DEPARTMENT_MASTER_SUCCESS",

    DEPARTMENT_MASTER_APPROVE_REQUEST: "DEPARTMENT_MASTER_APPROVE_REQUEST",
    DEPARTMENT_MASTER_APPROVE_SUCCESS: "DEPARTMENT_MASTER_APPROVE_SUCCESS",

    DELETE_DEPARTMENT_MASTER_REQUEST: "DELETE_DEPARTMENT_MASTER_REQUEST",
    DELETE_DEPARTMENT_MASTER_SUCCESS: "DELETE_DEPARTMENT_MASTER_SUCCESS",

    ADD_DEPARTMENT_MASTER_REQUEST: "ADD_DEPARTMENT_MASTER_REQUEST",
    ADD_DEPARTMENT_MASTER_SUCCESS: "ADD_DEPARTMENT_MASTER_SUCCESS",
    UPDATE_DEPARTMENT_MASTER_SUCCESS: "UPDATE_DEPARTMENT_MASTER_SUCCESS",

    GET_COST_GROUP_MASTER_REQUEST: "GET_COST_GROUP_MASTER_REQUEST",
    GET_COST_GROUP_MASTER_SUCCESS: "GET_COST_GROUP_MASTER_SUCCESS",

    COST_GROUP_APPROVE_REQUEST: "COST_GROUP_APPROVE_REQUEST",
    COST_GROUP_APPROVE_SUCCESS: "COST_GROUP_APPROVE_SUCCESS",

    ACTIVE_COST_GROUP_MASTER_REQUEST: "ACTIVE_COST_GROUP_MASTER_REQUEST",
    ACTIVE_COST_GROUP_MASTER_SUCCESS: "ACTIVE_COST_GROUP_MASTER_SUCCESS",

    COST_GROUP_MASTER_DELETE_REQUEST: "COST_GROUP_MASTER_DELETE_REQUEST",
    COST_GROUP_MASTER_DELETE_SUCCESS: "COST_GROUP_MASTER_DELETE_SUCCESS",

    ADD_COST_GROUP_MASTER_REQUEST: "ADD_COST_GROUP_MASTER_REQUEST",
    ADD_COST_GROUP_MASTER_SUCCESS: "ADD_COST_GROUP_MASTER_SUCCESS",
    UPDATE_COST_GROUP_MASTER_SUCCESS: "UPDATE_COST_GROUP_MASTER_SUCCESS",

    GET_FIXED_ASSET_MASTER_REQUEST: "GET_FIXED_ASSET_MASTER_REQUEST",
    GET_FIXED_ASSET_MASTER_SUCCESS: "GET_FIXED_ASSET_MASTER_SUCCESS",

    FIXED_ASSET_APPROVE_REQUEST: "FIXED_ASSET_APPROVE_REQUEST",
    FIXED_ASSET_APPROVE_SUCCESS: "FIXED_ASSET_APPROVE_SUCCESS",

    FIXED_ASSET_MASTER_DELETE_REQUEST: "FIXED_ASSET_MASTER_DELETE_REQUEST",
    FIXED_ASSET_MASTER_DELETE_SUCCESS: "FIXED_ASSET_MASTER_DELETE_SUCCESS",

    ADD_FIXED_ASSET_MASTER_REQUEST: "ADD_FIXED_ASSET_MASTER_REQUEST",
    ADD_FIXED_ASSET_MASTER_SUCCESS: "ADD_FIXED_ASSET_MASTER_SUCCESS",
    UPDATE_FIXED_ASSET_MASTER_SUCCESS: "UPDATE_FIXED_ASSET_MASTER_SUCCESS",

    GET_ACCOUNT_MASTER_LIST_REQUEST: "GET_ACCOUNT_MASTER_LIST_REQUEST",
    GET_ACCOUNT_MASTER_LIST_SUCCESS: "GET_ACCOUNT_MASTER_LIST_SUCCESS",

    ACCOUNT_MASTER_APPROVE_REQUEST: "ACCOUNT_MASTER_APPROVE_REQUEST",
    ACCOUNT_MASTER_APPROVE_SUCCESS: "ACCOUNT_MASTER_APPROVE_SUCCESS",

    ADD_ACCOUNT_MASTER_REQUEST: "ADD_ACCOUNT_MASTER_REQUEST",
    ADD_ACCOUNT_MASTER_SUCCESS: "ADD_ACCOUNT_MASTER_SUCCESS",
    UPDATE_ACCOUNT_MASTER_SUCCESS: "UPDATE_ACCOUNT_MASTER_SUCCESS",

    ACCOUNT_MASTER_DELETE_REQUEST: "ACCOUNT_MASTER_DELETE_REQUEST",
    ACCOUNT_MASTER_DELETE_SUCCESS: "ACCOUNT_MASTER_DELETE_SUCCESS",

    GET_MULTIPLE_DATA_REQUEST: "GET_MULTIPLE_DATA_REQUEST",
    GET_MULTIPLE_DATA_SUCCESS: "GET_MULTIPLE_DATA_SUCCESS",

    GET_BIND_DATA_REQUEST: "GET_BIND_DATA_REQUEST",
    GET_BIND_DATA_SUCCESS: "GET_BIND_DATA_SUCCESS",
    
    GET_OPENING_BALANCE_REQUEST: "GET_OPENING_BALANCE_REQUEST",
    GET_OPENING_BALANCE_SUCCESS: "GET_OPENING_BALANCE_SUCCESS",

    OPENING_BALANCE_ACTIVE_INACTIVE_REQUEST: "OPENING_BALANCE_ACTIVE_INACTIVE_REQUEST",
    OPENING_BALANCE_ACTIVE_INACTIVE_SUCCESS: "OPENING_BALANCE_ACTIVE_INACTIVE_SUCCESS",

    OPENING_BALANCE_DELETE_REQUEST: "OPENING_BALANCE_DELETE_REQUEST",
    OPENING_BALANCE_DELETE_SUCCESS: "OPENING_BALANCE_DELETE_SUCCESS",

    ADD_OPENING_BALANCE_REQUEST: "ADD_OPENING_BALANCE_REQUEST",
    ADD_OPENING_BALANCE_SUCCESS: "ADD_OPENING_BALANCE_SUCCESS",
    UPDATE_OPENING_BALANCE_SUCCESS: "UPDATE_OPENING_BALANCE_SUCCESS",

    GET_OUTSTANDING_VOUCHER_REQUEST: "GET_OUTSTANDING_VOUCHER_REQUEST",
    GET_OUTSTANDING_VOUCHER_SUCCESS: "GET_OUTSTANDING_VOUCHER_SUCCESS",

    ADD_OUTSTANDING_VOUCHER_REQUEST: "ADD_OUTSTANDING_VOUCHER_REQUEST",
    ADD_OUTSTANDING_VOUCHER_SUCCESS: "ADD_OUTSTANDING_VOUCHER_SUCCESS",
    UPDATE_OUTSTANDING_VOUCHER_SUCCESS: "UPDATE_OUTSTANDING_VOUCHER_SUCCESS",

    GET_UNCLEARED_CHEQUE_REQUEST: "GET_UNCLEARED_CHEQUE_REQUEST",
    GET_UNCLEARED_CHEQUE_SUCCESS: "GET_UNCLEARED_CHEQUE_SUCCESS",

    ADD_UNCLEARED_CHEQUE_REQUEST: "ADD_UNCLEARED_CHEQUE_REQUEST",
    ADD_UNCLEARED_CHEQUE_SUCCESS: "ADD_UNCLEARED_CHEQUE_SUCCESS",
    UPDATE_UNCLEARED_CHEQUE_SUCCESS: "UPDATE_UNCLEARED_CHEQUE_SUCCESS",

    RESEND_VERIFICATION_EMAIL_REQUEST: "RESEND_VERIFICATION_EMAIL_REQUEST",
    RESEND_VERIFICATION_EMAIL_SUCCESS: "RESEND_VERIFICATION_EMAIL_SUCCESS",

    GET_SALSE_PURCHASE_LIST_REQUEST: "GET_SALSE_PURCHASE_LIST_REQUEST",
    GET_SALSE_PURCHASE_LIST_SUCCESS: "GET_SALSE_PURCHASE_LIST_SUCCESS",

    
    GET_SALES_PREREQUISITE_DATA_REQUEST: "GET_SALES_PREREQUISITE_DATA_REQUEST",
    GET_SALES_PREREQUISITE_DATA_SUCCESS: "GET_SALES_PREREQUISITE_DATA_SUCCESS",
    
    GET_SET_MASTER_WITH_SIZE_REQUEST: "GET_SET_MASTER_WITH_SIZE_REQUEST",
    GET_SET_MASTER_WITH_SIZE_SUCCESS: "GET_SET_MASTER_WITH_SIZE_SUCCESS",
    
    GET_SALES_USERS_DROPDOWN_DATA_REQUEST: "GET_SALES_USERS_DROPDOWN_DATA_REQUEST",
    GET_SALES_USERS_DROPDOWN_DATA_SUCCESS: "GET_SALES_USERS_DROPDOWN_DATA_SUCCESS",

    CREATE_SALES_ORDER_REQUEST: "CREATE_SALES_ORDER_REQUEST",
    CREATE_SALES_ORDER_SUCCESS: "CREATE_SALES_ORDER_SUCCESS",

    GET_SALES_LIST_REQUEST: "GET_SALES_LIST_REQUEST",
    GET_SALES_LIST_SUCCESS: "GET_SALES_LIST_SUCCESS",

    GET_SALES_EDIT_REQUEST: "GET_SALES_EDIT_REQUEST",
    GET_SALES_EDIT_SUCCESS: "GET_SALES_EDIT_SUCCESS",

    
    GET_FIXED_ASSET_COMPANY_REQUEST: "GET_FIXED_ASSET_COMPANY_REQUEST",
    GET_FIXED_ASSET_COMPANY_SUCCESS: "GET_FIXED_ASSET_COMPANY_SUCCESS",
  };