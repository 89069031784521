import React, { useState, useEffect, memo } from "react";
import Index from "../Index";
import {
  AdminLogoutAction,
  GetAdminProfileListAction,
} from "../../redux/admin/action";

function HeaderDropDown() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { token, adminProfile, adminDetail } = Index.useSelector((state) => {
    return state.ERPAdminReducer;
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [url, setUrl] = useState("");
  const [adminProfileData, setAdminProfileData] = useState({});
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(AdminLogoutAction(navigate, adminDetail.domain));
  };

  useEffect(() => {
    dispatch(GetAdminProfileListAction(token));
  }, []);

  useEffect(() => {
    setAdminProfileData(adminProfile);
  }, [adminProfile]);

  useEffect(() => {
    if (
      Object.keys(adminProfileData).length &&
      adminProfileData?.profileImage
    ) {
      setUrl(process.env.REACT_APP_IMAGE_URL + adminProfileData?.profileImage);
    }
  }, [adminProfileData]);

  const handleChangePassword = () => {
    navigate('/dashboard/edit-profile', {
      state: { activeTab : 1 }
    })
  };
  
  const handleMyProfile = () => {
    navigate('/dashboard/edit-profile', {
      state: { activeTab : 0 }
    })
  };

  return (
    <>
      <Index.Button
        className="drop-header-btn"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple
      >
        <Index.Box className="flex-drop-main">
          <Index.Box className="title-admin-drop">
            <img
              //src={Index.Png.User}
              alt="user"
              src={
                url
                  ? url
                  : "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
              }
              className="user_hedaer"
            />
            {/* <Index.Typography variant="h5" component="h5" className='admin-header-drop'>Admin</Index.Typography> */}
          </Index.Box>
        </Index.Box>
      </Index.Button>
      <Index.Menu
        className="drop-header-menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onClose={handleClose}
      >
        <Index.MenuItem onClick={handleClose} className="drop-header-menuitem">
          <Index.Box
            className="header_link"
            //to={"edit-profile"}
            onClick={handleMyProfile}
          >
           <img src={Index.Svg.profileicon} alt="profile" className="header-drop-icons"/> My Profile{" "}
          </Index.Box>
        </Index.MenuItem>

        <Index.MenuItem onClick={handleClose} className="drop-header-menuitem">
          <Index.Box
            className="header_link"
            // to={"edit-profile"}
            onClick={handleChangePassword}
          >
            {" "}
            <img src={Index.Svg.resetpassword} alt="change password" className="header-drop-icons"/> Change Password
          </Index.Box>
        </Index.MenuItem>

        <Index.MenuItem onClick={handleClose} className="drop-header-menuitem">
          <Index.Link onClick={handleLogout} className="header_link">
          <img src={Index.Svg.signouticon} alt="sign out" className="header-drop-icons"/> Sign Out
          </Index.Link>
        </Index.MenuItem>
      </Index.Menu>
    </>
  );
}

export default memo(HeaderDropDown);
