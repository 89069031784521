import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Index from "../Index";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/material";

const MenuItemList = ({ array, type }) => {
  return (
    <>
      {array?.map((e, i) => {
        return (
          <>
            {(e.type === type && e?.permission) && (
              <>
                <Index.ListItem className="li_sidebar_list">
                  <Accordion className="cus-accordion" key={e.type}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Index.Box className="sidebar_btn_list">
                        <img src={Index.Svg[e.icon]} className="sidebar-icons"></img>
                        <Typography className="sidebar-text">{e.name}</Typography>
                      </Index.Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {e.child &&
                        e.child.map((e1) => {
                          return (
                            <>
                              {!e1.child ? (
                                e1.permission && (<NavLink activeClassName="active" to={e1.link}>
                                  <Index.Box className="sidebar_btn_list">
                                    <img src={Index.Svg[e1.icon]} className="sidebar-icons"></img>
                                    <Index.Typography className="sidebar-text">{e1.name}</Index.Typography></Index.Box></NavLink>)
                              ) : (
                                e1.permission && (<Accordion className="sub-accord">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Index.Box className="sidebar_btn_list">
                                      <img src={Index.Svg[e1.icon]} className="sidebar-icons"></img>
                                      <Index.Typography className="sidebar-text">{e1.name}</Index.Typography>
                                    </Index.Box>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {e1.child &&
                                      e1.child.map((e2) => {
                                        return (
                                          <>
                                            {!e2.child ? (
                                              e2?.permission && (<NavLink activeClassName="active" to={e2.link}>
                                                <Index.Box className="sidebar_btn_list">
                                                  <img src={Index.Svg[e2.icon]} className="sidebar-icons"></img>
                                                  <Index.Typography className="sidebar-text">{e2.name}</Index.Typography></Index.Box></NavLink>)
                                            ) : (
                                              e2?.permission && (<Accordion className="sub-accord">
                                                <AccordionSummary
                                                  expandIcon={<ExpandMoreIcon />}
                                                  aria-controls="panel1a-content"
                                                  id="panel1a-header"
                                                >
                                                  <Index.Box className="sidebar_btn_list">
                                                    <img src={Index.Svg[e2.icon]} className="sidebar-icons"></img>
                                                    <Index.Typography className="sidebar-text">
                                                      {e2.name}
                                                    </Index.Typography>
                                                  </Index.Box>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  {e2.child &&
                                                    e2.child.map((e3) => {
                                                      return (
                                                        e3?.permission && (
                                                          <NavLink activeClassName="active" className="rinkudi" to={e3.link}>
                                                            <Index.Box className="sidebar_btn_list">
                                                              <img src={Index.Svg[e3.icon]} className="sidebar-icons"></img>
                                                              <Index.Typography className="sidebar-text">{e3.name}</Index.Typography>
                                                            </Index.Box></NavLink>)
                                                      );
                                                    })}
                                                </AccordionDetails>
                                              </Accordion>)
                                            )}
                                          </>
                                        );
                                      })}
                                  </AccordionDetails>
                                </Accordion>)
                              )}
                            </>
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                </Index.ListItem>
              </>
            )}
          </>
        );
      })}
    </>
  );
};
export default MenuItemList;
